var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-navigation-drawer', {
    attrs: {
      "value": _vm.value,
      "clipped": _vm.$vuetify.breakpoint.lgAndUp,
      "app": "",
      "dark": "",
      "color": "secondary"
    },
    on: {
      "input": _vm.input
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function () {
        return [_c('v-divider'), _c('v-list', {
          staticClass: "pa-0",
          attrs: {
            "dense": ""
          }
        }, [_c('v-list-item', {
          on: {
            "click": _vm.signOut
          }
        }, [_c('v-list-item-action', [_c('v-icon', {
          attrs: {
            "dense": ""
          }
        }, [_vm._v("mdi-logout-variant")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          staticClass: "text-start"
        }, [_vm._v("로그아웃")])], 1)], 1)], 1)];
      },
      proxy: true
    }])
  }, [_c('v-list', {
    attrs: {
      "dense": ""
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  }, [_vm._l(_vm.menus, function (menu) {
    return [!menu.children ? _c('v-list-item-group', {
      key: menu.model,
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }, [menu.scope === undefined || menu.scope.find(function (scope) {
      return _vm.payload.scope.includes(scope);
    }) ? _c('v-list-item', {
      key: menu.title,
      attrs: {
        "value": menu
      },
      on: {
        "click": function ($event) {
          return _vm.navigate(menu);
        }
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function (_ref) {
          var active = _ref.active;
          return [_c('v-list-item-action', [_c('v-icon', {
            attrs: {
              "size": "20",
              "color": active ? 'accent' : ''
            }
          }, [_vm._v(_vm._s(menu.icon))])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
            staticClass: "text-start",
            class: {
              'accent--text': active
            }
          }, [_vm._v(_vm._s(menu.title))])], 1)];
        }
      }], null, true)
    }) : _vm._e()], 1) : menu.scope === undefined || menu.scope.find(function (scope) {
      return _vm.payload.scope.includes(scope);
    }) ? _c('v-list-group', {
      key: menu.text,
      attrs: {
        "prepend-icon": menu.icon,
        "color": "white"
      },
      scopedSlots: _vm._u([{
        key: "prependIcon",
        fn: function () {
          return [_c('v-icon', {
            attrs: {
              "size": "20",
              "dense": ""
            }
          }, [_vm._v(_vm._s(menu.icon))])];
        },
        proxy: true
      }, {
        key: "activator",
        fn: function () {
          return [_c('v-list-item-title', {
            staticClass: "text-start subtitle-2"
          }, [_vm._v(_vm._s(menu.title))])];
        },
        proxy: true
      }], null, true),
      model: {
        value: menu.model,
        callback: function ($$v) {
          _vm.$set(menu, "model", $$v);
        },
        expression: "menu.model"
      }
    }, [_c('v-divider'), _c('v-list-item-group', {
      model: {
        value: _vm.selected,
        callback: function ($$v) {
          _vm.selected = $$v;
        },
        expression: "selected"
      }
    }, [_vm._l(menu.children, function (child, j) {
      return [child.scope === undefined || child.scope.find(function (scope) {
        return _vm.payload.scope.includes(scope);
      }) ? _c('v-list-item', {
        key: j,
        staticStyle: {
          "background": "#414958"
        },
        attrs: {
          "value": child,
          "dense": ""
        },
        on: {
          "click": function ($event) {
            return _vm.navigate(child);
          }
        },
        scopedSlots: _vm._u([{
          key: "default",
          fn: function (_ref2) {
            var active = _ref2.active;
            return [_c('v-list-item-action', {
              staticClass: "mr-4 ml-4"
            }, [child.icon ? _c('v-icon', {
              attrs: {
                "size": "20",
                "color": active ? 'accent' : ''
              }
            }, [_vm._v(_vm._s(child.icon))]) : _vm._e()], 1), _c('v-list-item-content', [_c('v-list-item-title', {
              staticClass: "text-start",
              class: {
                'grey--text text--lighten-1': !active,
                'accent--text': active
              }
            }, [_vm._v(_vm._s(child.title))])], 1)];
          }
        }], null, true)
      }) : _vm._e()];
    })], 2), _c('v-divider')], 1) : _vm._e()];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }