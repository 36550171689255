<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">검색엔진최적화(SEO) (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="seoEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="seoEnabled">
            <v-text-field v-model="seo.title" label="타이틀: Title" placeholder=" " dense hide-details class="mt-3" @input="input"></v-text-field>
            <v-text-field v-model="author.content" label="메타태그: Author" placeholder=" " dense hide-details class="mt-6" @input="input"></v-text-field>
            <v-textarea v-model="decription.content" label="메타태그: Description" placeholder=" " dense hide-details class="mt-6" @input="input"></v-textarea>
            <v-textarea v-model="keywords.content" label="메타태그: Keywords" placeholder=" " dense hide-details class="mt-6" @input="input"></v-textarea>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            seoEnabled: false,

            seo: {
                title: null,
                meta: [
                    { name: "author", content: null },
                    { name: "decription", content: null },
                    { name: "keywords", content: null },
                ]
            },
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { seoEnabled, seo } = this.value;

                if(seoEnabled === undefined) seoEnabled = this.seoEnabled;
                if(seo === undefined) seo = this.seo;

                Object.assign(this.$data, {
                    seoEnabled,
                    seo
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "seoEnabled", this.seoEnabled);
            this.$set(this.value, "seo", this.seo);

            this.$emit('input', this.value);
        },
    },
    computed: {
        author(){
            var author = this.seo.meta.find((meta) => meta.name == "author");
            if(!author){
                author = { name: "author", content: '' };
                this.seo.meta.push(author);
            }
            return author;
        },
        decription(){
            var decription = this.seo.meta.find((meta) => meta.name == "decription");
            if(!decription){
                decription = { name: "decription", content: '' };
                this.seo.meta.push(decription);
            }
            return decription;
        },
        keywords(){
            var keywords = this.seo.meta.find((meta) => meta.name == "keywords");
            if(!keywords){
                keywords = { name: "keywords", content: '' };
                this.seo.meta.push(keywords);
            }
            return keywords;
        }
    }
}
</script>
