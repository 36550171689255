import { render, staticRenderFns } from "./console-banners.vue?vue&type=template&id=2fdd2e9a"
import script from "./console-banners.vue?vue&type=script&lang=js"
export * from "./console-banners.vue?vue&type=script&lang=js"
import style0 from "./console-banners.vue?vue&type=style&index=0&id=2fdd2e9a&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports