var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.setting ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("자사정보 관리")]), _c('v-spacer'), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("무통장입금 계좌연동")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.putSetting
    },
    model: {
      value: _vm.setting.shop.cashRecipt.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop.cashRecipt, "enabled", $$v);
      },
      expression: "setting.shop.cashRecipt.enabled"
    }
  })], 1)], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', [_c('v-select', {
    attrs: {
      "label": "은행",
      "placeholder": " ",
      "items": _vm.banks,
      "item-text": "name",
      "item-value": "code"
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.setting.shop.accountBankCode,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountBankCode", $$v);
      },
      expression: "setting.shop.accountBankCode"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "예금주",
      "placeholder": " "
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.setting.shop.accountHolder,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountHolder", $$v);
      },
      expression: "setting.shop.accountHolder"
    }
  })], 1), _c('v-col', [_c('v-text-field', {
    attrs: {
      "label": "계좌번호",
      "placeholder": " "
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.setting.shop.accountNumber,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "accountNumber", $$v);
      },
      expression: "setting.shop.accountNumber"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-1",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("SEO(검색어 최적화 설정)")])], 1)], 1), _c('v-card-text', [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": function ($event) {
        _vm.meta('title').content = _vm.setting.seo.title;
        _vm.putSetting();
      }
    },
    model: {
      value: _vm.setting.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.setting.seo, "title", $$v);
      },
      expression: "setting.seo.title"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Title",
      "placeholder": " ",
      "disabled": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('title').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('title'), "content", $$v);
      },
      expression: "meta('title').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('author').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('author'), "content", $$v);
      },
      expression: "meta('author').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Subject",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('subject').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('subject'), "content", $$v);
      },
      expression: "meta('subject').content"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Copyright",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('copyright').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('copyright'), "content", $$v);
      },
      expression: "meta('copyright').content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('decription').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('decription'), "content", $$v);
      },
      expression: "meta('decription').content"
    }
  }), _c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.putSetting
    },
    model: {
      value: _vm.meta('keywords').content,
      callback: function ($$v) {
        _vm.$set(_vm.meta('keywords'), "content", $$v);
      },
      expression: "meta('keywords').content"
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }