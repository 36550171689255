<template>
    <v-dialog v-model="show" max-width="1700">
        <template slot="activator" slot-scope="{on}">
            <v-file-input v-model="file" ref="input" class="d-none" accept=".xlsx" @change="file => change(file)"/>
        </template>
        <v-card>
            <v-card-title class="subtitle-2">인테리어샵 업로드</v-card-title>
            <v-divider/>
            <v-data-table :items="shops" :headers="shopsHeaders" disable-sort disable-filtering>
            </v-data-table>
            <v-divider/>
            <v-card-actions>
                <v-spacer />
                <v-btn text color="grey" @click="cancel">취소</v-btn>
                <v-btn text color="primary" @click="upload">업로드</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import XLSX from "xlsx";
export default {
    data(){
        return {
            show: false,

            file: null,

            shops: [],
            shopsHeaders: [
                { text: "지역", value: "area", width: 100 },
                { text: "취급점명", value: "storeName", width: 200 },
                { text: "연락처", value: "storePhone", width: 150 },
                { text: "이메일", value: "storeEmail", width: 200 },
                { text: "한줄설명", value: "summary", width: 200 },
                { text: "시/도", value: "sido", width: 150 },
                { text: "구/군", value: "gugun", width: 150 },
                { text: "우편번호", value: "postcode", width: 100 },
                { text: "기본주소", value: "address1", width: 300 },
                { text: "상세주소", value: "address2", width: 150 },
                { text: "취급컬렉션", value: "collections", width: 150 }
            ]
        }
    },

    methods: {
        open(){
            this.$refs["input"].$refs["input"].click();
        },
        close(){
            this.show = false;
            this.shops = [];
            this.file = null;
        },
        cancel(){
            this.close();
        },
        validate(){
            try{
                if(!this.shops.length) throw new Error("업로드 가능안 인테리어 샵이 없습니다")
                return true;
            }
            catch(error){
                alert(error.message);
            }
            return false;
        },
        async upload(){
            if(this.validate()){
                for(let shop of this.shops){
                    await this.$http.post("/api/console/boards", shop);
                }

                alert("등록되었습니다");
                this.$emit("upload");
                this.close();
            }
        },

        change(file) {
            if (!file) return;

            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(
                        workBook.Sheets[sheetName]
                    );
                    workSheets.push(sheet);
                });

                let [workSheet] = workSheets;
                for(let row of workSheet){
                    this.shops.push({
                        "code": "interior-shop",
                        "area": row["지역"],
                        "storeName": row["취급점명"],
                        "storePhone": row["연락처"],
                        "storeEmail": row["이메일"],
                        "summary": row["한줄설명"],
                        "sido": row["시/도"],
                        "gugun": row["구/군"],
                        "postcode": row["우편번호"],
                        "address1": row["기본주소"],
                        "address2": row["상세주소"],
                        "collections": row["취급컬렉션"]?.split(",")?.map(collection => collection?.trim?.() ),
                    })
                }

                this.show = true;
            };
            reader.readAsBinaryString(file);
        }
    }
}
</script>