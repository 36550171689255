<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%" height="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">회원 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="mt-4" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.name" dense hide-details type="text" label="사용자이름" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.phone" dense hide-details type="text" label="연락처" placeholder=" "/>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter._level" :items="[{ name: '전체', _id: '' }].concat(levels)" item-text="name" item-value="_id" dense hide-details label="회원등급" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter.warning" :items="warningsItems" item-text="text" item-value="value" dense hide-details label="주의회원" placeholder=" "></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2" @click="getExcelUsers" ><v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드</v-btn>
                </v-col>
            </v-row>
            <v-data-table :headers="usersHeaders" :items="users" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate ? item.createDate.toDateTime() : null }}
                </template>
                <template v-slot:item.phone="{ item }">
                    {{ item.phone ? item.phone.phoneNumberFormat() : null }}
                </template>
                <template v-slot:item.level="{ item }">
                    {{ item.level ? item.level.name : null }}
                </template>
                <template v-slot:item.warning="{ item }">
                    <v-row justify="center" align="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.warning" color="red lighten-1" hide-details dens class="mt-0" @change="putUser({ _id: item._id, warning: item.warning })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.orderPrice="{ item }">
                    {{ item.orderPrice.format() }}
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small @click="edit(item)">
                        mdi-pencil
                    </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12 mx-4" @input="search"></v-pagination>
        </v-responsive>
    </v-layout>
</template>

<script>
import XLSX from 'xlsx';

import { mdiMicrosoftExcel } from '@mdi/js';

export default {
    created() {
        this.init();
    },
    data: () => {
        return {
            mdiMicrosoftExcel, // 엑셀아이콘

            showSearch: true,

            filter: {
                name: null,
                phone: null,
                _level: null,
                warning: null,
                withdraw: false,
            },

            page: 1,
            pageCount: 0,
            limit: 10,

            users: [],
            usersHeaders: [{
                    text: '사용자 이름',
                    align: 'center',
                    width: 200,
                    value: 'name',
                },
                {
                    text: '연락처',
                    align: 'center',
                    width: 128,
                    value: 'phone'
                },
                {
                    text: '이메일',
                    align: 'center',
                    width: 128,
                    value: 'email'
                },
                {
                    text: '등급',
                    align: 'center',
                    width: 140,
                    value: 'level'
                },
                {
                    text: '주의회원',
                    width: 100,
                    align: 'center',
                    value: 'warning'
                },
                {
                    text: '구매금액',
                    width: 100,
                    align: 'center',
                    value: 'orderPrice'
                },
                {
                    text: '가입일자',
                    align: 'center',
                    width: 160,
                    value: 'createDate'
                },
                {
                    text: 'Actions',
                    value: 'actions',
                    width: 100,
                    align: 'center'
                },
            ],

            levels: [],

            warningsItems: [
                { text: "전체", value: null },
                { text: "주의회원", value: true },
                { text: "미주의회원", value: false }
            ]

        };
    },
    methods: {
        async init(){
            try{
                await this.getUsers();
                await this.getLevels();
            }
            catch(error){
                console.error(error);
            }
        },
        async search(){
            try{
                await this.getUsers();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        edit(user){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _user: user._id
                }
            });
        },

        getUsers(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/users", {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
                    });

                    this.users = res.data.users;
                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putUser(user){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/users/${user._id}`, user);

                    resolve(res.data.user);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getLevels(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/levels');

                    this.levels = res.data.levels;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getExcelUsers(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/users', {
                        headers: {
                            skip: 0,
                            limit: 0
                        },
                        filter: this.filter
                    });

                    var excelData = [];
                    res.data.users.forEach((user) => {
                        excelData.push({
                            "사용자 이름": user.name,
                            "연락처": user.phone,
                            "이메일": user.email,
                            "회원등급": user.level.name ? user.level.name : '-',
                            "주의회원여부": user.warning ? "주의" : '-',
                            "구매금액": 0,
                            "가입일자": user.createDate.toDate()
                        })
                    });

                    var workbook = new XLSX.utils.book_new();
                    var worksheet = XLSX.utils.json_to_sheet(excelData);

                    XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                    XLSX.writeFile(workbook, "회원목록.xlsx");

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
}
</script>
