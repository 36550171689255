var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.$route.query._user === undefined ? _c('user-list') : _c('user-view')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }