<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">베스트 매치 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="bestMatchsEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="bestMatchsEnabled && bestMatchs.length" class="pb-0">
            <draggable v-model="bestMatchs" @change="input">
                <transition-group>
                    <v-row v-for="(bestMatch, index) in bestMatchs" :key="index" align="center">
                        <v-col cols="12" class="pa-0"><v-divider/></v-col>
                        <v-col v-for="product in bestMatch.products" cols="auto">
                            {{ product.combinedCodeName }}
                        </v-col>
                        <v-spacer/>
                        <v-col cols="auto">
                            <v-btn icon @click="bestMatchs.splice(index, 1); input();"><v-icon>mdi-delete</v-icon></v-btn>
                        </v-col>
                    </v-row>
                </transition-group>
            </draggable>
        </v-card-text>
        <v-divider/>
        <v-card-actions v-if="bestMatchsEnabled">
            <v-row align="center">
                <v-col>
                    <v-autocomplete v-model="selected" dense hide-details multiple :loading="productLoading" :items="products" item-text="combinedCodeName" return-object :search-input.sync="searchValue" label="제품이름 또는 코드로 검색" placeholder=" "></v-autocomplete>
                </v-col>
                <v-col cols="auto" class="py-0 pl-0">
                    <v-btn color="primary" @click="insert"><v-icon class="mr-2">mdi-plus</v-icon>추가</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable'

export default {
    components: {
        draggable
    },
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            bestMatchsEnabled: false,
            bestMatchs: [],

            searchValue: null,
            productLoading: false,
            selected: [],
            products: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { bestMatchsEnabled, bestMatchs } = this.value;

                Object.assignDefined(this.$data, {
                    bestMatchsEnabled,
                    bestMatchs
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "bestMatchsEnabled", this.bestMatchsEnabled);
            this.$set(this.value, "bestMatchs", this.bestMatchs);

            this.$emit('input', this.value);
        },
        insert(){
            this.bestMatchs.push({
                products: this.selected,
                _products: this.selected.map((product) => product._id)
            });
            this.input();
        },

        getProducts(params){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/shop/products", { params });

                    resolve(res.data.products);
                }
                catch(error){
                    reject(error);
                }
            });
        },
    },
    watch: {
        searchValue(){
            if(0 < this.products.length) return;

            this.productLoading = true;
            this.getProducts({ searchKey: "name|code", searchValue: this.searchValue }).then((products) => {
                this.products = products;
                this.productLoading = false;
            });
        },
    }
}
</script>
