var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("시공포트폴리오")]), _vm.portfolios.length ? _c('v-card-text', _vm._l(_vm.portfolios, function (portfolio) {
    return !portfolio.removed ? _c('v-card', {
      staticClass: "d-inline-block mr-2",
      attrs: {
        "width": "283"
      }
    }, [_c('v-card-title', {
      staticClass: "pa-0"
    }, [portfolio.imageFile || portfolio.image ? _c('v-img', {
      attrs: {
        "src": portfolio.imageFile ? _vm.createObjectURL(portfolio.imageFile) : portfolio.image,
        "width": "283",
        "height": "185",
        "contain": ""
      }
    }) : _c('v-responsive', {
      staticClass: "grey lighten-2",
      attrs: {
        "width": "283",
        "height": "185"
      }
    }, [_c('v-row', {
      staticClass: "fill-height",
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "black"
      }
    }, [_vm._v("mdi-pencil")])], 1)], 1)], 1), _c('v-card-text', {
      staticClass: "pb-0"
    }, [_c('v-row', {
      staticClass: "mt-4"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(portfolio.spot))]), _c('v-divider', {
      staticClass: "py-0",
      attrs: {
        "vertical": ""
      }
    }), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(portfolio.size))]), _c('v-divider', {
      staticClass: "py-0",
      attrs: {
        "vertical": ""
      }
    }), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "auto"
      }
    }, [_vm._v(_vm._s(portfolio.style))])], 1), _c('v-row', {
      staticClass: "my-4"
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(_vm._s(portfolio.name))])], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.remove(portfolio);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1) : _vm._e();
  }), 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-row', {
    staticClass: "mt-2"
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.portfolio.imageFile ? _c('v-img', {
    attrs: {
      "width": "283",
      "height": "185",
      "src": _vm.createObjectURL(_vm.portfolio.imageFile),
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "283",
      "height": "185"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "black"
    }
  }, [_vm._v("mdi-pencil")])], 1)], 1), _c('v-file-input', {
    staticClass: "mt-2",
    attrs: {
      "label": "포트폴리오 사진",
      "placeholder": " ",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.portfolio.imageFile,
      callback: function ($$v) {
        _vm.$set(_vm.portfolio, "imageFile", $$v);
      },
      expression: "portfolio.imageFile"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "시공장소",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.portfolio.spot,
      callback: function ($$v) {
        _vm.$set(_vm.portfolio, "spot", $$v);
      },
      expression: "portfolio.spot"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "평수",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.portfolio.size,
      callback: function ($$v) {
        _vm.$set(_vm.portfolio, "size", $$v);
      },
      expression: "portfolio.size"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "스타일",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.portfolio.style,
      callback: function ($$v) {
        _vm.$set(_vm.portfolio, "style", $$v);
      },
      expression: "portfolio.style"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "포트폴리오 명칭",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.portfolio.name,
      callback: function ($$v) {
        _vm.$set(_vm.portfolio, "name", $$v);
      },
      expression: "portfolio.name"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }