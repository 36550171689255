var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("회원등급")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("고객등급 수정")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-2",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm._v("적용기간")]), _c('v-col', {
    staticClass: "py-0 py-sm-3"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.nextScheduledDate) + " ~ ")]), _c('span', {
    staticClass: "caption"
  }, [_vm._v("(매월 1일 등급 산정 후 자동 적용)")]), _c('br'), _c('span', {
    staticClass: "caption primary--text"
  }, [_vm._v("설정된 등급은 다음달 1일부터 적용되며, 별도 설정을 변경하지 않으실 경우, 매월 1일 정오 전까지 등급 산정이 완료되어 자동으로 적용됩니다")])])], 1), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm._v("등급기준")]), _c('v-col', {
    staticClass: "py-0 py-sm-3"
  }, [_c('v-radio-group', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.putSetting
    },
    model: {
      value: _vm.setting.users.levelStandard,
      callback: function ($$v) {
        _vm.$set(_vm.setting.users, "levelStandard", $$v);
      },
      expression: "setting.users.levelStandard"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "주문금액",
      "value": "price"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "주문횟수",
      "value": "count"
    }
  })], 1), _c('v-divider', {
    staticClass: "my-4"
  }), _c('v-radio-group', {
    staticClass: "pa-0 ma-0 d-inline-block",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.putSetting
    },
    model: {
      value: _vm.setting.users.levelPeriod,
      callback: function ($$v) {
        _vm.$set(_vm.setting.users, "levelPeriod", $$v);
      },
      expression: "setting.users.levelPeriod"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "전체",
      "value": 0
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1개월",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "3개월",
      "value": 3
    }
  }), _c('v-radio', {
    attrs: {
      "label": "6개월",
      "value": 6
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1년",
      "value": 12
    }
  })], 1), _c('span', {
    staticClass: "caption d-block d-sm-inline-block"
  }, [_vm._v("동안 구매확정한 주문건")]), _c('span', {
    staticClass: "caption primary--text mt-2 d-block"
  }, [_vm._v("이번달 말일까지 구매확정된 거래 기준입니다.")])], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "subtitle-2",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm._v("가입등급")]), _c('v-col', {
    staticClass: "py-0 py-sm-3",
    attrs: {
      "cols": "12",
      "sm": "5"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.levels,
      "item-text": "name",
      "item-value": "_id",
      "label": "신규회원가입 기본등급",
      "placeholder": " "
    },
    on: {
      "change": _vm.putSetting
    },
    model: {
      value: _vm.setting.users._level,
      callback: function ($$v) {
        _vm.$set(_vm.setting.users, "_level", $$v);
      },
      expression: "setting.users._level"
    }
  })], 1)], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.levels,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.schedule",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-row', {
          attrs: {
            "align": "center",
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "dense": "",
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save(item);
            }
          },
          model: {
            value: item.schedule.enabled,
            callback: function ($$v) {
              _vm.$set(item.schedule, "enabled", $$v);
            },
            expression: "item.schedule.enabled"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.createDate",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.createDate.toDateTime()) + " ")];
      }
    }, {
      key: "item.condition",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [item.schedule.enabled ? _c('div', [_vm.setting.users.levelStandard == 'price' ? _c('span', [_vm._v(_vm._s(_vm.schedulePrices.find(function (info) {
          return info.value == item.schedule.price;
        }).text))]) : _vm._e(), _vm.setting.users.levelStandard == 'count' ? _c('span', [_vm._v(_vm._s(_vm.scheduleCounts.find(function (info) {
          return info.value == item.schedule.count;
        }).text))]) : _vm._e()]) : _vm._e()];
      }
    }, {
      key: "item.content",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-row', [item.joinPoint.enabled ? _c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('div', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("· 신규회원 포인트 지급")]), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 지급포인트 " + _vm._s(item.joinPoint.amount.format()) + _vm._s(item.joinPoint.unit))])]) : _vm._e(), item.purchasePoint.enabled ? _c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('div', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("· 구매확정 포인트")]), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 지급포인트 " + _vm._s(item.purchasePoint.amount.format()) + _vm._s(item.purchasePoint.unit))])]) : _vm._e(), item.joinCoupon.enabled ? _c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('div', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("· 신규회원 쿠폰 발급")]), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 할인 " + _vm._s(item.joinCoupon.amount.format()) + _vm._s(item.joinCoupon.unit))]), item.joinCoupon.unit == '%' && item.joinCoupon.maxPrice ? _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 최대 " + _vm._s(item.joinCoupon.maxPrice.format()) + "원 할인")]) : _vm._e(), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 수량 " + _vm._s(item.joinCoupon.count.format()) + "개")])]) : _vm._e(), item.monthlyCoupon.enabled ? _c('v-col', {
          staticClass: "text-start",
          attrs: {
            "cols": "12",
            "sm": "6"
          }
        }, [_c('div', {
          staticClass: "subtitle-2 font-weight-bold"
        }, [_vm._v("· 정기 쿠폰 발급")]), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 할인 " + _vm._s(item.monthlyCoupon.amount.format()) + _vm._s(item.monthlyCoupon.unit))]), item.monthlyCoupon.unit == '%' && item.monthlyCoupon.maxPrice ? _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 최대 " + _vm._s(item.monthlyCoupon.maxPrice.format()) + "원 할인")]) : _vm._e(), _c('div', {
          staticClass: "body-2"
        }, [_vm._v("- 수량 " + _vm._s(item.monthlyCoupon.count.format()) + "개")])]) : _vm._e()], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")]), item.removable ? _c('v-icon', {
          staticClass: "ml-1",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v("mdi-delete")]) : _vm._e()];
      }
    }], null, false, 966448551)
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "600px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('v-btn', {
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          },
          on: {
            "click": _vm.create
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }], null, false, 2079359153),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm.editItem._id ? _c('span', [_vm._v(_vm._s(_vm.editItem.name) + " 수정")]) : _c('span', [_vm._v("신규등급")])]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.setting.users.levelStandard == 'price' ? _c('v-select', {
    attrs: {
      "disabled": !_vm.editItem.schedule.enabled,
      "items": _vm.schedulePrices,
      "item-text": "text",
      "item-value": "value",
      "label": "등급조건",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.schedule.price,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.schedule, "price", $$v);
      },
      expression: "editItem.schedule.price"
    }
  }) : _vm._e(), _vm.setting.users.levelStandard == 'count' ? _c('v-select', {
    attrs: {
      "disabled": !_vm.editItem.schedule.enabled,
      "items": _vm.scheduleCounts,
      "item-text": "text",
      "item-value": "value",
      "label": "등급조건",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.schedule.count,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.schedule, "count", $$v);
      },
      expression: "editItem.schedule.count"
    }
  }) : _vm._e()], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급순위",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.rank,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "rank", $$v);
      },
      expression: "editItem.rank"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급코드",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "등급명칭",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1)], 1), _c('v-tabs', {
    staticClass: "mt-4",
    attrs: {
      "background-color": "white"
    }
  }, [_c('v-tab', {
    key: "point"
  }, [_vm._v("포인트")]), _c('v-tab', {
    key: "coupon"
  }, [_vm._v("쿠폰")]), _c('v-tab-item', {
    key: "point"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("포인트 적립")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.purchasePoint.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.purchasePoint, "enabled", $$v);
      },
      expression: "editItem.purchasePoint.enabled"
    }
  })], 1)], 1), _vm.editItem.purchasePoint.enabled ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pr-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "적립금액(%)",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.purchasePoint.amount,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.purchasePoint, "amount", $$v);
      },
      expression: "editItem.purchasePoint.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("% 지급")])], 1) : _vm._e(), _vm.editItem.purchasePoint.enabled ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0"
  }, [_c('span', {
    staticClass: "primary--text caption"
  }, [_vm._v("상품 구매 후 구매확정 시 포인트가 추가 적립됩니다."), _c('br'), _vm._v("재구매혜택과 중복되지 않습니다. (재구매혜택보다 낮지 않게 설정해 주세요.)")])])], 1) : _vm._e(), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("신규회원 포인트 지급")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "disabled": _vm.setting.users._level != _vm.editItem._id,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinPoint.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinPoint, "enabled", $$v);
      },
      expression: "editItem.joinPoint.enabled"
    }
  })], 1)], 1), _vm.editItem.joinPoint.enabled ? _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "적립금액(원)",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinPoint.amount,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinPoint, "amount", $$v);
      },
      expression: "editItem.joinPoint.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 지급")])], 1) : _vm._e(), _vm.editItem.joinPoint.enabled ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pb-0"
  }, [_c('span', {
    staticClass: "primary--text caption"
  }, [_vm._v("해당 등급으로 신규가입시 포인트를 지급합니다.")])])], 1) : _vm._e()], 1), _c('v-tab-item', {
    key: "coupon"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("정기 쿠폰 발급")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.monthlyCoupon.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.monthlyCoupon, "enabled", $$v);
      },
      expression: "editItem.monthlyCoupon.enabled"
    }
  })], 1)], 1), _vm.editItem.monthlyCoupon.enabled ? _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "할인금액",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.monthlyCoupon.amount,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.monthlyCoupon, "amount", $$v);
      },
      expression: "editItem.monthlyCoupon.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "label": "단위",
      "placeholder": " ",
      "items": _vm.amountUnits,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.monthlyCoupon.unit,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.monthlyCoupon, "unit", $$v);
      },
      expression: "editItem.monthlyCoupon.unit"
    }
  })], 1)], 1)], 1), _vm.editItem.monthlyCoupon.unit == '%' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("최대")]), _c('v-col', {
    staticClass: "pa-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.monthlyCoupon.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.monthlyCoupon, "maxPrice", $$v);
      },
      expression: "editItem.monthlyCoupon.maxPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 할인")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.editItem.monthlyCoupon.enabled ? _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "쿠폰발급수량",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.monthlyCoupon.count,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.monthlyCoupon, "count", $$v);
      },
      expression: "editItem.monthlyCoupon.count"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("개")])], 1) : _vm._e(), _vm.editItem.monthlyCoupon.enabled ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-1"
  }, [_c('span', {
    staticClass: "primary--text caption"
  }, [_vm._v("매월 1일 자동으로 쿠폰이 즉시 발급됩니다.(쿠폰 유효기간: 해당월 1일~말일)")])])], 1) : _vm._e(), _c('v-divider'), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "font-weight-bold",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("신규회원 쿠폰 발급")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "disabled": _vm.setting.users._level != _vm.editItem._id,
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinCoupon.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinCoupon, "enabled", $$v);
      },
      expression: "editItem.joinCoupon.enabled"
    }
  })], 1)], 1), _vm.editItem.joinCoupon.enabled ? _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "할인금액",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinCoupon.amount,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinCoupon, "amount", $$v);
      },
      expression: "editItem.joinCoupon.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.amountUnits,
      "label": "단위",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinCoupon.unit,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinCoupon, "unit", $$v);
      },
      expression: "editItem.joinCoupon.unit"
    }
  })], 1)], 1)], 1), _vm.editItem.joinCoupon.unit == '%' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("최대")]), _c('v-col', {
    staticClass: "pa-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinCoupon.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinCoupon, "maxPrice", $$v);
      },
      expression: "editItem.joinCoupon.maxPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 할인")])], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.editItem.joinCoupon.enabled ? _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "쿠폰발급수량",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.joinCoupon.count,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.joinCoupon, "count", $$v);
      },
      expression: "editItem.joinCoupon.count"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("개")])], 1) : _vm._e(), _vm.editItem.joinCoupon.enabled ? _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-1"
  }, [_c('span', {
    staticClass: "primary--text caption"
  }, [_vm._v("매월 1일 자동으로 쿠폰이 즉시 발급됩니다.(쿠폰 유효기간: 해당월 1일~말일)")])])], 1) : _vm._e()], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "grey",
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.editItem);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }