<template>
<v-container fluid fill-height class="console-terms">
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">통계 관리</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">월별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="monthlySalesStartDate" :close-on-content-click="false" :return-value.sync="monthlySalesFilter.startDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="monthlySalesFilter.startDate" label="시작일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="monthlySalesFilter.startDate" no-title scrollable type="month" @input="$refs.monthlySalesStartDate.save(monthlySalesFilter.startDate); getMonthlySales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="monthlySalesEndDate" :close-on-content-click="false" :return-value.sync="monthlySalesFilter.endDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="monthlySalesFilter.endDate" label="종료일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="monthlySalesFilter.endDate" no-title scrollable type="month" @input="$refs.monthlySalesEndDate.save(monthlySalesFilter.endDate); getMonthlySales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-chart :options="monthlySalesOption"/>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">일별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="dailySalesStartDate" :close-on-content-click="false" :return-value.sync="dailySalesFilter.startDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dailySalesFilter.startDate" label="시작일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dailySalesFilter.startDate" no-title scrollable @input="$refs.dailySalesStartDate.save(dailySalesFilter.startDate); getDailySales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="dailySalesEndDate" :close-on-content-click="false" :return-value.sync="dailySalesFilter.endDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dailySalesFilter.endDate" label="종료일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dailySalesFilter.endDate" no-title scrollable @input="$refs.dailySalesEndDate.save(dailySalesFilter.endDate); getDailySales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-row>
                        <v-col cols="4">
                            <v-chart :options="dailySalesPriceOption"/>
                        </v-col>
                        <v-col cols="4">
                            <v-chart :options="dailySalesAmountOption"/>
                        </v-col>
                        <v-col cols="4">
                            <v-chart :options="dailySalesUserOption"/>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">요일별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="dayOfWeekSalesStartDate" :close-on-content-click="false" :return-value.sync="dayOfWeekSalesFilter.startDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dayOfWeekSalesFilter.startDate" label="시작일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dayOfWeekSalesFilter.startDate" no-title scrollable @input="$refs.dayOfWeekSalesStartDate.save(dayOfWeekSalesFilter.startDate); getDayOfWeekSales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="dayOfWeekSalesEndDate" :close-on-content-click="false" :return-value.sync="dayOfWeekSalesFilter.endDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="dayOfWeekSalesFilter.endDate" label="종료일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="dayOfWeekSalesFilter.endDate" no-title scrollable @input="$refs.dayOfWeekSalesEndDate.save(dayOfWeekSalesFilter.endDate); getDayOfWeekSales()"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                    <v-chart :options="dayOfWeekSalesOption"/>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="subtitle-1">상품별매출통계</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="productsSalesStartDate" :close-on-content-click="false" :return-value.sync="productsSalesFilter.startDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="productsSalesFilter.startDate" label="시작일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="productsSalesFilter.startDate" no-title scrollable @input="$refs.productsSalesStartDate.save(productsSalesFilter.startDate); getProductsSales();"></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="auto" class="body-1">
                            <v-menu ref="productsSalesEndDate" :close-on-content-click="false" :return-value.sync="productsSalesFilter.endDate" transition="scale-transition" offset-y min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="productsSalesFilter.endDate" label="종료일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="productsSalesFilter.endDate" no-title scrollable @input="$refs.productsSalesEndDate.save(productsSalesFilter.endDate); getProductsSales();"></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-divider class="mx-3"></v-divider>
                <v-card-text>
                        <v-data-table :items="productsSales" :headers="productsSalesHeaders" >
                            <template v-slot:item.product="{ item }">
                                <v-row v-if="item.product" align="center">
                                    <v-col md="auto">
                                        <v-img v-if="item.product.thumb" :src="item.product.thumb" max-width="56" max-height="56"></v-img>
                                        <v-responsive v-else width="56" height="56">
                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                <v-icon>mdi-image</v-icon>
                                            </v-row>
                                        </v-responsive>
                                    </v-col>
                                    <v-col>
                                        <div class="text-start">{{ item.product.name }}</div>
                                    </v-col>
                                </v-row>
                            </template>
                            <template v-slot:item.purchasePrice="{ item }">
                                <div class="pr-4">{{ item.purchasePrice.format() }}</div>
                            </template>
                            <template v-slot:item.purchaseAmount="{ item }">
                                <div class="pr-4">{{ item.purchaseAmount.format() }}</div>
                            </template>
                        </v-data-table>
                </v-card-text>
            </v-card>


        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import ECharts from 'vue-echarts';

export default {
    components: {
        'v-chart': ECharts
    },
    created() {
        this.init();
    },
    data: () => {
        return {
            ready: false,

            polar: {
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [100000, 500000, 1000000, 5000000],
                    type: 'line',
                    areaStyle: {}
                }]
            },

            // 월별매출통계
            monthlySales: [],
            monthlySalesFilter: {
                startDate: null,
                endDate: null
            },

            // 일별매출통계
            dailySales: [],
            dailySalesFilter: {
                startDate: null,
                endDate: null,
            },

            // 요일별매출통계
            dayOfWeekSales: [],
            dayOfWeekSalesFilter: {
                startDate: null,
                endDate: null
            },

            // 상품별매출통계
            productsSales: [],
            productsSalesFilter: {
                startDate: null,
                endDate: null
            },
            productsSalesHeaders: [
                {
                    text: "상품정보",
                    value: "product"
                },
                {
                    text: "판매금액",
                    align: "right",
                    value: "purchasePrice"
                },
                {
                    text: "판매수량",
                    align: "right",
                    value: "purchaseAmount"
                },
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                this.monthlySalesFilter.startDate = (new Date().addYear(-1)).toDate().substr(0, 7);
                this.monthlySalesFilter.endDate = Date.now().toDate().substr(0, 7);

                this.dailySalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.dailySalesFilter.endDate = Date.now().toDate();

                this.dayOfWeekSalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.dayOfWeekSalesFilter.endDate = Date.now().toDate();

                this.productsSalesFilter.startDate = (new Date().addMonth(-1)).toDate();
                this.productsSalesFilter.endDate = Date.now().toDate();

                await this.getMonthlySales();
                await this.getDailySales();
                await this.getDayOfWeekSales();
                await this.getProductsSales();

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getMonthlySales(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/statistics/monthlySales", {
                        params: this.monthlySalesFilter
                    });

                    this.monthlySales = res.data.monthlySales;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDailySales(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/statistics/dailySales", {
                        params: this.dailySalesFilter
                    });

                    this.dailySales = res.data.dailySales;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDayOfWeekSales(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/statistics/dayOfWeekSales", {
                        params: this.dayOfWeekSalesFilter
                    });

                    this.dayOfWeekSales = res.data.dayOfWeekSales;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getProductsSales(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/statistics/productsSales", {
                        params: this.productsSalesFilter
                    });

                    this.productsSales = res.data.productsSales;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

    },
    computed: {
        monthlySalesOption(){
            var xAxisData = [];
            for(var date = new Date(this.monthlySalesFilter.startDate); date <= new Date(this.monthlySalesFilter.endDate); date.addMonth(1)){
                xAxisData.push(date.getTime().toDate().substr(0, 7));
            }
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.monthlySales.map((statistic) => statistic.totalPrice),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesPriceOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제금액", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.totalPrice),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesAmountOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제건수", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 1,
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.totalCount),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dailySalesUserOption(){
            var xAxisData = [];
            for(var date = new Date(this.dailySalesFilter.startDate); date <= new Date(this.dailySalesFilter.endDate); date.addDate(1)){
                xAxisData.push(date.getTime().toDate());
            }
            return {
                title: { text: "일별결제자수", textStyle: { fontWeight: "normal", fontSize: 14 } },
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value',
                    splitNumber: 1
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dailySales.map((statistic) => statistic.userCount),
                    type: 'line',
                    areaStyle: {}
                }]
            };
        },
        dayOfWeekSalesOption(){
            return {
                color: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].primary,
                xAxis: {
                    type: 'category',
                    data: ["월","화","수","목","금","토","일"],
                },
                yAxis: {
                    type: 'value',
                },
                tooltip: {
                    trigger: 'axis',
                },
                series: [{
                    data: this.dayOfWeekSales.map((statistic) => statistic.totalPrice),
                    type: 'bar',
                    areaStyle: {}
                }]
            };
        },
    }
}
</script>

<style>
.echarts{ width: 100%; }
</style>
