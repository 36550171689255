<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">도서산간설정 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="islandsEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="islandsEnabled">
            <v-row v-for="island in islands">
                <v-col cols="12">
                    <v-text-field v-model="island.price" :label="`'${island.name}' 지역 추가배송비`" placeholder=" " dense hide-details @change="input"></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            islandsEnabled: false,

            islands: []
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getIslands();

                var { islandsEnabled, islands } = this.value;

                if(islandsEnabled === undefined) islandsEnabled = this.islandsEnabled;
                if(islands === undefined) islands = this.islands;

                Object.assign(this.$data, {
                    islandsEnabled
                });

                if(this.value.islands){
                    for(var i in this.islands){
                        var island = this.value.islands.find((island) => this.islands[i].code == island.code );
                        if(island){
                            Object.assign(this.islands[i], island);
                        }
                        else{
                            Object.assign(this.islands[i], { price: 0 });
                        }
                    }
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "islandsEnabled", this.islandsEnabled);
            this.$set(this.value, "islands", this.islands);

            this.$emit('input', this.value);
        },
        getIslands(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/shop/islands");

                    this.islands = res.data.islands;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
