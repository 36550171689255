<template>
    <v-card class="mt-4">
        <v-card-title class="subtitle-1">취급컬렉션</v-card-title>
        <v-card-text>
            <v-chip v-for="(collection, index) in collections"close close-icon="mdi-delete" @click:close="collections.splice(index, 1)" color="primary" class="mr-2">{{ collection }}</v-chip>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <v-row class="mt-2" align="center">
                <v-col class="py-0">
                    <v-text-field v-model="collection" label="컬렉션명칭" placeholder=" " dense hide-details></v-text-field>
                </v-col>
                <v-col cols="auto" class="py-0 pl-0">
                    <v-btn color="primary" @click="insert"><v-icon class="mr-2">mdi-plus</v-icon>추가</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            collections: [],
            collection: null
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { collections } = this.value;

                Object.assignDefined(this.$data, {
                    collections,
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "collections", this.collections);
            this.$emit('input', this.value);
        },
        insert(){
            this.collections.push(this.collection);
            this.input();
        },
    }
}
</script>
