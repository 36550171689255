<template>
    <v-card class="mt-4">
        <v-card-title>
            <v-row>
                <v-col cols="auto" class="subtitle-1 py-0">할인사용 (사용/미사용)</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="py-0">
                    <v-switch v-model="discountEnabled" :value="true" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="discountEnabled">
            <v-row class="mt-3">
                <v-col cols="9" class="py-0">
                    <v-text-field v-model="discount.amount" label="할인금액" placeholder=" " hide-details class="pt-0" @change="input"></v-text-field>
                </v-col>
                <v-col cols="3" class="py-0">
                    <v-select v-model="discount.unit" :items="unitHeaders" item-text="text" item-value="value" hide-details class="pt-0"></v-select>
                </v-col>
            </v-row>
            <v-row class="mt-6">
                <v-col cols="auto" class="py-0"><v-checkbox v-model="discount.expiration.enabled" label="특정기간만할인" hide-details class="mt-0"></v-checkbox></v-col>
            </v-row>
            <v-row v-if="discount.expiration.enabled" class="mt-4">
                <v-col cols="12" sm="6" class="py-0">
                    <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="discount.expiration.startDate" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="discount.expiration.startDate" label="할인시작일자" placeholder=" " prepend-icon="event" dense hide-details readonly v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker v-model="discount.expiration.startDate" no-title scrollable @input="$refs.startDateMenu.save(discount.expiration.startDate)"></v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                    <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="discount.expiration.endDate" transition="scale-transition" offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="discount.expiration.endDate" label="할인종료일자" placeholder=" " prepend-icon="event" dense hide-details readonly v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="discount.expiration.endDate" no-title scrollable @input="$refs.endDateMenu.save(discount.expiration.endDate)"></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            discountEnabled: false,

            discount: {
                amount: 0,
                unit: 'won',
                expiration: {
                    enabled: false,
                    startDate: '',
                    endDate: ''
                }
            },

            unitHeaders: [
                { text: '%', value: 'percent' },
                { text: '원', value: 'won' }
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            try{
                var { discountEnabled, discount } = this.value;

                if(discountEnabled === undefined) discountEnabled = this.discountEnabled;
                if(discount === undefined) discount = this.discount;

                if(discount.expiration.startDate) discount.expiration.startDate = discount.expiration.startDate.toDate();
                if(discount.expiration.endDate) discount.expiration.endDate = discount.expiration.endDate.toDate();
                Object.assign(this.$data, {
                    discountEnabled, discount
                });

            }
            catch(error){
                console.error(error);
            }
        },

        input(){
            this.$set(this.value, 'discountEnabled', this.discountEnabled);
            this.$set(this.value, 'discount', this.discount);
            this.$emit('input', this.value);
        },

    }
}
</script>
