<template>
    <v-layout justify-center>
        <v-responsive max-width="1980px" width="100%" class="px-0 px-md-4 px-xl-8">
            <v-row class="mt-0 mt-md-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">미결제 관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                </v-col>
            </v-row>

            <v-card class="mt-4">
                <v-card-text>
                    구매자가 나중에결제/무통장입금으로 주문한 이후, 아직 결제 완료되지 않은 주문건입니다.
                </v-card-text>
            </v-card>

            <v-data-table v-model="selected" :headers="purchasesHeaders" :items="purchases" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.order.sender="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template v-slot:item.order.receiver="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-card class="mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">주문확인</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="orderPayment">결제완료</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

        </v-responsive>
    </v-layout>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null,
                orderStatuses: ["order-nonpayment"]
            },
            searchKeys: [
                { text: "전체", value: null },
                { text: "수취인명", value: "receiver.name" },
                { text: "구매자명", value: "sender.name" },
                { text: "구매자연락처", value: "sender.phone" },
                { text: "구매자ID", value: "sender.username" },
                { text: "주문번호", value: "orderNo" },
                { text: "상품주문번호", value: "purchaseNo" },
            ],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 10,

            selected: [],
            purchases: [],
            purchasesHeaders: [{
                text: '상품주문번호',
                align: 'center',
                width: 140,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 140,
                value: 'orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 140,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 140,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 140,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'user.phone'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 100,
                value: 'product.name'
            },
            {
                text: '옵션정보',
                align: 'center',
                width: 100,
                value: 'name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'user.username'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getPurchases();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async search(params){
            try{
                Object.assign(this.filter, params);

                await this.getPurchases();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async orderPayment(){
            try{
                if(!this.selected.length) throw new Error("결제완료 처리를 위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "order-payment"
                    });
                }

                alert("결제완료 처리 되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        getPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/purchases', {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.purchases = res.data.purchases;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putPurchase(purchase){
            return new Promise(async (resolve, rejcet) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/purchases/${purchase._id}`, purchase);

                    resolve(res.data.purchase);
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
