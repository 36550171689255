var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("운영정보설정")]), _c('v-spacer'), _c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  })], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("운송업체설정")]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-data-table', {
    attrs: {
      "headers": _vm.deliveryHeaders,
      "items": _vm.deliveries,
      "max-height": "400px",
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: "item.url",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('v-text-field', {
          attrs: {
            "placeholder": "ex) https://example.com?deliveryCode=",
            "dense": "",
            "hide-details": ""
          },
          on: {
            "input": function ($event) {
              return _vm.putDelivery(item);
            }
          },
          model: {
            value: item.url,
            callback: function ($$v) {
              _vm.$set(item, "url", $$v);
            },
            expression: "item.url"
          }
        })];
      }
    }, {
      key: "item.actions",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v("mdi-pencil")])];
      }
    }], null, false, 2663328382)
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("배송정책")]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-radio-group', {
    staticClass: "ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.putSetting
    },
    model: {
      value: _vm.setting.shop._shipping,
      callback: function ($$v) {
        _vm.$set(_vm.setting.shop, "_shipping", $$v);
      },
      expression: "setting.shop._shipping"
    }
  }, [_c('v-data-table', {
    attrs: {
      "headers": _vm.shippingsHeaders,
      "items": _vm.shippings,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.shipping",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-radio', {
          attrs: {
            "label": item.name,
            "value": item._id
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function () {
              return [_c('v-icon', {
                staticClass: "mx-2",
                attrs: {
                  "small": "",
                  "color": item.color
                }
              }, [_vm._v(_vm._s(item.icon))]), _c('span', {
                staticClass: "subtitle-2"
              }, [_vm._v(_vm._s(item.name))])];
            },
            proxy: true
          }], null, true)
        })];
      }
    }, {
      key: "item.config",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.code == 'product' ? _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "배송비(원)",
            "placeholder": " ",
            "outlined": "",
            "dense": "",
            "hide-details": ""
          },
          model: {
            value: item.price,
            callback: function ($$v) {
              _vm.$set(item, "price", $$v);
            },
            expression: "item.price"
          }
        })], 1)], 1) : _vm._e(), item.code == 'order' ? _c('v-row', [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "주문금액(원)",
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "placeholder": " "
          },
          model: {
            value: item.range.basePrice,
            callback: function ($$v) {
              _vm.$set(item.range, "basePrice", $$v);
            },
            expression: "item.range.basePrice"
          }
        })], 1), _c('v-col', {
          staticClass: "py-0"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("이상 구매시")]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "배송비(원)",
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "placeholder": " "
          },
          model: {
            value: item.range.highPrice,
            callback: function ($$v) {
              _vm.$set(item.range, "highPrice", $$v);
            },
            expression: "item.range.highPrice"
          }
        })], 1)], 1), _c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_vm._v("미만 구매시")]), _c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "배송비(원)",
            "outlined": "",
            "dense": "",
            "hide-details": "",
            "placeholder": " "
          },
          model: {
            value: item.range.lowPrice,
            callback: function ($$v) {
              _vm.$set(item.range, "lowPrice", $$v);
            },
            expression: "item.range.lowPrice"
          }
        })], 1)], 1)], 1)], 1) : _vm._e(), item.code == 'free' ? _c('v-row', [_c('v-col', {
          staticClass: "py-0 text-start"
        }, [_vm._v("※ 해당 제품 구매시 무료배송으로 표시됩니다.")])], 1) : _vm._e(), item.code == 'later' ? _c('v-row', [_c('v-col', {
          staticClass: "py-0 text-start"
        }, [_vm._v("※ 해당 제품 구매시 착불배송으로 표시됩니다.")])], 1) : _vm._e()];
      }
    }], null, false, 4154622732)
  })], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("도서산간설정")]), _c('v-card-text', {
    staticClass: "pa-0"
  }, [_c('v-divider'), _c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.islands, function (island) {
    return _c('v-tab', {
      key: island.code
    }, [_vm._v(_vm._s(island.name))]);
  }), _vm._l(_vm.islands, function (island) {
    return _c('v-tab-item', {
      key: island.code
    }, [_c('v-divider'), _c('v-data-table', {
      attrs: {
        "headers": _vm.islandHeaders,
        "items": island.areas,
        "disable-sort": ""
      },
      scopedSlots: _vm._u([{
        key: "item.createDate",
        fn: function (_ref5) {
          var item = _ref5.item;
          return [_vm._v(" " + _vm._s(item.createDate.toDateTime()) + " ")];
        }
      }, {
        key: "item.remark",
        fn: function (_ref6) {
          var item = _ref6.item;
          return undefined;
        }
      }, {
        key: "item.actions",
        fn: function (_ref7) {
          var item = _ref7.item;
          return [_c('v-icon', {
            attrs: {
              "small": ""
            },
            on: {
              "click": function ($event) {
                return _vm.edit(item);
              }
            }
          }, [_vm._v("mdi-pencil")])];
        }
      }], null, true)
    })], 1);
  })], 2)], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }