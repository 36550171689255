<template>
    <v-container v-if="ready" fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">운영정보설정</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0"></v-col>
                </v-row>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">운송업체설정</v-card-title>
                    <v-card-text class="pa-0">
                        <v-divider/>
                        <v-data-table :headers="deliveryHeaders" :items="deliveries" max-height="400px" disable-sort>
                            <template v-slot:item.url="{ item }">
                                <v-text-field v-model="item.url" placeholder="ex) https://example.com?deliveryCode=" dense hide-details @input="putDelivery(item)"></v-text-field>
                            </template>
                            <template v-slot:item.actions="{item}">
                                <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">배송정책</v-card-title>
                    <v-card-text class="pa-0">
                        <v-divider/>
                        <v-radio-group v-model="setting.shop._shipping" dense class="ma-0" hide-details @change="putSetting">
                            <v-data-table :headers="shippingsHeaders" :items="shippings" disable-sort disable-pagination hide-default-footer>
                                <template v-slot:item.shipping="{ item }">
                                    <v-radio :label="item.name" :value="item._id">
                                        <template v-slot:label>
                                            <v-icon class="mx-2" small :color="item.color">{{ item.icon }}</v-icon><span class="subtitle-2">{{ item.name }}</span>
                                        </template>
                                    </v-radio>
                                </template>
                                <template v-slot:item.config="{ item }">
                                    <v-row v-if="item.code == 'product'" align="center">
                                        <v-col cols="auto">
                                            <v-text-field v-model="item.price" label="배송비(원)" placeholder=" " outlined dense hide-details></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="item.code == 'order'">
                                        <v-col cols="auto">
                                            <v-text-field v-model="item.range.basePrice" label="주문금액(원)" outlined dense hide-details placeholder=" "></v-text-field>
                                        </v-col>
                                        <v-col class="py-0">
                                            <v-row align="center">
                                                <v-col cols="auto">이상 구매시</v-col>
                                                <v-col cols="auto">
                                                    <v-text-field v-model="item.range.highPrice" label="배송비(원)" outlined dense hide-details placeholder=" "></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row align="center">
                                                <v-col cols="auto">미만 구매시</v-col>
                                                <v-col cols="auto">
                                                    <v-text-field v-model="item.range.lowPrice" label="배송비(원)" outlined dense hide-details placeholder=" "></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="item.code == 'free'">
                                        <v-col class="py-0 text-start">※ 해당 제품 구매시 무료배송으로 표시됩니다.</v-col>
                                    </v-row>
                                    <v-row v-if="item.code == 'later'">
                                        <v-col class="py-0 text-start">※ 해당 제품 구매시 착불배송으로 표시됩니다.</v-col>
                                    </v-row>
                                </template>
                            </v-data-table>
                        </v-radio-group>
                    </v-card-text>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">도서산간설정</v-card-title>
                    <v-card-text class="pa-0">
                        <v-divider/>
                        <v-tabs v-model="tab">
                            <v-tab v-for="island in islands" :key="island.code">{{ island.name }}</v-tab>
                            <v-tab-item v-for="island in islands" :key="island.code" >
                                <v-divider/>
                                <v-data-table :headers="islandHeaders" :items="island.areas" disable-sort>
                                    <template v-slot:item.createDate="{item}">
                                        {{ item.createDate.toDateTime() }}
                                    </template>
                                    <template v-slot:item.remark="{item}">

                                    </template>
                                    <template v-slot:item.actions="{item}">
                                        <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                                    </template>
                                </v-data-table>
                            </v-tab-item>
                        </v-tabs>
                    </v-card-text>
                </v-card>

            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data: () => {
        return {
            ready: false,

            setting: {},

            ///////////////////////////////////////////////
            // 운송업체설정
            ///////////////////////////////////////////////
            deliveries: [],
            deliveryHeaders: [
                {
                    text: "코드",
                    align: "center",
                    width: 140,
                    value: "code"
                },
                {
                    text: "운송업체명칭",
                    align: "center",
                    width: 250,
                    value: "name"
                },
                {
                    text: "URL",
                    align: "center",
                    value: "url"
                },
            ],

            ///////////////////////////////////////////////
            // 도서산간
            ///////////////////////////////////////////////
            islands: [],
            islandHeaders: [
                {
                    text: "우편번호",
                    align: "center",
                    width: 140,
                    value: "postcode"
                },
                {
                    text: "도로명주소",
                    align: "center",
                    value: "address"
                },
            ],

            ///////////////////////////////////////////////
            // 배송정책
            ///////////////////////////////////////////////
            shippings: [],
            shippingsHeaders: [
                {
                    text: "기본정책",
                    align: "center",
                    width: 200,
                    value: "shipping"
                },
                {
                    text: "설정",
                    align: "center",
                    value: "config"
                }
            ],

        };
    },
    created(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getDeliveries();
                await this.getIslands();
                await this.getShippings();
                await this.getSetting();

                this.ready = true;
            }
            catch(error){
                if(error.response) alert(error.response.data.message);
                console.error(error);
            }
        },

        getDeliveries(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/deliveries');

                    this.deliveries = res.data.deliveries;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putDelivery(delivery){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/deliveries/${delivery._id}`, delivery);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getShippings(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/shippings');

                    this.shippings = res.data.shippings;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getIslands(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/islands');

                    this.islands = res.data.islands;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/setting");

                    this.setting = res.data.setting;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put("/api/console/setting", this.setting);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }

    }
}
</script>
