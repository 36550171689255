<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">게시글 목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pt-4 pb-0">
                        <v-row>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.createDates[0]" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.createDates[0]" label="작성일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.createDates[0]" no-title scrollable @input="$refs.startDateMenu.save(filter.createDates[0])"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.createDates[1]" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.createDates[1]" label="작성일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.createDates[1]" no-title scrollable @input="$refs.endDateMenu.save(filter.createDates[1])"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="3">
                                <v-select v-model="filter.searchKey" :items="searchKeys" label="검색조건" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="6" md="3">
                                <v-text-field v-model="filter.searchValue" label="검색어" placeholder=" " :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page = 1; search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-spacer/>
                <v-col cols="auto">
                    <v-btn v-if="$route.params.code == 'interior-shop'" color="white" class="pink--text px-2 ml-1" @click="download('/assets/data/upload-example.xlsx')">
                        <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>
                        <span>엑셀 업로드 양식</span>
                    </v-btn>
                    <v-btn v-if="$route.params.code == 'interior-shop'" color="white" class="green--text px-2 ml-1" @click="upload">
                        <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>
                        <span>엑셀 업로드</span>
                    </v-btn>
                </v-col>
            </v-row>

            <v-data-table :items="boards" :headers="boardsHeaders.filter((header) => header.code.includes($route.params.code))" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.thumb="{ item }">
                    <v-responsive align="center" max-width="96">
                        <v-img v-if="item.thumb" :src="item.thumb" max-width="96" max-height="96" contain></v-img>
                    </v-responsive>
                </template>
                <template v-slot:item.subject="{ item }">
                    <span @click="edit(item)">{{ item.subject }}</span>
                </template>
                <template v-slot:item.address="{item}">
                    [{{ item.postcode }}] {{ item.address1 }} {{ item.address2 }}
                </template>
                <template v-slot:item.reply="{item}">
                    <span v-if="item.reply">답변</span>
                    <span v-else>미답변</span>
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon v-if="false" small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>

        </v-responsive>

        <shop-upload-dialog ref="shopUploadDialog" @upload="search(false)" />
    </v-layout>
</template>

<script>
import { mdiMicrosoftExcel } from "@mdi/js";
import download from "downloadjs";
import ShopUploadDialog from "@/components/console/boards/shop-upload-dialog.vue";
export default {
    components: {
        ShopUploadDialog
    },
    data() {
        return {
            mdiMicrosoftExcel,

            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,

            category: null,
            filter: {
                code: this.$route.params.code,
                searchKey: "",
                searchValue: "",
                createDates: [null, null]
            },

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 10,

            boards: [],
            boardsHeaders: [{
                text: '카테고리',
                align: 'center',
                width: 100,
                value: 'category',
                code: ['event']
            },
            {
                text: '썸네일',
                align: 'center',
                width: 128,
                value: 'thumb',
                code: ['support']
            },
            {
                text: '작성일',
                align: 'center',
                width: 200,
                value: 'createDate',
                code: ['interior-inquire']
            },
            {
                text: '제목',
                align: 'center',
                value: 'subject',
                code: ['support', 'interior-inquire', 'event']
            },
            {
                text: '한줄설명',
                align: 'center',
                width: 140,
                value: 'summary',
                code: ['support', 'event']
            },
            {
                text: '기간',
                width: 100,
                align: 'center',
                value: 'period',
                code: ['event']
            },
            {
                text: '지역',
                align: 'center',
                width: 80,
                value: 'area',
                code: ['interior-shop']
            },
            {
                text: '취급점명',
                align: 'center',
                width: 80,
                value: 'storeName',
                code: ['interior-shop']
            },
            {
                text: '취급점 연락처',
                align: 'center',
                width: 80,
                value: 'storePhone',
                code: ['interior-shop']
            },
            {
                text: '주소',
                align: 'center',
                width: 80,
                value: 'address',
                code: ['interior-shop']
            },
            {
                text: '작성자',
                align: 'center',
                width: 80,
                value: 'writer.name',
                code: ['interior-inquire']
            },
            {
                text: '답변여부',
                align: 'center',
                width: 80,
                value: 'reply',
                code: ['interior-inquire']
            },
            {
                text: 'Actions',
                width: 100,
                align: 'center',
                value: 'actions',
                code: ['support', 'interior-shop', 'interior-inquire', 'event']
            }],

            searchKeys: [
                { text: "전체", value: null },
                // { text: "작성자", value: "writer.name" },
                { text: "제목", value: "subject" },
                { text: "내용", value: "content" },
            ],
            searchKey: null,
            searchValue: null,

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            this.search(false);
        },
        async search(routable = true){
            try{
                if(routable){
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page })
                    });
                }

                var { summary, boards } = await this.getBoards({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.boards = boards;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.$router.push({
                path: this.$route.path,
                query: {
                    _board: null
                }
            });
        },
        copy(board){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _board: board._id,
                    method: "copy"
                }
            });
        },
        edit(board){
            this.$router.push({
                query: {
                    _board: board._id
                }
            });
        },
        async remove(board){
            try{
                if(confirm("게시글을 삭제하시겠습니까?")){
                    await this.deleteBoard(board);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            }
            catch(error){
                console.error(error);
            }
        },

        download(path, rename = "example.xlsx"){
            this.getResource(path).then(file => download(file, rename));
        },

        getResource(filename){
            return this.$http({
                url: filename,
                method: 'GET',
                responseType: 'blob'
            }).then(result => { 
                try{
                    return new File([result.data], filename);
                }
                catch(error){
                    var blob = new Blob([result.data]);
                    blob.name = filename;
                    return blob;
                }
            });
        },

        getBoards(data){
            return this.$http.get("/api/console/boards", data).then(res => res.data);
        },

        deleteBoard(data){
            return this.$http.delete(`/api/console/boards/${data._id}`).then(res => res.data);
        },

        upload(){
            this.$refs["shopUploadDialog"].open();
        }
    },
    computed: {
        code(){
            return this.$route.params.code;
        }
    },
    watch: {
        code(){
            this.filter.code = this.code;
            this.search();
        }
    }
}
</script>
