var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-6 shop-product-supplies"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("베스트 매치 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.bestMatchsEnabled,
      callback: function ($$v) {
        _vm.bestMatchsEnabled = $$v;
      },
      expression: "bestMatchsEnabled"
    }
  })], 1)], 1)], 1), _vm.bestMatchsEnabled && _vm.bestMatchs.length ? _c('v-card-text', {
    staticClass: "pb-0"
  }, [_c('draggable', {
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.bestMatchs,
      callback: function ($$v) {
        _vm.bestMatchs = $$v;
      },
      expression: "bestMatchs"
    }
  }, [_c('transition-group', _vm._l(_vm.bestMatchs, function (bestMatch, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pa-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-divider')], 1), _vm._l(bestMatch.products, function (product) {
      return _c('v-col', {
        attrs: {
          "cols": "auto"
        }
      }, [_vm._v(" " + _vm._s(product.combinedCodeName) + " ")]);
    }), _c('v-spacer'), _c('v-col', {
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          _vm.bestMatchs.splice(index, 1);
          _vm.input();
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 2);
  }), 1)], 1)], 1) : _vm._e(), _c('v-divider'), _vm.bestMatchsEnabled ? _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "multiple": "",
      "loading": _vm.productLoading,
      "items": _vm.products,
      "item-text": "combinedCodeName",
      "return-object": "",
      "search-input": _vm.searchValue,
      "label": "제품이름 또는 코드로 검색",
      "placeholder": " "
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }