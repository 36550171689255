<template>
    <v-card class="mt-4">
        <v-card-title class="subtitle-1">시공포트폴리오</v-card-title>
        <v-card-text v-if="portfolios.length">
            <v-card v-for="portfolio in portfolios" v-if="!portfolio.removed" width="283" class="d-inline-block mr-2">
                <v-card-title class="pa-0">
                    <v-img v-if="portfolio.imageFile || portfolio.image" :src="portfolio.imageFile ? createObjectURL(portfolio.imageFile) : portfolio.image" width="283" height="185" contain></v-img>
                    <v-responsive v-else width="283" height="185" class="grey lighten-2">
                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                    </v-responsive>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-row class="mt-4">
                        <v-col cols="auto" class="py-0">{{ portfolio.spot }}</v-col>
                        <v-divider vertical class="py-0"/>
                        <v-col cols="auto" class="py-0">{{ portfolio.size }}</v-col>
                        <v-divider vertical class="py-0"/>
                        <v-col cols="auto" class="py-0">{{ portfolio.style }}</v-col>
                    </v-row>
                    <v-row class="my-4">
                        <v-col cols="12" class="py-0">{{ portfolio.name }}</v-col>
                    </v-row>
                </v-card-text>
                <v-divider/>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn icon @click="remove(portfolio)"><v-icon>mdi-delete</v-icon></v-btn>
                </v-card-actions>
            </v-card>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
            <v-row class="mt-2">
                <v-col cols="auto" class="py-0">
                    <v-img v-if="portfolio.imageFile" width="283" height="185" :src="createObjectURL(portfolio.imageFile)" contain></v-img>
                    <v-responsive v-else width="283" height="185" class="grey lighten-2">
                        <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                    </v-responsive>
                    <v-file-input v-model="portfolio.imageFile" label="포트폴리오 사진" placeholder=" " class="mt-2" show-size accept="image/*"></v-file-input>
                </v-col>
                <v-col class="py-0">
                    <v-row class="mt-6" align="center">
                        <v-col cols="4" class="py-0">
                            <v-text-field v-model="portfolio.spot" label="시공장소" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-text-field v-model="portfolio.size" label="평수" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-text-field v-model="portfolio.style" label="스타일" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6" align="center">
                        <v-col class="py-0">
                            <v-text-field v-model="portfolio.name" label="포트폴리오 명칭" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="auto" class="py-0 pl-0">
                    <v-btn color="primary" @click="insert"><v-icon class="mr-2">mdi-plus</v-icon>추가</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            portfolios: [],
            portfolio: {
                spot: null,
                size: null,
                style: null,
                name: null,
                image: null,
                imageFile: null,
            },
            portfolioDefault: {
                spot: null,
                size: null,
                style: null,
                name: null,
                image: null,
                imageFile: null,
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { portfolios } = this.value;

                Object.assignDefined(this.$data, {
                    portfolios,
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "portfolios", this.portfolios);
            this.$emit('input', this.value);
        },
        insert(){
            if(!this.portfolio.imageFile){
                alert("포트폴리오 이미지를 등록해주세요");
                return;
            }
            this.portfolios.push(this.portfolio);
            this.portfolio = Object.assign({}, this.portfolioDefault);
            this.input();
        },
        remove(portfolio){
            if(portfolio.imageFile) this.portfolios.splice(this.portfolios.indexOf(portfolio), 1);
            else this.$set(portfolio, "removed", true);
            this.input();
        },
        createObjectURL(file){
            return URL.createObjectURL(file);
        }
    }
}
</script>
