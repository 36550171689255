var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.accessToken ? _c('console-app') : _c('console-sign-in')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }