var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-coupon-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.$route.query._coupon === undefined ? _c('span', [_vm._v("쿠폰 등록")]) : _c('span', [_vm._v("쿠폰 정보")])]), _c('v-spacer')], 1), _c('v-tabs', {
    staticClass: "mt-4",
    attrs: {
      "background-color": "content"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    key: "first",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_vm._v("첫구매고객")]), _c('v-tab', {
    key: "second",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_vm._v("재구매고객")]), _c('v-tab', {
    key: "target",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_vm._v("타겟팅")]), _c('v-tab-item', {
    key: "first"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _vm._v("첫구매고객 대상")], 1), _c('v-card-text', [_c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("최근 2년간 구매이력이 없는 고객을 대상으로 혜택을 설정할 수 있습니다.")]), _c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("첫구매고객을 위한 첫구매 쿠폰(다운로드) 혜택을 등록해 보세요.")])])], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰명칭")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "쿠폰 이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.name,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "name", $$v);
      },
      expression: "firstCoupon.name"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰종류")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.firstCoupon.type,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "type", $$v);
      },
      expression: "firstCoupon.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "상품할인",
      "value": "product"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "카테고리할인",
      "value": "category"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "주문금액할인",
      "value": "order"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "배송비할인",
      "value": "delivery"
    }
  })], 1), _vm.firstCoupon.type == 'category' ? _c('shop-category-select', {
    staticClass: "mt-4",
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.firstCoupon._category = _vm.firstCoupon.category._id;
      }
    },
    model: {
      value: _vm.firstCoupon.category,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "category", $$v);
      },
      expression: "firstCoupon.category"
    }
  }) : _vm._e(), _vm.firstCoupon.type == 'product' ? _c('v-autocomplete', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.productLoading,
      "search-input": _vm.searchValue,
      "label": "할인상품",
      "placeholder": " ",
      "items": _vm.products,
      "item-text": "name",
      "item-value": "_id",
      "hide-details": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attr = _ref.attr,
          on = _ref.on,
          item = _ref.item,
          selected = _ref.selected;
        return [_c('v-card', {
          staticClass: "mr-4 px-2 my-2"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)], 1)];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "py-1",
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "py-1 pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }], null, false, 142472018),
    model: {
      value: _vm.firstCoupon._product,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "_product", $$v);
      },
      expression: "firstCoupon._product"
    }
  }) : _vm._e(), _vm.firstCoupon.type == 'product' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("즉시할인과 중복으로 사용할 수 있는쿠폰입니다.")]) : _vm._e(), _vm.firstCoupon.type == 'order' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)")]) : _vm._e(), _vm.firstCoupon.type == 'delivery' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.")]) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("발급방법")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.firstCoupon.method,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "method", $$v);
      },
      expression: "firstCoupon.method"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "다운로드",
      "value": "download"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "고객에게 즉시발급",
      "value": "immediately"
    }
  })], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.")])], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("할인설정")]), _c('v-card-text', [_c('v-row', {
    staticClass: "m",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "할인금액",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.amount,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "amount", $$v);
      },
      expression: "firstCoupon.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.amountUnits,
      "label": "단위",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.unit,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "unit", $$v);
      },
      expression: "firstCoupon.unit"
    }
  })], 1)], 1)], 1), _vm.firstCoupon.unit == '%' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("최대")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "maxPrice", $$v);
      },
      expression: "firstCoupon.maxPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 할인")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("최소주문금액")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.minPrice,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon, "minPrice", $$v);
      },
      expression: "firstCoupon.minPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 이상 구매시 사용 가능")])], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다")]), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v("( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )")])], 1)], 1), _vm.firstCoupon.method == 'download' ? _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("혜택기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0 pr-0 caption",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("특정 기간만 혜택 제공")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.download.period,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.download, "period", $$v);
      },
      expression: "firstCoupon.download.period"
    }
  })], 1)], 1)], 1), _vm.firstCoupon.download.period ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "value": _vm.getTerm(_vm.firstCoupon.download.startDate, _vm.firstCoupon.download.endDate),
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.setTerm($event, _vm.firstCoupon.download);
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "1주일",
      "value": 0.25
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1개월",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "3개월",
      "value": 3
    }
  }), _c('v-radio', {
    attrs: {
      "label": "6개월",
      "value": 6
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1년",
      "value": 12
    }
  })], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', [_c('v-menu', {
    ref: "firstDownloadStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.firstCoupon.download.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.firstCoupon.download, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.firstCoupon.download, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.firstCoupon.download.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.firstCoupon.download, "startDate", $$v);
            },
            expression: "firstCoupon.download.startDate"
          }
        }, on))];
      }
    }], null, false, 161040798)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadStartDate.save(_vm.firstCoupon.download.startDate);
      }
    },
    model: {
      value: _vm.firstCoupon.download.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.download, "startDate", $$v);
      },
      expression: "firstCoupon.download.startDate"
    }
  })], 1)], 1), _c('v-col', [_c('v-menu', {
    ref: "firstDownloadEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.firstCoupon.download.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.firstCoupon.download, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.firstCoupon.download, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.firstCoupon.download.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.firstCoupon.download, "endDate", $$v);
            },
            expression: "firstCoupon.download.endDate"
          }
        }, on))];
      }
    }], null, false, 4024701397)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadEndDate.save(_vm.firstCoupon.download.endDate);
      }
    },
    model: {
      value: _vm.firstCoupon.download.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.download, "endDate", $$v);
      },
      expression: "firstCoupon.download.endDate"
    }
  })], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v(" 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("쿠폰유효기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.expiration.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.expiration, "enabled", $$v);
      },
      expression: "firstCoupon.expiration.enabled"
    }
  })], 1)], 1)], 1), _vm.firstCoupon.expiration.enabled ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.expiration.type,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.expiration, "type", $$v);
      },
      expression: "firstCoupon.expiration.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "기간으로설정",
      "value": "dates"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "발급일 기준으로 설정",
      "value": "days"
    }
  })], 1), _vm.firstCoupon.expiration.type == 'dates' ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.firstCoupon.expiration.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.firstCoupon.expiration, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.firstCoupon.expiration, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.firstCoupon.expiration.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.firstCoupon.expiration, "startDate", $$v);
            },
            expression: "firstCoupon.expiration.startDate"
          }
        }, on))];
      }
    }], null, false, 1374542816)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationStartDate.save(_vm.firstCoupon.expiration.startDate);
      }
    },
    model: {
      value: _vm.firstCoupon.expiration.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.expiration, "startDate", $$v);
      },
      expression: "firstCoupon.expiration.startDate"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.firstCoupon.expiration.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.firstCoupon.expiration, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.firstCoupon.expiration, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.firstCoupon.expiration.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.firstCoupon.expiration, "endDate", $$v);
            },
            expression: "firstCoupon.expiration.endDate"
          }
        }, on))];
      }
    }], null, false, 1512298347)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationEndDate.save(_vm.firstCoupon.expiration.endDate);
      }
    },
    model: {
      value: _vm.firstCoupon.expiration.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.expiration, "endDate", $$v);
      },
      expression: "firstCoupon.expiration.endDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.firstCoupon.expiration.type == 'dates' ? _c('div', {
    staticClass: "caption primary--text mt-3"
  }, [_vm._v("특정 기간동안 쿠폰 유효")]) : _vm._e(), _vm.firstCoupon.expiration.type == 'days' ? _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발급일로부터")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.firstCoupon.expiration.days,
      callback: function ($$v) {
        _vm.$set(_vm.firstCoupon.expiration, "days", $$v);
      },
      expression: "firstCoupon.expiration.days"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("일 간 유효")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-tab-item', {
    key: "second"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _vm._v("재구매고객 대상")], 1), _c('v-card-text', [_c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("최근 6개월간 구매이력이 있는 고객을 대상으로 재구매 혜택을 설정할 수 있습니다.")]), _c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("재구매고객을 위해 쿠폰을 즉시 발급하거나, 구매 시 포인트 적립 혜택을 등록해 보세요.")])])], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰명칭")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "쿠폰 이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.name,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "name", $$v);
      },
      expression: "secondCoupon.name"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰종류")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.secondCoupon.type,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "type", $$v);
      },
      expression: "secondCoupon.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "상품할인",
      "value": "product"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "카테고리할인",
      "value": "category"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "주문금액할인",
      "value": "order"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "배송비할인",
      "value": "delivery"
    }
  })], 1), _vm.secondCoupon.type == 'category' ? _c('shop-category-select', {
    staticClass: "mt-4",
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.secondCoupon._category = _vm.secondCoupon.category._id;
      }
    },
    model: {
      value: _vm.secondCoupon.category,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "category", $$v);
      },
      expression: "secondCoupon.category"
    }
  }) : _vm._e(), _vm.secondCoupon.type == 'product' ? _c('v-autocomplete', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.productLoading,
      "search-input": _vm.searchValue,
      "label": "할인상품",
      "placeholder": " ",
      "items": _vm.products,
      "item-text": "name",
      "item-value": "_id",
      "hide-details": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref7) {
        var attr = _ref7.attr,
          on = _ref7.on,
          item = _ref7.item,
          selected = _ref7.selected;
        return [_c('v-card', {
          staticClass: "mr-4 px-2 my-2"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)], 1)];
      }
    }, {
      key: "item",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "py-1",
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "py-1 pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }], null, false, 142472018),
    model: {
      value: _vm.secondCoupon._product,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "_product", $$v);
      },
      expression: "secondCoupon._product"
    }
  }) : _vm._e(), _vm.secondCoupon.type == 'product' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("즉시할인과 중복으로 사용할 수 있는쿠폰입니다.")]) : _vm._e(), _vm.secondCoupon.type == 'order' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)")]) : _vm._e(), _vm.secondCoupon.type == 'delivery' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.")]) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("발급방법")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.secondCoupon.method,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "method", $$v);
      },
      expression: "secondCoupon.method"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "다운로드",
      "value": "download"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "고객에게 즉시발급",
      "value": "immediately"
    }
  })], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.")])], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("할인설정")]), _c('v-card-text', [_c('v-row', {
    staticClass: "m",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "할인금액",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.amount,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "amount", $$v);
      },
      expression: "secondCoupon.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.amountUnits,
      "label": "단위",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.unit,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "unit", $$v);
      },
      expression: "secondCoupon.unit"
    }
  })], 1)], 1)], 1), _vm.secondCoupon.unit == '%' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("최대")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "maxPrice", $$v);
      },
      expression: "secondCoupon.maxPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 할인")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("최소주문금액")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.minPrice,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon, "minPrice", $$v);
      },
      expression: "secondCoupon.minPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 이상 구매시 사용 가능")])], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다")]), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v("( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )")])], 1)], 1), _vm.secondCoupon.method == 'download' ? _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("혜택기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0 pr-0 caption",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("특정 기간만 혜택 제공")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.download.period,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.download, "period", $$v);
      },
      expression: "secondCoupon.download.period"
    }
  })], 1)], 1)], 1), _vm.secondCoupon.download.period ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "value": _vm.getTerm(_vm.secondCoupon.download.startDate, _vm.secondCoupon.download.endDate),
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.setTerm($event, _vm.secondCoupon.download);
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "1주일",
      "value": 0.25
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1개월",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "3개월",
      "value": 3
    }
  }), _c('v-radio', {
    attrs: {
      "label": "6개월",
      "value": 6
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1년",
      "value": 12
    }
  })], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', [_c('v-menu', {
    ref: "firstDownloadStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.secondCoupon.download.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.secondCoupon.download, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.secondCoupon.download, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var on = _ref9.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.secondCoupon.download.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.secondCoupon.download, "startDate", $$v);
            },
            expression: "secondCoupon.download.startDate"
          }
        }, on))];
      }
    }], null, false, 1405133780)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadStartDate.save(_vm.secondCoupon.download.startDate);
      }
    },
    model: {
      value: _vm.secondCoupon.download.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.download, "startDate", $$v);
      },
      expression: "secondCoupon.download.startDate"
    }
  })], 1)], 1), _c('v-col', [_c('v-menu', {
    ref: "firstDownloadEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.secondCoupon.download.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.secondCoupon.download, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.secondCoupon.download, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref10) {
        var on = _ref10.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.secondCoupon.download.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.secondCoupon.download, "endDate", $$v);
            },
            expression: "secondCoupon.download.endDate"
          }
        }, on))];
      }
    }], null, false, 861385631)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadEndDate.save(_vm.secondCoupon.download.endDate);
      }
    },
    model: {
      value: _vm.secondCoupon.download.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.download, "endDate", $$v);
      },
      expression: "secondCoupon.download.endDate"
    }
  })], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v(" 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("쿠폰유효기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.expiration.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.expiration, "enabled", $$v);
      },
      expression: "secondCoupon.expiration.enabled"
    }
  })], 1)], 1)], 1), _vm.secondCoupon.expiration.enabled ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.expiration.type,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.expiration, "type", $$v);
      },
      expression: "secondCoupon.expiration.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "기간으로설정",
      "value": "dates"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "발급일 기준으로 설정",
      "value": "days"
    }
  })], 1), _vm.secondCoupon.expiration.type == 'dates' ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.secondCoupon.expiration.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.secondCoupon.expiration, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.secondCoupon.expiration, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref11) {
        var on = _ref11.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.secondCoupon.expiration.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.secondCoupon.expiration, "startDate", $$v);
            },
            expression: "secondCoupon.expiration.startDate"
          }
        }, on))];
      }
    }], null, false, 61567274)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationStartDate.save(_vm.secondCoupon.expiration.startDate);
      }
    },
    model: {
      value: _vm.secondCoupon.expiration.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.expiration, "startDate", $$v);
      },
      expression: "secondCoupon.expiration.startDate"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.secondCoupon.expiration.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.secondCoupon.expiration, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.secondCoupon.expiration, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref12) {
        var on = _ref12.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.secondCoupon.expiration.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.secondCoupon.expiration, "endDate", $$v);
            },
            expression: "secondCoupon.expiration.endDate"
          }
        }, on))];
      }
    }], null, false, 1569147809)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationEndDate.save(_vm.secondCoupon.expiration.endDate);
      }
    },
    model: {
      value: _vm.secondCoupon.expiration.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.expiration, "endDate", $$v);
      },
      expression: "secondCoupon.expiration.endDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.secondCoupon.expiration.type == 'dates' ? _c('div', {
    staticClass: "caption primary--text mt-3"
  }, [_vm._v("특정 기간동안 쿠폰 유효")]) : _vm._e(), _vm.secondCoupon.expiration.type == 'days' ? _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발급일로부터")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.secondCoupon.expiration.days,
      callback: function ($$v) {
        _vm.$set(_vm.secondCoupon.expiration, "days", $$v);
      },
      expression: "secondCoupon.expiration.days"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("일 간 유효")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('v-tab-item', {
    key: "target"
  }, [_c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-alert-circle-outline")]), _vm._v("타겟팅 대상")], 1), _c('v-card-text', [_c('span', {
    staticClass: "caption d-block"
  }, [_vm._v("판매자님이 원하는 고객에게 쿠폰을 등록할 수 있습니다.")]), _c('v-row', [_c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "8"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-spacer'), _c('v-col', {
    staticClass: "caption pb-0 pb-sm-3",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm._v("회원이름")]), _c('v-col', {
    staticClass: "px-sm-0 py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userFilter.searchValue,
      callback: function ($$v) {
        _vm.$set(_vm.userFilter, "searchValue", $$v);
      },
      expression: "userFilter.searchValue"
    }
  })], 1), _c('v-col', {
    staticClass: "caption pb-0 pb-sm-3",
    attrs: {
      "cols": "12",
      "sm": "auto"
    }
  }, [_vm._v("반려동물 생년월일")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "px-sm-0 birth py-0"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "yyyy",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userFilter['pets.birthYear'],
      callback: function ($$v) {
        _vm.$set(_vm.userFilter, 'pets.birthYear', $$v);
      },
      expression: "userFilter['pets.birthYear']"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "px-sm-0 birth py-0"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "mm",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userFilter['pets.birthMonth'],
      callback: function ($$v) {
        _vm.$set(_vm.userFilter, 'pets.birthMonth', $$v);
      },
      expression: "userFilter['pets.birthMonth']"
    }
  })], 1), _c('v-col', {
    staticClass: "pa-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("-")]), _c('v-col', {
    staticClass: "px-sm-0 birth py-0"
  }, [_c('v-text-field', {
    attrs: {
      "placeholder": "dd",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.userFilter['pets.birthDay'],
      callback: function ($$v) {
        _vm.$set(_vm.userFilter, 'pets.birthDay', $$v);
      },
      expression: "userFilter['pets.birthDay']"
    }
  })], 1)], 1)], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "secondary",
      "small": ""
    },
    on: {
      "click": _vm.searchUser
    }
  }, [_vm._v("검색")])], 1)], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "items": _vm.users,
      "headers": _vm.usersHeaders,
      "show-select": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.pets",
      fn: function (_ref13) {
        var item = _ref13.item;
        return _vm._l(item.pets, function (pet) {
          return _c('div', [_vm._v(" " + _vm._s(pet.name) + " | " + _vm._s(pet.type) + " | " + _vm._s(pet.birthYear && pet.birthMonth && pet.birthDay ? [pet.birthYear, pet.birthMonth.toString().padStart(2, 0), pet.birthDay.toString().padStart(2, 0)].join('-') : '') + " ")]);
        });
      }
    }]),
    model: {
      value: _vm.usersSelected,
      callback: function ($$v) {
        _vm.usersSelected = $$v;
      },
      expression: "usersSelected"
    }
  }), _c('v-pagination', {
    staticClass: "mt-2",
    attrs: {
      "length": _vm.userPageCount,
      "total-visible": 10,
      "color": "secondary"
    },
    model: {
      value: _vm.userPage,
      callback: function ($$v) {
        _vm.userPage = $$v;
      },
      expression: "userPage"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰명칭")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "쿠폰 이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.name,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "name", $$v);
      },
      expression: "targetCoupon.name"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("쿠폰종류")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.targetCoupon.type,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "type", $$v);
      },
      expression: "targetCoupon.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "상품할인",
      "value": "product"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "카테고리할인",
      "value": "category"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "주문금액할인",
      "value": "order"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "배송비할인",
      "value": "delivery"
    }
  })], 1), _vm.targetCoupon.type == 'category' ? _c('shop-category-select', {
    staticClass: "mt-4",
    attrs: {
      "categories": _vm.categories
    },
    on: {
      "input": function ($event) {
        _vm.targetCoupon._category = _vm.targetCoupon.category._id;
      }
    },
    model: {
      value: _vm.targetCoupon.category,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "category", $$v);
      },
      expression: "targetCoupon.category"
    }
  }) : _vm._e(), _vm.targetCoupon.type == 'product' ? _c('v-autocomplete', {
    staticClass: "mt-4",
    attrs: {
      "loading": _vm.productLoading,
      "search-input": _vm.searchValue,
      "label": "할인상품",
      "placeholder": " ",
      "items": _vm.products,
      "item-text": "name",
      "item-value": "_id",
      "hide-details": ""
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref14) {
        var attr = _ref14.attr,
          on = _ref14.on,
          item = _ref14.item,
          selected = _ref14.selected;
        return [_c('v-card', {
          staticClass: "mr-4 px-2 my-2"
        }, [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)], 1)];
      }
    }, {
      key: "item",
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('v-row', {
          attrs: {
            "align": "center"
          }
        }, [_c('v-col', {
          staticClass: "py-1",
          attrs: {
            "cols": "auto"
          }
        }, [item.thumb ? _c('v-img', {
          attrs: {
            "src": item.thumb,
            "max-width": "56",
            "max-height": "56"
          }
        }) : _vm._e()], 1), _c('v-col', {
          staticClass: "py-1 pl-0"
        }, [_vm._v(_vm._s(item.name))])], 1)];
      }
    }], null, false, 142472018),
    model: {
      value: _vm.targetCoupon._product,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "_product", $$v);
      },
      expression: "targetCoupon._product"
    }
  }) : _vm._e(), _vm.targetCoupon.type == 'product' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("즉시할인과 중복으로 사용할 수 있는쿠폰입니다.")]) : _vm._e(), _vm.targetCoupon.type == 'order' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("쇼핑몰 기준으로 총 주문금액에 적용되는 할인입니다. 즉시할인 및 상품중복할인과 동시에 사용할 수 있습니다.(단독 사용도 가능)")]) : _vm._e(), _vm.targetCoupon.type == 'delivery' ? _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("배송비 유료 상품에 배송비 묶음그룹 기준으로 사용할 수 있는 쿠폰입니다.")]) : _vm._e()], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("발급방법")]), _c('v-card-text', [_c('v-radio-group', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.targetCoupon.method,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "method", $$v);
      },
      expression: "targetCoupon.method"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "다운로드",
      "value": "download"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "고객에게 즉시발급",
      "value": "immediately"
    }
  })], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("고객이 다운로드 버튼을 클릭하여 쿠폰을 발급받아 사용합니다.")])], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("할인설정")]), _c('v-card-text', [_c('v-row', {
    staticClass: "m",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "할인금액",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.amount,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "amount", $$v);
      },
      expression: "targetCoupon.amount"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.amountUnits,
      "label": "단위",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.unit,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "unit", $$v);
      },
      expression: "targetCoupon.unit"
    }
  })], 1)], 1)], 1), _vm.targetCoupon.unit == '%' ? _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("최대")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.maxPrice,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "maxPrice", $$v);
      },
      expression: "targetCoupon.maxPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 할인")])], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_vm._v("최소주문금액")]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.minPrice,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon, "minPrice", $$v);
      },
      expression: "targetCoupon.minPrice"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("원 이상 구매시 사용 가능")])], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block mt-2"
  }, [_vm._v("· 상품중복할인과 배송비 할인 쿠폰의 최소주문금액은 판매가 기준으로 사용됩니다")]), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v("( 예시 : 최소주문금액 1,000원 / 100원 상품중복할인 쿠폰의 경우, 판매가 1,000원 / 즉시할인가 500원인 상품 → 사용가능 / 400원에 결제 즉, 판매가 1,000원 이상이면 즉시할인가에 상관없이 사용가능 )")])], 1)], 1), _vm.targetCoupon.method == 'download' ? _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("혜택기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0 pr-0 caption",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("특정 기간만 혜택 제공")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.download.period,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.download, "period", $$v);
      },
      expression: "targetCoupon.download.period"
    }
  })], 1)], 1)], 1), _vm.targetCoupon.download.period ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "value": _vm.getTerm(_vm.targetCoupon.download.startDate, _vm.targetCoupon.download.endDate),
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": function ($event) {
        return _vm.setTerm($event, _vm.targetCoupon.download);
      }
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "1주일",
      "value": 0.25
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1개월",
      "value": 1
    }
  }), _c('v-radio', {
    attrs: {
      "label": "3개월",
      "value": 3
    }
  }), _c('v-radio', {
    attrs: {
      "label": "6개월",
      "value": 6
    }
  }), _c('v-radio', {
    attrs: {
      "label": "1년",
      "value": 12
    }
  })], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', [_c('v-menu', {
    ref: "firstDownloadStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.targetCoupon.download.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.targetCoupon.download, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.targetCoupon.download, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref16) {
        var on = _ref16.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.targetCoupon.download.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.targetCoupon.download, "startDate", $$v);
            },
            expression: "targetCoupon.download.startDate"
          }
        }, on))];
      }
    }], null, false, 4193588149)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadStartDate.save(_vm.targetCoupon.download.startDate);
      }
    },
    model: {
      value: _vm.targetCoupon.download.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.download, "startDate", $$v);
      },
      expression: "targetCoupon.download.startDate"
    }
  })], 1)], 1), _c('v-col', [_c('v-menu', {
    ref: "firstDownloadEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.targetCoupon.download.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.targetCoupon.download, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.targetCoupon.download, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref17) {
        var on = _ref17.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "혜택종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.targetCoupon.download.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.targetCoupon.download, "endDate", $$v);
            },
            expression: "targetCoupon.download.endDate"
          }
        }, on))];
      }
    }], null, false, 835099134)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstDownloadEndDate.save(_vm.targetCoupon.download.endDate);
      }
    },
    model: {
      value: _vm.targetCoupon.download.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.download, "endDate", $$v);
      },
      expression: "targetCoupon.download.endDate"
    }
  })], 1)], 1)], 1), _c('span', {
    staticClass: "caption primary--text d-block"
  }, [_vm._v(" 설정된 날짜부터 다운로드 가능한 쿠폰이 노출됩니다.")])], 1) : _vm._e()], 1) : _vm._e(), _c('v-card', {
    staticClass: "mt-4",
    attrs: {
      "disabled": !!_vm.$route.query._coupon
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("쿠폰유효기간")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.expiration.enabled,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.expiration, "enabled", $$v);
      },
      expression: "targetCoupon.expiration.enabled"
    }
  })], 1)], 1)], 1), _vm.targetCoupon.expiration.enabled ? _c('v-card-text', [_c('v-radio-group', {
    staticClass: "ma-0 pa-0",
    attrs: {
      "row": "",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.expiration.type,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.expiration, "type", $$v);
      },
      expression: "targetCoupon.expiration.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "기간으로설정",
      "value": "dates"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "발급일 기준으로 설정",
      "value": "days"
    }
  })], 1), _vm.targetCoupon.expiration.type == 'dates' ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationStartDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.targetCoupon.expiration.startDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.targetCoupon.expiration, "startDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.targetCoupon.expiration, "startDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref18) {
        var on = _ref18.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "시작일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.targetCoupon.expiration.startDate,
            callback: function ($$v) {
              _vm.$set(_vm.targetCoupon.expiration, "startDate", $$v);
            },
            expression: "targetCoupon.expiration.startDate"
          }
        }, on))];
      }
    }], null, false, 2994800267)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationStartDate.save(_vm.targetCoupon.expiration.startDate);
      }
    },
    model: {
      value: _vm.targetCoupon.expiration.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.expiration, "startDate", $$v);
      },
      expression: "targetCoupon.expiration.startDate"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-menu', {
    ref: "firstExpirationEndDate",
    attrs: {
      "close-on-content-click": false,
      "return-value": _vm.targetCoupon.expiration.endDate,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "290px"
    },
    on: {
      "update:returnValue": function ($event) {
        return _vm.$set(_vm.targetCoupon.expiration, "endDate", $event);
      },
      "update:return-value": function ($event) {
        return _vm.$set(_vm.targetCoupon.expiration, "endDate", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref19) {
        var on = _ref19.on;
        return [_c('v-text-field', _vm._g({
          attrs: {
            "label": "종료일자",
            "placeholder": " ",
            "dense": "",
            "hide-details": "",
            "append-icon": "event",
            "readonly": ""
          },
          model: {
            value: _vm.targetCoupon.expiration.endDate,
            callback: function ($$v) {
              _vm.$set(_vm.targetCoupon.expiration, "endDate", $$v);
            },
            expression: "targetCoupon.expiration.endDate"
          }
        }, on))];
      }
    }], null, false, 2705515008)
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function ($event) {
        return _vm.$refs.firstExpirationEndDate.save(_vm.targetCoupon.expiration.endDate);
      }
    },
    model: {
      value: _vm.targetCoupon.expiration.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.expiration, "endDate", $$v);
      },
      expression: "targetCoupon.expiration.endDate"
    }
  })], 1)], 1)], 1) : _vm._e(), _vm.targetCoupon.expiration.type == 'dates' ? _c('div', {
    staticClass: "caption primary--text mt-3"
  }, [_vm._v("특정 기간동안 쿠폰 유효")]) : _vm._e(), _vm.targetCoupon.expiration.type == 'days' ? _c('v-row', {
    staticClass: "mt-6",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("발급일로부터")]), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.targetCoupon.expiration.days,
      callback: function ($$v) {
        _vm.$set(_vm.targetCoupon.expiration, "days", $$v);
      },
      expression: "targetCoupon.expiration.days"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("일 간 유효")])], 1)], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _c('v-row', {
    staticClass: "mt-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !!_vm.$route.query._coupon
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }