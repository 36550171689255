<template>
    <v-container fluid fill-height>
        <board-list v-if="$route.query._board === undefined && $route.query._copy === undefined"></board-list>
        <board-view v-else></board-view>
    </v-container>
</template>

<script>
import BoardList from './board-list.vue';
import BoardView from './board-view.vue';

export default {
    components: {
        BoardList,
        BoardView
    },
    data: () => {
        return {};
    },
}
</script>
