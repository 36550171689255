import download from "downloadjs";
import axios from "axios";

export default (url = "", rename = "") => {
    return axios({
        url,
        method: "GET",
        responseType: "blob",
    }).then(result => {
        try{
            /* [chrome, modern browser] */
            let file = new File([result.data], url?.split("/")?.pop(), { type: result.data.type });
            download(file, rename || undefined);
        }
        catch (error) {
            /* [ie, legacy browser] */
            let blob = new Blob([result.data]);
            blob.name = url?.split("/")?.pop();
            download(blob, rename || undefined);
        }
    });
}