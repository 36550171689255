<template>
    <v-layout justify-center>
        <v-responsive max-width="1980px" width="100%" class="px-0 px-md-4 px-xl-8">
            <v-row class="mt-0 mt-md-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">발주/발송 관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="mt-4" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate); filter.createDate[0] = filter.startDate ? new Date(`${filter.startDate} 00:00:00.000`).getTime() : '';"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate); filter.createDate[1] = filter.endDate ? new Date(`${filter.endDate} 23:59:59.999`).getTime() : ''"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" dense hide-details label="상세조건" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.searchValue" dense hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter['delivery.method']" :items="deliveryMethods" item-text="text" item-value="value" dense hide-details label="배송방법" placeholder=" " clearable></v-select>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2" @click="excel" ><v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드</v-btn>
                </v-col>
            </v-row>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-truck-check-outline</v-icon>배송준비지연</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=payment&orderStatus=shipping-delay&claimStatus=null&claimStatus=cancel-reject" class="body-2">{{ dashboard?.shippingDelay?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-truck-check-outline</v-icon>오늘출발</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=payment&orderStatus=shipping-confirm&claimStatus=null&claimStatus=cancel-reject">{{ dashboard?.shippingToday?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-package-variant</v-icon>신규주문</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=payment&orderStatus=order-payment&claimStatus=null&claimStatus=cancel-reject">{{ dashboard?.orderPayment?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>발주확인 완료</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=payment&orderStatus=order-confirm&claimStatus=null&claimStatus=cancel-reject">{{ dashboard?.orderConfirm?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" :headers="purchasesHeaders" :items="purchases" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.order.sender="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.warning : false}">{{ item.order?.sender?.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false}">{{ item.order?.sender?.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false}">{{ item.order?.sender?.email }}</div>
                </template>
                <template v-slot:item.order.receiver="{ item }">
                    <div>{{ item.order?.receiver?.name }}</div>
                    <div>{{ item.order?.receiver?.phone }}</div>
                    <div>{{ item.order?.receiver?.email }}</div>
                </template>
                <template v-slot:item.order.delivery.address="{ item }">
                    [{{ item.order?.receiver?.postcode }}] {{ item.order?.receiver?.address1 }} {{ item.order?.receiver?.address2 }}
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-card class="mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">주문확인</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="confirmOrder">발주확인</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="delayShipping">발송지연</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="receiverDialog = true">배송지 정보수정</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">발송처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="shippingDialog = true">발송처리</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="deliveryDialog = true">송장수정</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">취소처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="cancelComplete">판매취소</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="cancelShipping">집하취소</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-dialog v-model="shippingDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">발송처리</v-card-title>
                    <v-card-text>
                        <v-select v-model="delivery._id" :items="deliveries" item-text="name" item-value="_id" label="택배업체" placeholder=" "></v-select>
                        <v-select v-model="delivery.method" :items="deliveryMethods" label="배송방법" placeholder=" "></v-select>
                        <v-text-field v-model="delivery.number" label="운송장번호" placeholder=" "></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="shippingDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="confirmShipping">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deliveryDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">송장수정</v-card-title>
                    <v-card-text>
                        <v-select v-model="delivery._id" :items="deliveries" item-text="name" item-value="_id" label="택배업체" placeholder=" "></v-select>
                        <v-select v-model="delivery.method" :items="deliveryMethods" label="배송방법" placeholder=" "></v-select>
                        <v-text-field v-model="delivery.number" label="운송장번호" placeholder=" "></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="deliveryDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="updateDelivery">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="receiverDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">배송지 정보수정</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="receiver.name" label="수취인명" placeholder=" "></v-text-field>
                        <v-text-field v-model="receiver.phone" label="연락처" placeholder=" "></v-text-field>
                        <v-text-field v-model="receiver.email" label="이메일" placeholder=" "></v-text-field>
                        <v-text-field v-model="receiver.postcode" label="우편번호" placeholder=" "></v-text-field>
                        <v-text-field v-model="receiver.address1" label="기본주소" placeholder=" "></v-text-field>
                        <v-text-field v-model="receiver.address2" label="상세주소" placeholder=" "></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="receiverDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="updateReceiver">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-responsive>
    </v-layout>
</template>

<script>
import { mdiMicrosoftExcel } from "@mdi/js";

import XLSX from 'xlsx';

export default {
    data() {
        return {
            mdiMicrosoftExcel,
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: this.$route.query.startDate,
                endDate: this.$route.query.endDate,
                username: this.$route.query.username,
                phone: this.$route.query.phone,
                searchKey: this.$route.query.searchKey,
                searchValue: this.$route.query.searchValue,
                orderStatus: this.$route.query.orderStatus || ["order-payment", "order-confirm", "shipping-delay", "shipping-confirm"],
                claimStatus: this.$route.query.claimStatus || [ null, "cancel-reject" ],
                createDate: this.$route.query.createDate || [ null, null ],
                shippingDate: this.$route.query.shippingDate,
                "delivery.method": this.$route.query['delivery.method']
            },
            searchKeys: [
                { text: "전체", value: null },
                { text: "수취인명", value: "receiver.name" },
                { text: "구매자명", value: "sender.name" },
                { text: "구매자연락처", value: "sender.phone" },
                { text: "구매자ID", value: "sender.username" },
                { text: "주문번호", value: "orderNo" },
                { text: "상품주문번호", value: "purchaseNo" },
            ],

            ///////////////////////////////////////////////
            // 대시보드
            ///////////////////////////////////////////////
            dashboard: {
                shippingDelay: 0,
                shippingToday: 0,
                orderPayment: 0,
                orderConfirm: 0
            },

            ///////////////////////////////////////////////
            // 페이징
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            ///////////////////////////////////////////////
            // 구매목록
            ///////////////////////////////////////////////
            selected: [],
            purchases: [],
            purchasesHeaders: [{
                text: '상품주문번호',
                align: 'center',
                width: 100,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 100,
                value: 'order.orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 140,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 140,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 140,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'order.sender.phone'
            },
            {
                text: '벽지코드',
                align: 'center',
                width: 200,
                value: 'product.code'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 200,
                value: 'product.name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'order.sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'order.receiver'
            },
            {
                text: '배송지주소',
                align: 'center',
                width: 400,
                value: 'order.delivery.address'
            }],

            shippingDialog: false,
            deliveryDialog: false,
            deliveries: [],
            deliveryMethods: ["택배,등기,소포", "퀵서비스", "방문수령", "직접전달"],
            delivery: {
                _id: null,
                method: null,
                number: null
            },

            receiverDialog: false,
            receiver: {
                name: null,
                phone: null,
                email: null,
                postcode: null,
                address1: null,
                address2: null
            },

        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
               var { summary, purchases } = await this.getPurchases({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.purchases = purchases;

                await this.getDeliveries();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async search(routable = false){
            try{
                var { summary, purchases } = await this.getPurchases({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.purchases = purchases;
                
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async excel(){
            try{
                var { purchases } = await this.getPurchases({
                    params: this.filter
                });

                var rows = [];
                for(var purchase of purchases){
                    rows.push({
                        "상품주문번호": purchase.purchaseNo,
                        "주문번호": purchase.order.orderNo,
                        // "구매자명": purchase.order.sender.name,
                        // "구매자ID": purchase.user ? purchase.user.username : "비회원",
                        "수취인명": purchase.order.receiver.name,
                        "주문상태": purchase.orderStatusMessage,
                        "클레임상태": purchase.claimStatusMessage,
                        "주문일": purchase.createDate ? purchase.createDate.toDateTime() : "-",
                        "배송방법": purchase.delivery.method,
                        "택배사": purchase.delivery.name,
                        "송장번호": purchase.delivery.number,
                        "발송일": purchase.shippingDate ? purchase.shippingDate.toDateTime() : "-",
                        "벽지코드": purchase.product.code,
                        "벽지명": purchase.product.name,
                        "수량": purchase.amount,
                        "발주확인일": purchase.orderConfirmDate ? purchase.orderConfirmDate.toDateTime() : "-",
                        "수취인연락처": purchase.order.receiver.phone,
                        "우편번호": purchase.order.receiver.postcode,
                        "배송지": `${purchase.order.receiver.address1} ${purchase.order.receiver.address2}`,
                        "구매자연락처": purchase.order.sender.phone,
                        "배송메세지": purchase.delivery.message,
                    })
                }

                var workbook = new XLSX.utils.book_new();
                var worksheet = XLSX.utils.json_to_sheet(rows);

                XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                XLSX.writeFile(workbook, "발주/발송관리.xlsx");
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 발주확인
        //////////////////////////////////////////////////////////////////////
        async confirmOrder(){
            try{
                if(!this.selected.length) throw new Error("발주확인처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "order-confirm"
                    });
                }
                alert("발주확인 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 배송지연
        //////////////////////////////////////////////////////////////////////
        async delayShipping(){
            try{
                if(!this.selected.length) throw new Error("배송지연을 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "shipping-delay"
                    });
                }

                alert("배송지연처리 되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 발송처리
        //////////////////////////////////////////////////////////////////////
        async confirmShipping(){
            try{
                if(!this.selected.length) throw new Error("발송처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "shipping-confirm",
                        delivery: this.delivery
                    });
                }

                alert("발송처리 되었습니다");
                this.search();
                this.shippingDialog = false;
                this.delivery = {
                    _id: null,
                    number: null,
                    method: null
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 배송지 정보수정
        //////////////////////////////////////////////////////////////////////
        async updateReceiver(){
            try{
                if(!this.selected.length) throw new Error("배송지를 변경할 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putOrder({
                        _id: this.selected[i]._order,
                        receiver: this.receiver
                    });
                }

                alert("배송지 정보가 수정되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 송장수정
        //////////////////////////////////////////////////////////////////////
        async updateDelivery(){
            try{
                if(!this.selected.length) throw new Error("송장정보를 변경할 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        delivery: this.delivery
                    });
                }

                alert("송장이 수정되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 판매취소
        //////////////////////////////////////////////////////////////////////
        async cancelComplete(){
            try{
                if(!this.selected.length) throw new Error("판매취소할 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "cancel-complete"
                    });
                }

                alert("판매취소 처리되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        //////////////////////////////////////////////////////////////////////
        // 집하취소
        //////////////////////////////////////////////////////////////////////
        async cancelShipping(){
            try{
                if(!this.selected.length) throw new Error("집하취소 하기위한 구매건을 선택해주세요");
                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "shipping-cancel",
                        delivery: {
                            _id: null,
                            number: null,
                            method: null
                        }
                    });
                }

                alert("집하취소 처리되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        getPurchases(data){
            return this.$http.get('/api/console/shop/purchases', data).then(result => result.data);
        },

        putPurchase(purchase){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/purchases/${purchase._id}`, purchase);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putOrder(order){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/orders/${order._id}`, order);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDeliveries(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/deliveries');

                    this.deliveries = res.data.deliveries;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDashboard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/shop/purchases/dashboard/${this.$route.query.manage}`);

                    this.dashboard = res.data.dashboard;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        query(){
            return this.$route.query;
        }
    },
    watch: {
        query(){
            if(this.query.orderStatus == "shipping-confirm"){
                this.filter.shippingDate = [new Date(`${Date.now().toDate()} 00:00:00`).getTime(), new Date(`${Date.now().toDate()} 23:59:59`).getTime()];
            }
            else{
                this.filter.shippingDate = undefined;
            }
            this.search();
        }
    }
}
</script>
