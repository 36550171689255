<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">칼라 아이콘 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="iconsEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="iconsEnabled && icons.length">
            <v-row>
                <v-col v-for="(icon, index) in icons" v-show="!icon.removed" cols="auto">
                    <v-responsive width="48" class="text-center">
                        <v-img width="48" height="48" :src="icon.file ? createObjectURL(icon.file) : icon.filename"></v-img>
                        <span>{{ icon.name }}</span>
                        <v-btn icon @click="remove(icon, index)"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-responsive>
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider v-if="iconsEnabled"/>
        <v-card-actions v-if="iconsEnabled">
            <v-row align="center">
                <v-col cols="auto" class="py-0 pr-0">
                    <v-img width="48" height="48" :src="file ? createObjectURL(file) : ''"></v-img>
                </v-col>
                <v-col class="py-0">
                    <v-file-input v-model="file" label="파일찾기" placeholder=" " dense hide-details show-size accept="image/*"></v-file-input>
                </v-col>
                <v-col class="py-0">
                    <v-text-field v-model="name" label="칼라이름" placeholder=" " dense hide-details></v-text-field>
                </v-col>
                <v-col cols="auto" class="py-0 pl-0">
                    <v-btn color="primary" @click="insert"><v-icon class="mr-2">mdi-plus</v-icon>추가</v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            iconsEnabled: false,
            icons: [],

            file: null,
            name: null
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                var { iconsEnabled, icons } = this.value;

                Object.assignDefined(this.$data, {
                    iconsEnabled,
                    icons
                });
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        insert(){
            this.icons.push({
                file: this.file,
                name: this.name
            });

            this.file = null;
            this.name = null;
            this.input();
        },
        async remove(icon, index){
            try{
                this.value._id ? this.$set(this.icons[index], "removed", true) : this.icons.splice(index, 1);
                this.input();
                this.$forceUpdate();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },
        input(){
            this.$set(this.value, "iconsEnabled", this.iconsEnabled);
            this.$set(this.value, "icons", this.icons);

            this.$emit('input', this.value);
        },
        createObjectURL(file){
            return URL.createObjectURL(file);
        }
    }
}
</script>
