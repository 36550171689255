var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "clipped-left": _vm.$vuetify.breakpoint.lgAndUp,
      "app": "",
      "color": _vm.color,
      "dark": "",
      "dense": ""
    }
  }, [_c('v-app-bar-nav-icon', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.$emit('input', !_vm.value);
      }
    }
  }), _c('v-toolbar-title', {
    staticClass: "ml-0 pl-4 text-start subtitle-1"
  }, [_c('span', [_vm._v(_vm._s(_vm.title))])]), false ? _c('v-text-field', {
    staticClass: "hidden-sm-and-down",
    attrs: {
      "flat": "",
      "solo-inverted": "",
      "hide-details": "",
      "prepend-inner-icon": "mdi-magnify",
      "label": "Search"
    }
  }) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    staticClass: "px-2",
    attrs: {
      "text": "",
      "href": "/",
      "min-width": "auto"
    }
  }, [_c('v-icon', {
    attrs: {
      "medium": ""
    }
  }, [_vm._v("mdi-home")]), _c('span', {
    staticClass: "ml-2 d-none d-sm-inline-block"
  }, [_vm._v("내홈페이지")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }