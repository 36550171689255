<template>
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">

            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">상품목록</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="pt-4 pb-0">
                        <v-row>
                            <v-col cols="12" class="py-0">
                                <shop-category-select v-model="filter._category" dense hide-details :categories="categories"></shop-category-select>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.name" dense hide-details type="text" label="상품명" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.code" dense hide-details type="text" label="코드명" placeholder=" " />
                            </v-col>
                        </v-row>
                        <v-row v-show="false" class="mt-6">
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.newly" :items="newlyItems" item-text="text" item-value="value" dense hide-details type="text" label="신상여부" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.best" :items="bestItems" item-text="text" item-value="value" dense hide-details type="text" label="베스트" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.special" :items="specialItems" item-text="text" item-value="value" dense hide-details type="text" label="특가여부" placeholder=" " />
                            </v-col>
                        </v-row>
                        <v-row v-show="false" class="mt-6">
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.display" :items="displayItems" item-text="text" item-value="value" dense hide-details type="text" label="진열여부" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.soldout" :items="soldoutItems" item-text="text" item-value="value" dense hide-details type="text" label="품절상태여부" placeholder=" " />
                            </v-col>
                            <v-col cols="12" sm="3" class="py-sm-0">
                                <v-select v-model="filter.sale" :items="saleItems" item-text="text" item-value="value" dense hide-details type="text" label="판매여부" placeholder=" " />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="page=1; search()"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-layout class="mt-4 mx-n1">
                <v-spacer/>
                <v-flex shrink class="px-1">
                    <v-btn color="white" class="black--text" @click="download(0)"><v-icon color="primary" size="20" class="mr-2">{{ mdiDownload }}</v-icon>기본이미지 다운로드</v-btn>
                </v-flex>
                <v-flex shrink class="px-1">
                    <v-btn color="white" class="black--text" @click="download(1)"><v-icon color="primary" size="20" class="mr-2">{{ mdiDownload }}</v-icon>한폭보기 다운로드</v-btn>
                </v-flex>
                <v-flex shrink class="px-1">
                    <v-btn color="white" class="black--text" @click="download(2)"><v-icon color="primary" size="20" class="mr-2">{{ mdiDownload }}</v-icon>간지이미지 다운로드</v-btn>
                </v-flex>
            </v-layout>

            <v-data-table v-model="selected" :headers="productsHeaders" :items="products" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-2">
                <template v-slot:item.goods="{item}">
                    <v-row align="center">
                        <v-col md="auto">
                            <v-img v-if="item.thumb" :src="item.thumb" max-width="56" max-height="56"></v-img>
                            <v-responsive v-else width="56" height="56">
                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                    <v-icon>mdi-image</v-icon>
                                </v-row>
                            </v-responsive>
                        </v-col>
                        <v-col>
                            <div class="text-start">[{{ item.code }}] {{ item.name }}</div>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.price="{item}">
                    <span>{{ (item.price || 0).format() }}원</span>
                </template>
                <template v-slot:item.cost="{item}">
                    <span>{{ (item.cost || 0).format() }}원</span>
                </template>
                <template v-slot:item.stock="{item}">
                    <span>{{ (item.stock || 0 ).format() }}개</span>
                </template>
                <template v-slot:item.display="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.display" hide-details class="ma-0" @change="putProduct({ _id, display } = item)"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.sale="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.sale" hide-details class="ma-0" @change="putProduct({ _id, sale } = item)"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.best="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.best" hide-details class="ma-0" @change="putProduct({ _id: item._id, best: item.best })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.newly="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.newly" hide-details class="ma-0" @change="putProduct({ _id, newly } = item)"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.special="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.special" hide-details class="ma-0" @change="putProduct({ _id, special } = item)"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.color="{item}">
                    <v-row justify="center">
                        <v-col cols="6" class="pa-3">
                            <v-select hide-details="auto" v-model="item.color" height="40" solo :items="['화이트/베이지','옐로우','블루/그린','바이올렛/퍼플','레드/와인','핑크','브라운','블랙/그레이','메탈','기타']" @change="putProduct({ _id, color } = item)"/>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.soldout="{item}">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.soldout" hide-details class="ma-0" @change="putProduct({ _id, soldout } = item)"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.zoomBasic="{item}">
                    {{ item.zoomBasic }}%
                </template>
                <template v-slot:item.zoomHeight="{item}">
                    {{ item.zoomHeight }}%
                </template>
                <template v-slot:item.actions="{item}">
                    <v-icon small @click="edit(item)" title="수정">mdi-pencil</v-icon>
                    <v-icon small @click="copy(item)" title="복사" class="ml-2">mdi-content-copy</v-icon>
                    <v-icon small @click="remove(item)" title="삭제" class="ml-2">mdi-delete</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            
            <v-dialog v-model="zoomDialog" max-width="400">
                <v-card>
                    <v-card-title class="subtitle-1">확대비율 일괄 변경</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="zoom.zoomBasic" label="기본보기 확대비율(100% 기준)" type="number" placeholder=" " dense hide-details class="mt-6"></v-text-field>
                        <v-text-field v-model="zoom.zoomHeight" label="한폭보기 확대비율(100% 기준)" type="number" placeholder=" " dense hide-details class="mt-8"></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="grey" text @click="zoomDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="saveAll">수정</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <div class="v-fab-layout justify-end pb-4 pr-4">
                <v-btn v-if="user === 'uxidev'" color="green" dark fab class="mt-2" @click="upload">
                    <v-icon>{{ mdiMicrosoftExcel }}</v-icon>
                </v-btn>

                <v-btn color="secondary" fab class="mt-2" @click="zoomDialog = true">
                    <v-icon>{{ mdiMagnifyScan }}</v-icon>
                </v-btn>
                
                <v-btn color="blue" dark fab class="mt-2" @click="create">
                    <v-icon>mdi-pencil</v-icon>
                </v-btn>
            </div>

        </v-responsive>
    </v-layout>
</template>

<script>
import { mdiMagnifyScan, mdiDownload, mdiMicrosoftExcel } from "@mdi/js";
import ShopCategorySelect from '../category/shop-category-select.vue';
import download from "downloadjs";

export default {
    components: {
        ShopCategorySelect
    },
    mounted(){
        this.init();
        console.log(this.$store.state.payload);
    },
    data(){
        return {
            user: null,
            excel: null,

            mdiDownload,
            mdiMagnifyScan,
            mdiMicrosoftExcel,

            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            zoomDialog: false,

            category: null,
            filter: {
                _category: this.$route.query._category,
                name: this.$route.query.name,
                code: this.$route.query.code,
                newly: JSON.parse(this.$route.query.newly || "null"),
                best: JSON.parse(this.$route.query.newly || "null"),
                display: JSON.parse(this.$route.query.newly || "null"),
                special: JSON.parse(this.$route.query.newly || "null"),
                soldout: JSON.parse(this.$route.query.newly || "null"),
                sale: JSON.parse(this.$route.query.newly || "null"),
            },

            categories: [],

            page: +this.$route.query.page || 1,
            pageCount: 0,
            limit: 50,

            selected: [],
            products: [],
            productsHeaders: [{
                text: '진열순서',
                align: 'center',
                width: 100,
                value: 'sequence'
            },
            {
                text: '상품',
                align: 'center',
                value: 'goods'
            },
            {
                text: '색상',
                align: 'center',
                value: 'color'
            },
            {
                text: '기본보기 비율',
                width: 100,
                align: 'center',
                value: 'zoomBasic'
            },
            {
                text: '한폭보기 비율',
                width: 100,
                align: 'center',
                value: 'zoomHeight'
            },
            // {
            //     text: '판매가',
            //     align: 'center',
            //     width: 140,
            //     value: 'price'
            // },
            // {
            //     text: '재고',
            //     width: 100,
            //     align: 'center',
            //     value: 'stock'
            // },
            // {
            //     text: '신상품',
            //     align: 'center',
            //     width: 80,
            //     value: 'newly'
            // },
            // {
            //     text: '베스트',
            //     align: 'center',
            //     width: 80,
            //     value: 'best'
            // },
            // {
            //     text: '특가',
            //     align: 'center',
            //     width: 80,
            //     value: 'special'
            // },
            // {
            //     text: '품절여부',
            //     align: 'center',
            //     width: 80,
            //     value: 'soldout'
            // },
            {
                text: 'Actions',
                width: 100,
                align: 'center',
                value: 'actions'
            }],

            zoom: {
                zoomBasic: 150,
                zoomHeight: 100
            },

            newlyItems: [
                { text: "전체", value: null },
                { text: "신상품", value: true },
                { text: "일반", value: false }
            ],

            bestItems: [
                { text: "전체", value: null },
                { text: "적용중", value: true },
                { text: "미적용 ", value: false }
            ],

            specialItems: [
                { text: "전체", value: null },
                { text: "특가중", value: true },
                { text: "비특가 ", value: false }
            ],

            displayItems: [
                { text: "전체", value: null },
                { text: "전시중", value: true },
                { text: "미전시 ", value: false }
            ],

            soldoutItems: [
                { text: "전체", value: null },
                { text: "품절표시", value: true },
                { text: "미품절 ", value: false }
            ],

            saleItems: [
                { text: "전체", value: null },
                { text: "판매중", value: true },
                { text: "판매불가 ", value: false }
            ]
        };
    },
    methods: {
        async init(){
            try{
                console.log(this.$store.state.payload);
                let { data } = await this.$http.get(`/api/console/users/${this.$store.state.payload._user}`);
                this.user = data.user.username;
                console.log(data.user.username, "res");
                var { categories } = await this.getCategories();
                this.categories = categories;

                await this.search(false);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async search(routable = true){
            try{
                if(routable){
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page })
                    });
                }

                var { summary, products } = await this.getProducts({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    },
                    params: this.filter
                });

                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.products = products;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        create(){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _product: null
                }
            });
        },
        upload(){
            this.$router.push("/console/shop/products/upload/excel");
        },
        copy(product){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _product: product._id,
                    method: "copy"
                }
            });
        },
        edit(product){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _product: product._id
                }
            });
        },
        async saveAll(){
            try{
                if(!this.selected.length) throw new Error("벽지를 선택해주세요");

                for(var product of this.selected){
                    await this.putProduct({ _id: product._id, zoomBasic: this.zoom.zoomBasic, zoomHeight: this.zoom.zoomHeight });
                }
                this.search(false);

                alert("수정되었습니다");
                this.zoomDialog = false;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },
        async remove(product){
            try{
                if(confirm("상품을 삭제하시겠습니까?")){
                    await this.deleteProduct(product);
                    await this.search(false);

                    alert("삭제되었습니다");
                }
            }
            catch(error){
                console.error(error);
            }
        },

        getResource(filename){
            return this.$http({
                url: filename,
                method: 'GET',
                responseType: 'blob'
            })
            .then(result => {
                try{
                    return new File([result.data], filename.split('/').pop());
                }
                catch(error){
                    var blob = new Blob([result.data]);
                    blob.name = filename.split('/').pop();
                    return blob;
                }
            });
        },

        async download(imageIndex){
            let imageName;
            switch(imageIndex){
                case 0: { imageName = "기본이미지"; break;}
                case 1: { imageName = "한폭이미지"; break;}
                case 2: { imageName = "간지이미지"; break;}
            }

            for(var product of this.selected){
                let image = product.images[imageIndex];
                if(image){
                    download(await this.getResource(image), `${product.name}_${imageName}` + image.substr(image.indexOf(".")));
                }
            }
        },

        getProducts(data){
            return this.$http.get("/api/console/shop/products", data).then(res => res.data);
        },

        putProduct(product){
            return this.$http.put(`/api/console/shop/products/${product._id}`, product).then(res => res.data);
        },

        deleteProduct(product){
            return this.$http.delete(`/api/console/shop/products/${product._id}`).then(res => res.data);
        },

        /////////////////////////////////////////
        // 카테고리 조회
        /////////////////////////////////////////
        getCategories() {
            return this.$http.get('/api/console/shop/categories').then(res => res.data);
        },
    },
    watch: {
        page(){
            this.search();
        }
    }
}
</script>

<style scoped>
.v-fab-layout {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: grid;
    pointer-events: none;
    z-index: 99999;
}
.v-fab-layout * {
        pointer-events: auto;
    }
</style>