<template>
    <v-layout justify-center>
        <v-responsive max-width="1980px" width="100%" class="px-0 px-md-4 px-xl-8">
            <v-row class="mt-0 mt-md-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">교환관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="mt-4" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate); filter.createDate[0] = filter.startDate ? new Date(`${filter.startDate} 00:00:00.000`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate); filter.createDate[1] = filter.endDate ? new Date(`${filter.endDate} 23:59:59.999`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" dense hide-details label="상세조건" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.searchValue" dense hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-package-variant</v-icon>교환지연</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-delay">{{ dashboard.exchangeDelay.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>교환보류</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-pending">{{ dashboard.exchangePending.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-truck-check-outline</v-icon>교환요청</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-request">{{ dashboard.exchangeRequest.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-package-variant</v-icon>교환수거중</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-request&claimStatus=exchange-pending&claimStatus=exchange-delay&claimStatus=exchange-complete&collectStatus=collect-progress">{{ dashboard.collectProgress.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>교환 수거완료</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-request&claimStatus=exchange-pending&claimStatus=exchange-delay&claimStatus=exchange-complete&collectStatus=collect-complete">{{ dashboard.collectComplete.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>교환완료</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=exchange&claimStatus=exchange-complete">{{ dashboard.exchangeComplete.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" :headers="purchasesHeaders" :items="purchases" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.order.sender="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template v-slot:item.order.receiver="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-card class="mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">교환처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="collectComplete">수거 완료처리</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="shippingConfirm">교환 재배송처리</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="exchangeReject">교환 거부처리</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="returnRequest">반품으로 변경</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">교환보류</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="exchangePending">교환보류 설정</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="exchangeRequest">교환보류 해제</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">정보 수정</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="reasonDialog = true">교환사유 수정</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="collectDialog = true">수거정보 수정</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="deliveryDialog = true">재배송정보 수정</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-dialog v-model="shippingDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">발송처리</v-card-title>
                    <v-card-text>
                        <v-select v-model="delivery._id" :items="deliveries" item-text="name" item-value="_id" label="택배업체" placeholder=" "></v-select>
                        <v-select v-model="delivery.method" :items="deliveryMethods" label="배송방법" placeholder=" "></v-select>
                        <v-text-field v-model="delivery.number" label="운송장번호" placeholder=" "></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="shippingDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="shippingConfirm">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="deliveryDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">송장수정</v-card-title>
                    <v-card-text>
                        <v-select v-model="delivery._id" :items="deliveries" item-text="name" item-value="_id" label="택배업체" placeholder=" "></v-select>
                        <v-select v-model="delivery.method" :items="deliveryMethods" label="배송방법" placeholder=" "></v-select>
                        <v-text-field v-model="delivery.number" label="운송장번호" placeholder=" "></v-text-field>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="deliveryDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="updateDelivery">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="reasonDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">교환사유수정</v-card-title>
                    <v-card-text>
                        <v-textarea v-model="exchangeReason" label="교환사유" placeholder=" "></v-textarea>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="reasonDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="updateReceiver">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="collectDialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right>
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="subtitle-1">수거정보 수정</v-card-title>
                    <v-card-text>
                        <v-select v-model="collectStatus" :items="collectStatusItems" item-text="text" item-value="value" label="수거상태" placeholder=" "></v-select>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="collectDialog = false">취소</v-btn>
                        <v-btn color="primary" text @click="updateCollect">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-responsive>
    </v-layout>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null,
                claimStatuses: ["exchange-request", "exchange-pending", "exchange-complete", "exchange-reject"],
                createDate: [null, null]
            },
            searchKeys: [
                { text: "전체", value: null },
                { text: "수취인명", value: "receiver.name" },
                { text: "구매자명", value: "sender.name" },
                { text: "구매자연락처", value: "sender.phone" },
                { text: "구매자ID", value: "sender.username" },
                { text: "주문번호", value: "orderNo" },
                { text: "상품주문번호", value: "purchaseNo" },
            ],

            dashboard: {
                exchangeDelay: 0,
                exchangePending: 0,
                exchangeRequest: 0,
                collectProgress: 0,
                collectComplete: 0,
                exchangeComplete: 0,
            },

            ///////////////////////////////////////////////
            // 페이징
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            ///////////////////////////////////////////////
            // 구매목록
            ///////////////////////////////////////////////
            selected: [],
            purchases: [],
            purchasesHeaders: [{
                text: '상품주문번호',
                align: 'center',
                width: 100,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 100,
                value: 'order.orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 140,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 140,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 140,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'order.sender.phone'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 200,
                value: 'product.name'
            },
            {
                text: '옵션정보',
                align: 'center',
                width: 200,
                value: 'name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'order.sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'order.receiver'
            }],

            shippingDialog: false,
            deliveryDialog: false,
            deliveries: [],
            deliveryMethods: ["택배,등기,소포", "퀵서비스", "방문수령", "직접전달"],
            delivery: {
                _id: null,
                number: null
            },

            reasonDialog: false,
            exchangeReason: null,

            collectDialog: false,
            collectStatusItems: [
                { text: "수거중", value: "collect-progress" },
                { text: "수거보류", value: "collect-pending" },
                { text: "수거완료", value: "collect-complete" },
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getPurchases();
                await this.getDeliveries();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async search(params){
            try{
                Object.assign(this.filter, params);

                await this.getPurchases();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        // 수거완료처리
        async collectComplete(){
            try{
                if(!this.selected.length) throw new Error("수거완료를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        collectStatus: "collect-complete"
                    });
                }
                alert("수거완료처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 교환 재배송처리
        async shippingConfirm(){
            try{
                if(!this.selected.length) throw new Error("재배송처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "shipping-confirm",
                        claimStatus: "exchange-shipping",
                        delivery: this.delivery
                    });
                }
                alert("재배송처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 교환 거부처리
        async exchangeReject(){
            try{
                if(!this.selected.length) throw new Error("교환 거부처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        claimStatus: "exchange-reject"
                    });
                }
                alert("교환 거부처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 반품으로 변경
        async returnRequest(){
            try{
                if(!this.selected.length) throw new Error("반품변경을 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        claimStatus: "return-request",
                        collectStatus: null
                    });
                }

                alert("반품으로 변경되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 교환보류 설정
        async exchangePending(){
            try{
                if(!this.selected.length) throw new Error("교환보류처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        claimStatus: "exchange-pending"
                    });
                }
                alert("교환보류처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 교환보류 해제
        async exchangeRequest(){
            try{
                if(!this.selected.length) throw new Error("교환보류 해제를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        claimStatus: "exchange-request",
                    });
                }
                alert("교환보류가 해제 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 교환사유 수정
        async updateReason(){
            try{
                if(!this.selected.length) throw new Error("교환사유를 수정 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        exchangeReason: this.exchangeReason
                    });
                }
                alert("교환사유가 변경되었습니다");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 수거정보 수정
        async updateCollect(){
            try{
                if(!this.selected.length) throw new Error("수거정보를 수정하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        collectStatus: this.collectStatus
                    });
                }
                alert("수거정보가 변경 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        // 재배송정보 수정
        async updateDelivery(){
            try{
                if(!this.selected.length) throw new Error("배송정보수정을 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        delivery: this.delivery
                    });
                }
                alert("배송정보가 변경되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        getPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/purchases', {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: Object.assign({}, this.filter, this.query)
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.purchases = res.data.purchases;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putPurchase(purchase){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/purchases/${purchase._id}`, purchase);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDeliveries(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/deliveries');

                    this.deliveries = res.data.deliveries;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDashboard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/shop/purchases/dashboard/${this.$route.query.manage}`);

                    this.dashboard = res.data.dashboard;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        query(){
            return this.$route.query;
        }
    },
    watch: {
        query(){
            this.search();
        }
    }
}
</script>
