var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [!_vm.$route.query._product ? _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("제품 등록")]) : _c('div', {
    staticClass: "headline text-start mt-8"
  }, [_vm._v("제품 상세")]), _c('v-tabs', {
    staticClass: "mt-4",
    attrs: {
      "background-color": "content"
    },
    model: {
      value: _vm.tab,
      callback: function ($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_c('v-tab', {
    key: "basic"
  }, [_vm._v("기본상품정보")]), _c('v-tab', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    key: "autocomplete"
  }), _c('v-tab-item', {
    key: "basic"
  }, [_c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리")]), _c('v-card-text', [_c('shop-category-select', {
    attrs: {
      "categories": _vm.categories
    },
    model: {
      value: _vm.product._category,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_category", $$v);
      },
      expression: "product._category"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7",
      "sm": "7"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("썸네일")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb || _vm.product.thumb ? _c('v-img', {
    attrs: {
      "src": _vm.thumb ? _vm.createObjectURL(_vm.thumb) : _vm.product.thumb,
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": "썸네일 업로드",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1)], 1), _c('v-row')], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("제품 이미지")]), _c('v-card-text', [_c('v-row', {
    staticClass: "pt-0 pb-0"
  }, [_c('v-col', {
    staticClass: "pt-2 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.images[0] || _vm.product.images[0] ? _c('v-img', {
    attrs: {
      "src": _vm.images[0] ? _vm.createObjectURL(_vm.images[0]) : _vm.product.images[0],
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": '기본보기',
      "show-size": "",
      "dense": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.images[0],
      callback: function ($$v) {
        _vm.$set(_vm.images, 0, $$v);
      },
      expression: "images[0]"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "pt-0 pb-0"
  }, [_c('v-col', {
    staticClass: "pt-2 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.images[1] || _vm.product.images[1] ? _c('v-img', {
    attrs: {
      "src": _vm.images[1] ? _vm.createObjectURL(_vm.images[1]) : _vm.product.images[1],
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": '한폭보기',
      "show-size": "",
      "dense": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.images[1],
      callback: function ($$v) {
        _vm.$set(_vm.images, 1, $$v);
      },
      expression: "images[1]"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "pt-0 pb-0"
  }, [_c('v-col', {
    staticClass: "pt-2 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.images[2] || _vm.product.images[2] ? _c('v-img', {
    attrs: {
      "src": _vm.images[2] ? _vm.createObjectURL(_vm.images[2]) : _vm.product.images[2],
      "width": "128",
      "height": "128"
    }
  }) : _c('v-responsive', {
    attrs: {
      "width": "128",
      "height": "128"
    }
  }, [_c('v-row', {
    staticClass: "fill-height ma-0 grey lighten-3",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": '간지보기',
      "show-size": "",
      "dense": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.images[2],
      callback: function ($$v) {
        _vm.$set(_vm.images, 2, $$v);
      },
      expression: "images[2]"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "기본보기 확대비율(%)",
      "type": "number",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.zoomBasic,
      callback: function ($$v) {
        _vm.$set(_vm.product, "zoomBasic", $$v);
      },
      expression: "product.zoomBasic"
    }
  }), _c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "한폭보기 확대비율(%)",
      "type": "number",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.zoomHeight,
      callback: function ($$v) {
        _vm.$set(_vm.product, "zoomHeight", $$v);
      },
      expression: "product.zoomHeight"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("해시태그")]), _vm.product.hashtags.length ? _c('v-card-text', _vm._l(_vm.product.hashtags, function (hashtag, index) {
    return _c('v-chip', {
      key: index,
      staticClass: "mr-2",
      attrs: {
        "color": "primary",
        "close": "",
        "close-icon": "mdi-delete"
      },
      on: {
        "click:close": function ($event) {
          _vm.product.hashtags.splice(_vm.product.hashtags.indexOf(hashtag), 1);
        }
      }
    }, [_vm._v(_vm._s(hashtag))]);
  }), 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "dense": "",
      "outlined": ""
    },
    on: {
      "input": function ($event) {
        _vm.hashtag = _vm.hashtag.replace(/[^0-9a-zA-Zㄱ-ㅎ가-힣#,]/, '');
      },
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.product.hashtags.push(_vm.hashtag);
        _vm.hashtag = '';
      }
    },
    model: {
      value: _vm.hashtag,
      callback: function ($$v) {
        _vm.hashtag = $$v;
      },
      expression: "hashtag"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.product.hashtags.push(_vm.hashtag);
        _vm.hashtag = '';
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiTagPlus))]), _vm._v(" 추가")], 1)], 1)], 1)], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5",
      "sm": "5"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("제품 정보")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "제품명",
      "placeholder": " "
    },
    model: {
      value: _vm.product.name,
      callback: function ($$v) {
        _vm.$set(_vm.product, "name", $$v);
      },
      expression: "product.name"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제품명(영문)",
      "placeholder": " "
    },
    model: {
      value: _vm.product.nameEn,
      callback: function ($$v) {
        _vm.$set(_vm.product, "nameEn", $$v);
      },
      expression: "product.nameEn"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "제품코드",
      "placeholder": " "
    },
    model: {
      value: _vm.product.code,
      callback: function ($$v) {
        _vm.$set(_vm.product, "code", $$v);
      },
      expression: "product.code"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "판매가 (0원 일때 '무료'로 문구변경)",
      "placeholder": " "
    },
    model: {
      value: _vm.product.price,
      callback: function ($$v) {
        _vm.$set(_vm.product, "price", $$v);
      },
      expression: "product.price"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "재고",
      "placeholder": " ",
      "disabled": _vm.product.optionsEnabled
    },
    model: {
      value: _vm.product.stock,
      callback: function ($$v) {
        _vm.$set(_vm.product, "stock", $$v);
      },
      expression: "product.stock"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "원가 (0원 이상시 할인율도 같이표시)",
      "placeholder": " "
    },
    model: {
      value: _vm.product.cost,
      callback: function ($$v) {
        _vm.$set(_vm.product, "cost", $$v);
      },
      expression: "product.cost"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "진열순서",
      "placeholder": " "
    },
    model: {
      value: _vm.product.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sequence", $$v);
      },
      expression: "product.sequence"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "제조사",
      "placeholder": " "
    },
    model: {
      value: _vm.product.manufacturer,
      callback: function ($$v) {
        _vm.$set(_vm.product, "manufacturer", $$v);
      },
      expression: "product.manufacturer"
    }
  }), _c('v-text-field', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "label": "원산지",
      "placeholder": " "
    },
    model: {
      value: _vm.product.origin,
      callback: function ($$v) {
        _vm.$set(_vm.product, "origin", $$v);
      },
      expression: "product.origin"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', [_c('v-select', {
    attrs: {
      "label": "디자인",
      "items": ['스톤', '플라워', '기하무늬', '스트라이프', '디마스크', '솔리드', '내츄럴', '패브릭', '벽돌', '기타'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.design,
      callback: function ($$v) {
        _vm.$set(_vm.product, "design", $$v);
      },
      expression: "product.design"
    }
  }), _c('v-select', {
    attrs: {
      "label": "용도",
      "items": ['포인트', '아동', '천정', '범용'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.usage,
      callback: function ($$v) {
        _vm.$set(_vm.product, "usage", $$v);
      },
      expression: "product.usage"
    }
  }), _c('v-combobox', {
    attrs: {
      "label": "스타일",
      "items": ['플레인', '내추럴', '지오매트릭', '플라워', '키즈', '집이넓어보이는', '트렌디', '차분한분위기', '화려한분위기'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.style,
      callback: function ($$v) {
        _vm.$set(_vm.product, "style", $$v);
      },
      expression: "product.style"
    }
  }), _c('v-select', {
    attrs: {
      "label": "단가",
      "items": ['저가', '중가', '고가'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.unitPrice,
      callback: function ($$v) {
        _vm.$set(_vm.product, "unitPrice", $$v);
      },
      expression: "product.unitPrice"
    }
  }), _c('v-select', {
    attrs: {
      "label": "종류",
      "items": ['내수', '수출'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.kind,
      callback: function ($$v) {
        _vm.$set(_vm.product, "kind", $$v);
      },
      expression: "product.kind"
    }
  }), _c('v-select', {
    attrs: {
      "label": "타입",
      "items": ['실크', '합지'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.type,
      callback: function ($$v) {
        _vm.$set(_vm.product, "type", $$v);
      },
      expression: "product.type"
    }
  }), _c('v-combobox', {
    attrs: {
      "label": "색상",
      "items": ['화이트/베이지', '옐로우', '블루/그린', '바이올렛/퍼플', '레드/와인', '핑크', '브라운', '블랙/그레이', '메탈', '기타'],
      "placeholder": " "
    },
    model: {
      value: _vm.product.color,
      callback: function ($$v) {
        _vm.$set(_vm.product, "color", $$v);
      },
      expression: "product.color"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "사이즈",
      "placeholder": " "
    },
    model: {
      value: _vm.product.size,
      callback: function ($$v) {
        _vm.$set(_vm.product, "size", $$v);
      },
      expression: "product.size"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "소재",
      "placeholder": " "
    },
    model: {
      value: _vm.product.material,
      callback: function ($$v) {
        _vm.$set(_vm.product, "material", $$v);
      },
      expression: "product.material"
    }
  })], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-text', [_c('div', {
    staticClass: "caption"
  }, [_vm._v("컬렉션 리스트 -> 텍스트타입")]), _c('v-radio-group', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.product.fontType,
      callback: function ($$v) {
        _vm.$set(_vm.product, "fontType", $$v);
      },
      expression: "product.fontType"
    }
  }, [_c('v-radio', {
    attrs: {
      "value": "black",
      "label": "블랙"
    }
  }), _c('v-radio', {
    attrs: {
      "value": "light",
      "label": "화이트"
    }
  }), _c('v-radio', {
    attrs: {
      "value": "shadow",
      "label": "쉐도우"
    }
  })], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("브랜드")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "12",
      "md": "6"
    }
  }, [_c('v-select', {
    staticClass: "mt-0",
    attrs: {
      "items": _vm.brands,
      "item-text": "name",
      "item-value": "_id",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product._brand,
      callback: function ($$v) {
        _vm.$set(_vm.product, "_brand", $$v);
      },
      expression: "product._brand"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("전시여부 (전시/숨김)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.display,
      callback: function ($$v) {
        _vm.$set(_vm.product, "display", $$v);
      },
      expression: "product.display"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("판매여부 (판매/중단)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.sale,
      callback: function ($$v) {
        _vm.$set(_vm.product, "sale", $$v);
      },
      expression: "product.sale"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("신상품 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.newly,
      callback: function ($$v) {
        _vm.$set(_vm.product, "newly", $$v);
      },
      expression: "product.newly"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("특가 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.special,
      callback: function ($$v) {
        _vm.$set(_vm.product, "special", $$v);
      },
      expression: "product.special"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("베스트 (사용/미사용)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.best,
      callback: function ($$v) {
        _vm.$set(_vm.product, "best", $$v);
      },
      expression: "product.best"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mt-4"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("품절여부 (품절/판매)")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.product.soldout,
      callback: function ($$v) {
        _vm.$set(_vm.product, "soldout", $$v);
      },
      expression: "product.soldout"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('shop-product-discount', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-options', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-supplies', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-shippings', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-islands', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-bestmatchs', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-icons', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('shop-product-seo', {
    model: {
      value: _vm.product,
      callback: function ($$v) {
        _vm.product = $$v;
      },
      expression: "product"
    }
  }), _c('v-card', {
    staticClass: "mt-6"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("상품 상세 설명")]), _c('v-card-text', [_c('naver-smarteditor', {
    attrs: {
      "rows": "10"
    },
    model: {
      value: _vm.product.content,
      callback: function ($$v) {
        _vm.$set(_vm.product, "content", $$v);
      },
      expression: "product.content"
    }
  })], 1)], 1)], 1), _c('v-tab-item', {
    key: "autocomplete"
  })], 1), _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }