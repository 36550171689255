var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "max-width": "1700"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-file-input', {
          ref: "input",
          staticClass: "d-none",
          attrs: {
            "accept": ".xlsx"
          },
          on: {
            "change": function (file) {
              return _vm.change(file);
            }
          },
          model: {
            value: _vm.file,
            callback: function ($$v) {
              _vm.file = $$v;
            },
            expression: "file"
          }
        })];
      }
    }]),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-2"
  }, [_vm._v("인테리어샵 업로드")]), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.shops,
      "headers": _vm.shopsHeaders,
      "disable-sort": "",
      "disable-filtering": ""
    }
  }), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "grey"
    },
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.upload
    }
  }, [_vm._v("업로드")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }