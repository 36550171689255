var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-6 shop-product-supplies"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("칼라 아이콘 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.iconsEnabled,
      callback: function ($$v) {
        _vm.iconsEnabled = $$v;
      },
      expression: "iconsEnabled"
    }
  })], 1)], 1)], 1), _vm.iconsEnabled && _vm.icons.length ? _c('v-card-text', [_c('v-row', _vm._l(_vm.icons, function (icon, index) {
    return _c('v-col', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !icon.removed,
        expression: "!icon.removed"
      }],
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-responsive', {
      staticClass: "text-center",
      attrs: {
        "width": "48"
      }
    }, [_c('v-img', {
      attrs: {
        "width": "48",
        "height": "48",
        "src": icon.file ? _vm.createObjectURL(icon.file) : icon.filename
      }
    }), _c('span', [_vm._v(_vm._s(icon.name))]), _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function ($event) {
          return _vm.remove(icon, index);
        }
      }
    }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.iconsEnabled ? _c('v-divider') : _vm._e(), _vm.iconsEnabled ? _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-img', {
    attrs: {
      "width": "48",
      "height": "48",
      "src": _vm.file ? _vm.createObjectURL(_vm.file) : ''
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-file-input', {
    attrs: {
      "label": "파일찾기",
      "placeholder": " ",
      "dense": "",
      "hide-details": "",
      "show-size": "",
      "accept": "image/*"
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "칼라이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }