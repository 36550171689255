<template>
    <v-container fluid fill-height>
        <shop-product-list v-if="$route.query._product === undefined && $route.query._copy === undefined"></shop-product-list>
        <shop-product-view v-else></shop-product-view>
    </v-container>
</template>

<script>
import ShopProductList from './product/shop-product-list.vue';
import ShopProductView from './product/shop-product-view.vue';

export default {
    components: {
        ShopProductList,
        ShopProductView
    },
    data: () => {
        return {};
    },
}
</script>
