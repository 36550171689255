<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%" class="px-0 px-md-8">
                <v-row class="mt-0 mt-md-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">포인트 관리</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mx-auto">
                        <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.startDate" label="포인트 지급&사용일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6" class="pt-0 pb-0">
                                    <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.endDate" label="포인트 지급&사용일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="pt-0 pb-0" cols="12" sm="6">
                                    <v-text-field v-model="filter.username" label="사용자이름" placeholder=" " dense hide-details type="text" />
                                </v-col>
                                <v-col class="pt-0 pb-0" cols="12" sm="6">
                                    <v-text-field v-model="filter.phone" label="연락처" placeholder=" " dense hide-deatails type="text" />
                                </v-col>
                            </v-row>
                            <v-row>
                            </v-row>
                        </v-card-text>
                        <v-card-actions class="pt-0">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-data-table :headers="headers" :items="orders" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.create_date="{item}">
                        {{ toDateTime(item.create_date) }}
                    </template>
                    <template v-slot:item.goods="{item}">
                        <v-responsive min-width="256px">
                            <v-row align="center">
                                <v-col md="auto">
                                    <v-img :src="item.goods.goodsImgS" max-width="56" max-height="56"></v-img>
                                </v-col>
                                <v-col>
                                    <div class="text-start">{{ item.goods.goodsName }}</div>
                                    <div class="text-start">{{ item.goods.realPrice.format() }}원</div>
                                </v-col>
                            </v-row>
                        </v-responsive>
                    </template>
                    <template v-slot:item.user.phone="{ item }">
                        {{ phone_number_format(item.user.phone) }}
                    </template>
                    <template v-slot:item.amount="{item}">
                        <v-responsive min-width="40px">{{ item.amount.format() }}개</v-responsive>
                    </template>
                    <template v-slot:item.total_amount="{item}">
                        <v-responsive min-width="64px">{{ (item.amount * item.phones.length).format() }}개</v-responsive>
                    </template>
                    <template v-slot:item.price="{item}">
                        <v-responsive min-width="80px">{{ item.price.format() }}원</v-responsive>
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon small @click="editItem(item)">mdi-pencil</v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
            },

            ///////////////////////////////////////////////
            // 주문목록
            ///////////////////////////////////////////////
            orders: [],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            headers: [{
                text: '사용자',
                align: 'center',
                width: 200,
                value: 'product'
            }, {
                text: '포인트',
                align: 'center',
                width: 200,
                value: 'user'
            }, {
                text: '비고',
                align: 'center',
                value: 'user.username'
            }, {
                text: '내역일자',
                align: 'center',
                width: 170,
                value: 'createDate'
            }, {
                text: 'Actions',
                align: 'center',
                width: 140,
                value: 'actions'
            }],
        };
    },
    methods: {

    }
}
</script>
