<template>
    <v-container fluid fill-height>
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">

                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">구매후기</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-expand-transition>
                    <v-card v-show="showSearch" class="elevation-1 mx-auto">
                        <v-card-text>
                            <v-row class="mt-4">
                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createDates0" :close-on-content-click="false" :return-value.sync="filter.createDates[0]" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.createDates[0]" label="등록일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createDates[0]" no-title scrollable @input="$refs.createDates0.save(filter.createDates[0])"></v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" sm="6" class="py-0">
                                    <v-menu ref="createDates1" :close-on-content-click="false" :return-value.sync="filter.createDates[1]" transition="scale-transition" offset-y min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="filter.createDates[1]" label="등록일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="filter.createDates[1]" no-title scrollable @input="$refs.createDates1.save(filter.createDates)"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col cols="12" md="3" class="py-0">
                                    <v-select v-model="filter.searchKey" label="검색옵션" :items="searchKeyItems" item-text="text" item-value="value" placeholder=" " dense hide-details></v-select>
                                </v-col>
                                <v-col cols="12" md="3" class="py-0">
                                    <v-text-field v-model="filter.searchValue" label="검색어" :disabled="!filter.searchKey" placeholder=" " dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" rounded text @click="search" dense><v-icon class="mr-2">mdi-magnify</v-icon>검색</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

                <v-row class="mt-6">
                    <v-col class="py-0">
                        <span class="subtitle-2">검색수 : {{ summary.totalCount.format() }}개</span>
                    </v-col>
                </v-row>
                <v-data-table :headers="reviewsHeaders" :items="reviews" disable-sort disable-pagination hide-default-footer class="elevation-1">
                    <template v-slot:item.createDate="{item}">
                        {{ item.createDate.toDate() }}
                    </template>
                    <template v-slot:item.product="{ item }">
                        <v-row align="center">
                            <v-col cols="auto">
                                <v-img :src="item.images[0]" contain max-height="96" max-width="96"></v-img>
                            </v-col>
                            <v-col class="text-start">
                                {{ item.product.name }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.user="{ item }">
                        <div>{{ item.user.name }}</div>
                        <div>{{ item.user.username }}</div>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                createDates: [],
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null
            },

            searchKeyItems: [
                { text: "전체", value: null },
                { text: "작성자명", value: "user.name" },
                { text: "회원아이디", value: "user.username" },
                { text: "연락처", value: "user.phone" },
                { text: "상품명", value: "product.name" }
            ],

            ///////////////////////////////////////////////
            // 페이징
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            ///////////////////////////////////////////////
            // 리뷰목록
            ///////////////////////////////////////////////
            summary: { totalCount: 0 },
            reviews: [],
            reviewsHeaders: [{
                text: '상품',
                align: 'center',
                value: 'product'
            }, {
                text: '후기내용',
                align: 'center',
                value: 'content'
            }, {
                text: '작성자',
                align: 'center',
                width: 140,
                value: 'user'
            }, {
                text: '작성일자',
                align: 'center',
                width: 170,
                value: 'createDate'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getReviews();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        async search(){
            try{
                await this.getReviews();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        getReviews(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/reviews', {
                        params: this.filter
                    });

                    this.summary = res.data.summary;
                    this.reviews = res.data.reviews;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    watch: {
        page(){
            this.search();
        }
    }
}
</script>
