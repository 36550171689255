<template>
<v-container fluid fill-height class="console-terms">
    <v-layout v-if="setting" justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">자사정보 관리</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="py-0 subtitle-1">무통장입금 계좌연동</v-col>
                        <v-spacer></v-spacer>
                        <v-col cols="auto" class="py-0">
                            <v-switch v-model="setting.shop.cashRecipt.enabled" dense hide-details class="pa-0 ma-0" @change="putSetting"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col><v-select v-model="setting.shop.accountBankCode" label="은행" placeholder=" " :items="banks" item-text="name" item-value="code" @input="putSetting"></v-select></v-col>
                        <v-col><v-text-field v-model="setting.shop.accountHolder" label="예금주" placeholder=" " @input="putSetting"></v-text-field></v-col>
                        <v-col><v-text-field v-model="setting.shop.accountNumber" label="계좌번호" placeholder=" " @input="putSetting"></v-text-field></v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title>
                    <v-row>
                        <v-col cols="auto" class="py-0 subtitle-1">SEO(검색어 최적화 설정)</v-col>
                    </v-row>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="setting.seo.title" label="타이틀: Title" placeholder=" " dense hide-details class="mt-3" @input="meta('title').content = setting.seo.title; putSetting();"></v-text-field>

                    <v-text-field v-model="meta('title').content" label="메타태그: Title" placeholder=" " disabled dense hide-details class="mt-6" @input="putSetting"></v-text-field>
                    <v-text-field v-model="meta('author').content" label="메타태그: Author" placeholder=" " dense hide-details class="mt-6" @input="putSetting"></v-text-field>
                    <v-text-field v-model="meta('subject').content" label="메타태그: Subject" placeholder=" " dense hide-details class="mt-6" @input="putSetting"></v-text-field>
                    <v-text-field v-model="meta('copyright').content" label="메타태그: Copyright" placeholder=" " dense hide-details class="mt-6" @input="putSetting"></v-text-field>
                    <v-textarea v-model="meta('decription').content" label="메타태그: Description" placeholder=" " dense hide-details class="mt-6" @input="putSetting"></v-textarea>
                    <v-textarea v-model="meta('keywords').content" label="메타태그: Keywords" placeholder=" " dense hide-details class="mt-6" @input="putSetting"></v-textarea>
                </v-card-text>
            </v-card>

        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
import banks from "../../../plugins/shop-default-banks.json";

export default {
    created() {
        this.init();
    },
    data: () => {
        return {
            setting: null,
            banks
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getSetting();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        meta(name){
            var meta = this.setting.seo.meta.find((meta) => meta.name == name);
            if(!meta){
                meta = { name, content: null };
                this.setting.seo.meta.push(meta);
            }
            return meta;
        },

        getSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/setting");

                    this.setting = res.data.setting;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put("/api/console/setting", this.setting);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
