<template>
    <v-container fluid fill-height>
        <shop-brand-list v-if="$route.query._brand === undefined"></shop-brand-list>
        <shop-brand-view v-else></shop-brand-view>
    </v-container>
</template>

<script>
import ShopBrandList from './brand/shop-brand-list.vue';
import ShopBrandView from './brand/shop-brand-view.vue';

export default {
    components: {
        ShopBrandList,
        ShopBrandView
    },
    data: () => {
        return {};
    },
}
</script>
