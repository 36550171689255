var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.$route.query.manage === undefined ? _c('shop-order-default') : _vm._e(), _vm.$route.query.manage == 'nonpayment' ? _c('shop-order-nonpayment') : _vm._e(), _vm.$route.query.manage == 'payment' ? _c('shop-order-payment') : _vm._e(), _vm.$route.query.manage == 'delivery' ? _c('shop-order-delivery') : _vm._e(), _vm.$route.query.manage == 'cancel' ? _c('shop-order-cancel') : _vm._e(), _vm.$route.query.manage == 'exchange' ? _c('shop-order-exchange') : _vm._e(), _vm.$route.query.manage == 'return' ? _c('shop-order-return') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }