<template>
    <v-layout justify-center>
        <v-responsive max-width="1440px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">쿠폰 관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text>
                        <v-row align="center">
                            <v-col cols="12" class="subtitme-2 py-0">타켓팅 대상</v-col>
                            <v-col cols="12" class="py-0">
                                <v-radio-group row dense hide-details class="mt-0">
                                    <v-radio label="전체"></v-radio>
                                    <v-radio label="첫구매고객"></v-radio>
                                    <v-radio label="재구매고객"></v-radio>
                                    <v-radio label="고객지정"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select label="발급상태" placeholder=" " dense hide-details></v-select>
                            </v-col>
                        </v-row>

                        <v-row class="mt-6" align="center">
                            <v-col cols="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate" label="조회기간" placeholder=" " append-icon="event" dense hide-details readonly v-on="on">
                                        </v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate)"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate" label="조회기간" placeholder=" " append-icon="event" dense hide-details readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate)"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="auto">
                    <v-btn color="secondary" @click="remove">선택삭제</v-btn>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" :headers="couponHeaders" :items="coupons" show-select disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.type="{ item }">
                    <span v-if="item.type == 'product'">상품할인</span>
                    <span v-if="item.type == 'category'">카테고리할인</span>
                    <span v-if="item.type == 'order'">주문금액할인</span>
                    <span v-if="item.type == 'delivery'">배송비 할인</span>
                </template>
                <template v-slot:item.kind="{ item }">
                    <span v-if="item.kind == 'first'">첫구매고객</span>
                    <span v-if="item.kind == 'second'">재구매고객</span>
                    <span v-if="item.kind == 'target'">타겟팅</span>
                </template>
                <template v-slot:item.status="{ item }">
                    <span v-if="item.method == 'download'">
                        <span v-if="item.download.period">
                            <span v-if="new Date(item.download.startDate + ' 00:00:00') <= Date.now() && Date.now() <= new Date(item.download.endDate + ' 23:59:59')">적용중</span>
                            <span v-else>중단</span>
                        </span>
                        <span v-else>적용중</span>
                    </span>
                    <span v-if="item.method == 'immediately'">완료</span>
                </template>
                <template v-slot:item.expiration="{ item }">
                    <span v-if="item.expiration.enabled">
                        <span v-if="item.expiration.type == 'dates'">{{ item.expiration.startDate }} ~ {{ item.expiration.endDate }}</span>
                        <span v-if="item.expiration.type == 'days'">발급일로부터 {{ item.expiration.days }}일 간</span>
                    </span>
                    <span v-else>{{ item.createDate.toDate() }} ~ </span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small @click="edit(item)">mdi-pencil</v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

            <v-btn bottom color="accent" fab fixed right @click="create">
                <v-icon>mdi-pencil</v-icon>
            </v-btn>
        </v-responsive>
    </v-layout>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
            },

            ///////////////////////////////////////////////
            // 주문목록
            ///////////////////////////////////////////////
            coupons: [],
            selected: [],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,
            couponHeaders: [{
                text: '쿠폰명칭',
                align: 'center',
                width: 400,
                value: 'name'
            },
            {
                text: '쿠폰종류',
                align: 'center',
                width: 140,
                value: 'type'
            },
            {
                text: '타겟팅 대상',
                align: 'center',
                width: 140,
                value: 'kind'
            },
            {
                text: '혜택상태',
                align: 'center',
                width: 100,
                value: 'status'
            },
            {
                text: '발급시작일',
                align: 'center',
                width: 140,
                value: 'download.startDate'
            },
            {
                text: '발급종료일',
                align: 'center',
                width: 140,
                value: 'download.endDate'
            },
            {
                text: '쿠폰유효기간',
                align: 'center',
                width: 300,
                value: 'expiration'
            },
            {
                text: '발급건수',
                align: 'center',
                width: 100,
                value: 'create_date'
            },
            {
                text: '사용건수',
                align: 'center',
                width: 100,
                value: 'create_date'
            },
            {
                text: 'Actions',
                align: 'center',
                value: 'actions'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getCoupons();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        edit(coupon){
            this.$router.push({
                query: {
                    _coupon: coupon._id
                }
            });
        },

        async remove(){
            try{
                if(confirm("쿠폰을 삭제하시겠습니까?")){
                    for(var i in this.selected){
                        await this.deleteCoupon(this.selected[i]);
                    }
                    await this.getCoupons();
                }
            }
            catch(error){
                console.error(error);
            }
        },

        create(){
            this.$router.push({
                name: this.$route.name,
                query: {
                    _coupon: null
                }
            });
        },

        getCoupons(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/shop/coupons", {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        }
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.coupons = res.data.coupons;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteCoupon(coupon){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/console/shop/coupons/${coupon._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
