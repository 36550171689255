<template>
<div>
    <console-app v-if="accessToken"></console-app>
    <console-sign-in v-else></console-sign-in>
</div>
</template>

<script>
import 'vuetify/dist/vuetify.min.css'

import ConsoleApp from '../../components/console/console-app.vue';
import ConsoleSignIn from '../../components/console/console-sign-in.vue';

export default {
    components: {
        ConsoleApp,
        ConsoleSignIn
    },
    computed: {
        accessToken(){
            return this.$store.state.accessToken;
        },
        payload(){
            return this.$store.state.payload;
        }
    }
}
</script>
