<template>
    <v-container fluid fill-height>
        <user-list v-if="$route.query._user === undefined"></user-list>
        <user-view v-else></user-view>
    </v-container>
</template>

<script>
import UserList from './user/user-list.vue';
import UserView from './user/user-view.vue';

export default {
    components: {
        UserList,
        UserView
    },
    data: () => {
        return {};
    },
}
</script>
