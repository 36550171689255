<template>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app :color="color" dark dense>
        <v-app-bar-nav-icon @click.stop="$emit('input', !value)"></v-app-bar-nav-icon>
        <v-toolbar-title class="ml-0 pl-4 text-start subtitle-1">
            <span>{{ title }}</span>
        </v-toolbar-title>
        <v-text-field v-if="false" flat solo-inverted hide-details prepend-inner-icon="mdi-magnify" label="Search" class="hidden-sm-and-down" />
        <v-spacer />
        <v-btn text href="/" class="px-2" min-width="auto">
            <v-icon medium>mdi-home</v-icon>
            <span class="ml-2 d-none d-sm-inline-block">내홈페이지</span>
        </v-btn>
    </v-app-bar>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: null },
        title: { type: String, default: "" },
        color: { type: String, default: "primary" }
    },
    data: () => {
        return {

        };
    },
}
</script>
