var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("취급컬렉션")]), _c('v-card-text', _vm._l(_vm.collections, function (collection, index) {
    return _c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "close": "",
        "close-icon": "mdi-delete",
        "color": "primary"
      },
      on: {
        "click:close": function ($event) {
          return _vm.collections.splice(index, 1);
        }
      }
    }, [_vm._v(_vm._s(collection))]);
  }), 1), _c('v-divider'), _c('v-card-actions', [_c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "컬렉션명칭",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.collection,
      callback: function ($$v) {
        _vm.collection = $$v;
      },
      expression: "collection"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("추가")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }