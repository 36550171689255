<template>
    <v-container fluid fill-height class="console-banners">
        <v-layout justify-center>
            <v-responsive max-width="1280px" width="100%">
                <v-row class="mt-8" justify="center" align="center">
                    <v-col cols="auto" class="pt-0 pb-0 headline text-start">배너관리</v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pt-0 pb-0">
                        <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                    </v-col>
                </v-row>

                <v-data-table :headers="headers" :items="banners" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                    <template v-slot:item.createDate="{ item }">
                        {{ item.createDate ? item.createDate.toDate() : '' }}
                    </template>
                    <template v-slot:item.slides="{ item }">
                        <v-row v-for="slide in item.slides.filter((slide) => slide.image)" align="center">
                            <v-col cols="auto">
                                <v-img :src="slide.image" max-width="320px" contain></v-img>
                            </v-col>
                            <v-col cols="auto">
                                {{ slide.url }}
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon small class="mr-2" @click="edit(item)">
                            mdi-pencil
                        </v-icon>
                        <v-icon small @click="remove(item)">
                            mdi-delete
                        </v-icon>
                    </template>
                </v-data-table>
                <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12"></v-pagination>

                <v-dialog v-model="dialog" max-width="720px">
                    <template v-slot:activator="{ on }">
                        <v-btn bottom color="accent" dark fab fixed right @click="create">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <v-card v-if="dialog" class="console-banners-dialog">
                        <v-card-title>
                            <span v-if="editItem._id" class="subtitle-1 font-weight-bold">배너수정</span>
                            <span v-else class="subtitle-1 font-weight-bold">배너추가</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container class="pb-0">
                                <v-row align="center">
                                    <v-col cols="12" class="pa-0">
                                        <v-text-field v-model="editItem.code" label="코드" placeholder=" " dense hide-details></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row align="center" class="mt-6">
                                    <v-col cols="12" class="pa-0">
                                        <v-text-field v-model="editItem.name" label="명칭" placeholder=" " dense hide-details></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row align="center" class="mt-6">
                                <v-col cols="12" class="py-0">
                                    <v-carousel class="carousel" height="320px">
                                        <v-carousel-item v-for="(slide, i) in editItem.slides" :key="i">
                                            <v-sheet height="100%" tile light>
                                                <v-row style="height: calc(100% - 100px)" align="center">
                                                    <label style="width: 100%;" :style="{ height: images[i] || slide.image ? '' : '100%' }">
                                                        <v-img v-if="images[i] || slide.image" :src="images[i] ? createObjectURL(images[i]) : slide.image" width="100%" max-height="172" contain></v-img>
                                                        <v-responsive v-else width="100%" height="100%">
                                                            <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                        <v-file-input v-show="false" v-model="images[i]" label="이미지 업로드" show-size accept="image/*"></v-file-input>
                                                    </label>
                                                </v-row>
                                                <v-row align="center">
                                                    <v-col class="py-0">
                                                        <v-text-field v-model="slide.url" label="URL" placeholder=" " dense hide-details></v-text-field>
                                                    </v-col>
                                                    <v-col v-if="images[i] || slide.image" cols="auto" class="py-0 pl-0">
                                                        <v-btn class="px-2" @click="removeImage(editItem, i)"><v-icon class="mr-2" small color="grey darken-1">mdi-delete</v-icon>배너이미지 삭제</v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-sheet>
                                        </v-carousel-item>
                                    </v-carousel>
                                </v-col>
                            </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="close">취소</v-btn>
                            <v-btn text color="primary" @click="save">저장</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-responsive>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    created(){
        this.init();
    },
    data: () => {
        return {
            showSearch: false,
            dialog: false,
            page: 1,
            pageCount: 0,
            limit: 10,
            headers: [
                {
                    text: '코드',
                    width: 128,
                    align: 'center',
                    value: 'code'
                },
                {
                    text: '명칭',
                    align: 'center',
                    value: 'name'
                },
                {
                    text: '슬라이드',
                    align: 'center',
                    value: 'slides'
                },
                {
                    text: '등록일자',
                    align: 'center',
                    width: 140,
                    value: 'createDate'
                },
                {
                    text: 'actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],
            banners: [],
            editItem: null,
            defaultItem: {
                _id: null,
                code: null,
                name: null,
                slides: [
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                    { image: null, url: null },
                ]
            },

            images: new Array(10)
        };
    },
    methods: {
        async init(){
            try{
                await this.getBanners();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        close() {
            this.dialog = false
        },

        create(){
            this.editItem = Object.assign({}, this.defaultItem);
            this.$forceUpdate();
            this.dialog = true;
        },

        edit(banner) {
            this.editItem = Object.assign({}, banner);
            this.$forceUpdate();
            this.dialog = true;
        },

        async save() {
            try{
                var banner = this.editItem._id ? await this.putBanner() : await this.postBanner();
                for(var i in this.images){
                    if(this.images[i]) await this.postImage({ banner, image: this.images[i], index: i });
                }

                await this.getBanners();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
            this.dialog = false;
        },

        async remove(banner){
            try{
                await this.deleteBanner(banner);
                await this.getBanners();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        getBanners(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/banners', {
                        headers: {
                            "skip": (this.page - 1) * this.limit,
                            "limit": this.limit
                        }
                    });

                    this.banners = res.data.banners;
                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postBanner(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post('/api/console/banners', this.editItem);

                    resolve(res.data.banner);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postImage({ banner, image, index }){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('image', image);
                    await this.$http.post(`/api/console/banners/${banner._id}/images/${index}` , data, {
                        headers: { 'Content-Type' : 'multipart/form-data' }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        async removeImage(banner, index){
            try{
                if(confirm("배너 이미지를 삭제하시겠습니까?")){
                    if(banner._id) await this.$http.delete(`/api/console/banners/${banner._id}/images/${index}`).then(result => result.data);

                    this.images[index] = undefined;
                    banner.slides[index].image = undefined;
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        putBanner(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put('/api/console/banners/' + this.editItem._id, this.editItem);

                    resolve(res.data.banner);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteBanner(banner){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete('/api/console/banners/' + banner._id);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
    watch: {
        dialog(){
            if(!this.dialog){
                this.editedItem = Object.assign({}, this.defaultItem)
            }
        }
    }
}
</script>

<style>
.console-banners-dialog .v-window__prev { margin-top: -50px !important; }
.console-banners-dialog .v-window__next { margin-top: -50px !important; }
.console-banners-dialog .v-carousel__controls { background-color: rgba(0,0,0,0.57) }
</style>
