<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div v-if="!$route.query._board" class="headline text-start mt-8">게시글 등록</div>
            <div v-else class="headline text-start mt-8">게시글 상세</div>

            <v-card v-if="$route.query._board" class="mt-4">
                <v-card-title class="subtitle-1">작성자 정보</v-card-title>
                <v-card-text class="pb-6">
                    <v-row v-if="['interior-inquire'].includes($route.params.code)" class="mt-2">
                        <v-col cols="4" class="py-0">
                            <v-text-field :value="board.user.name" label="이름" placeholder=" " dense hide-details> </v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-text-field :value="board.user.phone" label="연락처" placeholder=" " dense hide-details> </v-text-field>
                        </v-col>
                        <v-col cols="4" class="py-0">
                            <v-text-field :value="board.user.email" label="이메일" placeholder=" " dense hide-details> </v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-4">
                <v-card-title class="subtitle-1">기본정보</v-card-title>
                <v-card-text class="pb-6">
                    <v-row v-if="['support','interior-inquire','event'].includes($route.params.code)" class="mt-2">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.subject" label="제목" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                        <v-col v-if="['interior-inquire'].includes($route.params.code)" cols="6" class="py-0">
                            <v-text-field v-model="board.createDate" label="작성일" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    
                    <v-row v-if="['event'].includes($route.params.code)" class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board.category" :items="['진행중 이벤트','종료된 이벤트']" label="카테고리" placeholder=" " dense hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row v-if="['event'].includes($route.params.code)" class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.period" label="기간" placeholder="ex) 2020-01-01 ~ 2020.12.31" dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row v-if="['support','interior-shop','event'].includes($route.params.code)" class="mt-6">
                        <v-col cols="12" class="py-0">
                            <v-text-field v-model="board.summary" label="한줄설명" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="['support','event'].includes($route.params.code)" class="mt-4">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="board.thumb || board.thumbFile" width="283" height="185" :src="board.thumbFile ? createObjectURL(board.thumbFile) : board.thumb" contain></v-img>
                            <v-responsive v-else width="283" height="185" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="board.thumbFile" label="썸네일 이미지" placeholder=" " class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card v-if="['interior-shop'].includes($route.params.code)" class="mt-4">
                <v-card-title class="subtitle-1">상세정보</v-card-title>
                <v-card-text class="pb-6">
                    <v-row class="mt-2">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.storeName" label="취급점명" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.storePhone" label="연락처" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.storeEmail" label="이메일" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board.sido" :items="sidoGugun" item-text="name" label="시/도" placeholder=" " dense hide-details></v-select>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-select v-model="board.gugun" :items="board.sido ? sidoGugun.find((sido) => sido.name == board.sido).gugun : []" item-text="name" label="구/군" placeholder=" " dense hide-details></v-select>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.area" label="지역" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.postcode" label="우편번호" placeholder=" " dense hide-details readonly @click="searchPostcode"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-6">
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.address1" label="기본주소" placeholder=" " dense hide-details readonly @click="searchPostcode"></v-text-field>
                        </v-col>
                        <v-col cols="6" class="py-0">
                            <v-text-field v-model="board.address2" label="상세주소" placeholder=" " dense hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <board-collections v-if="['interior-shop'].includes($route.params.code)" v-model="board"></board-collections>

            <board-relative-products v-if="['support','interior-shop','interior-inquire'].includes($route.params.code)" v-model="board"></board-relative-products>

            <board-portfolios v-if="['interior-shop'].includes($route.params.code)" v-model="board"></board-portfolios>

            <v-card class="mt-6">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.content" id="content" rows="30"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-card v-if="['interior-inquire'].includes($route.params.code)" class="mt-6">
                <v-card-title class="subtitle-1">답변</v-card-title>
                <v-card-text>
                    <naver-smarteditor v-model="board.reply" id="reply" rows="30"></naver-smarteditor>
                </v-card-text>
            </v-card>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

            <daum-postcode ref="daumPostcode" @change="changePostcode"></daum-postcode>

        </v-responsive>
    </v-layout>
</template>

<script>
import sidoGugun from '@/plugins/sido-gugun.json';

import DaumPostcode from '@/components/plugins/daum/daum-postcode.vue';
import NaverSmarteditor from '@/components/plugins/naver/naver-smarteditor.vue';

import BoardRelativeProducts from './board-relative-products.vue';
import BoardCollections from './board-collections.vue';
import BoardPortfolios from './board-portfolios.vue';

import { mdiTagPlus } from '@mdi/js';

export default {
    components: {
        DaumPostcode,
        NaverSmarteditor,
        BoardRelativeProducts,
        BoardCollections,
        BoardPortfolios,
    },
    data: () => {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: null,
                category: null,

                subject: null,
                content: null,
                summary: null,
                period: null,
                createDate: null,

                sequence: 1,

                storeName: null,
                storePhone: null,
                storeEmail: null,

                sido: null,
                gugun: null,
                area: null,
                postcode: null,
                address1: null,
                address2: null,
                lat: null,
                lng: null,

                writer: {
                    name: null,
                    phone: null,
                    email: null
                },

                collections: [],
                relativeProducts: [],
                portfolios: [],

                thumb: null,
                thumbFile: null,
            },

            sidoGugun
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                // if(this.$route.query.method == "copy"){
                //     this.board._id = undefined;
                //
                //     if(this.board.thumb){
                //         this.thumb = await this.getResource(this.board.thumb);
                //         this.board.thumb = undefined;
                //     }
                //     for(var i in this.board.images){
                //         if(this.board.images[i]){
                //             this.images[i] = await this.getResource(this.board.images[i]);
                //             this.board.images[i] = undefined;
                //         }
                //     }
                // }
                //
                // if(this.board._id === undefined){
                //     var { summary } = await this.getBoards();
                //     this.board.sequence = summary.totalCount + 1;
                // }

                if(this.$route.query._board) this.board = await this.getBoard();

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        async save(){
            try{
                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                var board = this.board._id ? await this.putBoard() : await this.postBoard();

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if(this.board.thumbFile) await this.postThumb(board._id, this.board.thumbFile);

                for(var i in this.board.portfolios){
                    var portfolio = this.board.portfolios[i];
                    if(!portfolio.removed) portfolio.imageFile ? await this.postPortfolio(board._id, i, portfolio) : await this.putPortfolio(board._id, i, portfolio);
                }

                for(var i = this.board.portfolios.length - 1; 0 <= i; i--){
                    var portfolio = this.board.portfolios[i];
                    if(portfolio.removed) await this.deletePortfolio(board._id, i);
                }

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");
                this.$router.go(-1);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message);
            }
        },

        searchPostcode(){
            this.$refs.daumPostcode.open();
        },

        changePostcode(data){
            this.board.postcode = data.postcode;
            this.board.address1 = data.address;
        },

        getBoards(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/boards', {
                        params: {
                            code: this.$route.params.code
                        }
                    });

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getBoard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/boards/${this.$route.query._board}`);

                    resolve(res.data.board);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postBoard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var board = Object.assign({}, this.board, {
                        code: this.$route.params.code,
                        relativeProducts: this.board.relativeProducts.map((product) => product._id)
                    });

                    var res = await this.$http.post('/api/console/boards', board);

                    resolve(res.data.board);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putBoard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var board = Object.assign({}, this.board, {
                        code: this.$route.params.code,
                        relativeProducts: this.board.relativeProducts.map((product) => product._id)
                    });

                    var res = await this.$http.put(`/api/console/boards/${this.$route.query._board}`, board);

                    resolve(res.data.board);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postThumb(_board, thumbFile){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('thumbFile', thumbFile);
                    await this.$http.post(`/api/console/boards/${_board}/thumb`, data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postPortfolio(_board, index, portfolio){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('imageFile', portfolio.imageFile);
                    data.append('spot', portfolio.spot);
                    data.append('size', portfolio.size);
                    data.append('style', portfolio.style);
                    data.append('name', portfolio.name);

                    await this.$http.post(`/api/console/boards/${_board}/portfolios/${index}` , data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putPortfolio(_board, index, portfolio){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.put(`/api/console/boards/${_board}/portfolios/${index}`, portfolio);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deletePortfolio(_board, index){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.delete(`/api/console/boards/${_board}/portfolios/${index}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getResource(filename){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http({
                        url: filename,
                        method: 'GET',
                        responseType: 'blob'
                    });

                    resolve(new File([res.data], filename));
                }
                catch(error){
                    reject(error);
                }
            });
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
}
</script>
