<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view overflow-visible">
            <div class="headline text-start mt-8 mb-4">제품 파일 업로드</div>
                <v-card>
                    <v-card-title>
                        <span class="subtitle-1">벽지 엑셀 파일</span>
                        <v-spacer />
                        <v-btn color="primary" outlined @click="download(`/assets/excel/didwallpaper_upload.xlsx`, `didwallpaper_upload.xlsx`)"><v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon><span>엑셀 양식 다운로드</span></v-btn>
                        <v-file-btn v-model="wallpaper" outlined color="blue" class="ml-2" accept=".xlsx" @change="file => preload(file)">
                            <v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>
                            <span>벽지 엑셀 업로드</span>
                        </v-file-btn>
                    </v-card-title>
                    <v-divider />
                    <v-card-text>
                        <div class="caption primary--text"><b>※ 주의사항</b></div>
                        <div class="caption mt-1"><b>* 카테고리는 선행 등록이 되어있어야 합니다. <router-link to="/console/shop/categories">[컬렉션 & 텍스쳐 관리]</router-link>에서 설정 할 수 있습니다.</b></div>
                        <div class="caption mt-1"><b>* [썸네일, 기본보기, 한폭보기, 간지보기] 이미지 파일 이름이 같으면, 이미지가 동일한 현상이 발생하므로 다르게 설정해주시기 바랍니다.</b></div>
                        <div class="caption mt-1"><b>* 작성 오류가 있는 행은 빨간색으로 표시됩니다. 빨간 행은 업로드 되지 않습니다.</b></div>
                    </v-card-text>
                    <v-divider />
                    <v-data-table :items="preitems" :headers="preitemsHeaders" :item-class="item => toStyle(check(item))" disable-sort class="preitems">
                        <template v-slot:[`item.section`]="{item}">
                            <span>{{ item.section }}</span>
                        </template>
                        <template v-slot:[`item.content`]="{item}">
                            <p style="width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; margin: auto;">{{ item.content }}</p>
                        </template>
                    </v-data-table>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" :loading="loading" @click="postProduct">
                            <v-icon size="20" class="mr-2">mdi-content-save</v-icon>
                            <span>데이터베이스에 저장</span>
                            <template v-slot:[`loader`]>
                                <span>{{ postCount }} / {{ preitems.length }}...</span>
                            </template>
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <v-card class="mt-4">
                    <v-card-title class="subtitle-2 font-weight-bold">
                        <span>이미지 업로드(썸네일/기본보기/한폭보기/간지보기)</span>
                        <v-spacer />
                        <v-file-btn outlined color="blue" accept="image/*" multiple @change="files => preupload(files)">
                            <v-icon class="mr-2">mdi-image</v-icon>
                            <span>이미지 첨부</span>
                        </v-file-btn>
                    </v-card-title>
                    <v-divider />
                    <v-data-table :items="images" :headers="imagesHeaders">
                        <template v-slot:[`item.preview`]="{item}">
                            <v-img :src="createObjectURL(item)" width="150" height="90" contain />
                        </template>
                        <template v-slot:[`item.actions`]="{item}">
                            <v-icon size="20" @click="images.splice(images.indexOf(item), 1)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary" :loading="uploading" @click="upload">
                            <v-icon size="20" class="mr-2">mdi-content-save</v-icon>
                            <span>서버에 업로드하기</span>
                            <template v-slot:[`loader`]>
                                <span>{{ uploadCount }} / {{ images.length }}...</span>
                            </template>
                        </v-btn>
                    </v-card-actions>
                </v-card>

                <!-- <v-card class="mt-4">
                    <v-card-title class="subtitle-1">제품 이미지 파일</v-card-title>
                    <v-card-text>
                        <v-row class="pt-0 pb-0">
                            <v-col cols="auto" class="pt-2 pb-0">
                                <v-img v-if="images[0] || product.images[0]" :src="images[0] ? createObjectURL(images[0]) : product.images[0]" width="128" height="128"></v-img>
                                <v-responsive v-else width="128" height="128">
                                    <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                        <v-icon>mdi-image</v-icon>
                                    </v-row>
                                </v-responsive>
                            </v-col>
                            <v-col>
                                <v-file-input v-model="images" show-size dense accept="image/*" @change="file => postExcelImage(file)"></v-file-input>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card> -->

        </v-responsive>
    </v-layout>
</template>

<script>
import XLSX from "xlsx";
import draggable from "vuedraggable";
import VFileBtn from "@/components/vuetify/v-file-btn.vue";
import { mdiMicrosoftExcel } from "@mdi/js";
import download from "@/plugins/download";

export default {
    components: {
        draggable,
        VFileBtn
    },
    data: () => {
        return {
            ready: false,

            mdiMicrosoftExcel,

            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            preitems: [],
            preitemsHeaders: [
                { text: "1차카테고리*", value: "section", align: "center", width: 140 },
                { text: "2차카테고리*", value: "collection", align: "center", width: 140 },
                { text: "3차카테고리*", value: "category", align: "center", width: 140 },
                { text: "제품코드*", value: "code", align: "center", width: 140 },
                { text: "제품명_한글*", value: "name", align: "center", width: 140 },
                { text: "제품명_영문", value: "nameEn", align: "center", width: 140 },
                { text: "제품상세설명", value: "content", align: "center", width: 200 },
                { text: "썸네일_이미지", value: "thumb", align: "center", width: 200 },
                { text: "기본보기_이미지", value: "image0", align: "center", width: 200 },
                { text: "한폭보기_이미지", value: "image1", align: "center", width: 200 },
                { text: "간지보기_이미지", value: "image2", align: "center", width: 200 },
                { text: "기본보기_확대비율", value: "zoomBasic", align: "center", width: 140 },
                { text: "한폭보기_확대비율", value: "zoomHeight", align: "center", width: 140 },
                { text: "디자인", value: "design", align: "center", width: 140 },
                { text: "용도", value: "usage", align: "center", width: 140 },
                { text: "스타일", value: "style", align: "center", width: 200 },
                { text: "단가", value: "unitPrice", align: "center", width: 140 },
                { text: "종류", value: "kind", align: "center", width: 140 },
                { text: "타입", value: "type", align: "center", width: 140 },
                { text: "색상", value: "color", align: "center", width: 140 },
                { text: "사이즈", value: "size", align: "center", width: 140 },
                { text: "소재", value: "material", align: "center", width: 140 },
            ],

            loading: false,
            postCount: 0,

            ///////////////////////////////////////////////
            // 이미지 업로드
            ///////////////////////////////////////////////
            images: [],
            imagesHeaders: [
                { text: "이미지", value: "preview", align: "center", width: 100, sortable: false },
                { text: "파일이름", value: "name", align: "left" },
                { text: "-", value: "actions", align: "center", width: 100, sortable: false },
            ],

            uploading: false,
            uploadCount: 0,


            ///////////////////////////////////////////////
            // UI
            ///////////////////////////////////////////////
            categories: [],
            thumbs: [],
            file: null,
            wallpaper: null,
            excel: null,
            images: new Array(),
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        download,
        async init(){
            try{
                let { categories } = await this.$http.get('/api/console/shop/categories').then(res => res.data);
                this.categories = categories;

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        check(item){
            let errors = [];

            if(item.section != "컬렉션") errors.push(1);
            if(item.collection !== this.collections.find(collection => collection.name == item.collection)?.name) errors.push(2);
            if(item.category !== this.collections.find(collection => collection.name == item.collection)?.children?.find?.(category => category.name == item.category)?.name) errors.push(3);
            if(!item.code) errors.push(4);
            if(!item.name) errors.push(5);
            if(item.thumb && (item.thumb.indexOf(".jpg") < 0 && item.thumb.indexOf(".png") < 0)) errors.push(8);
            if(item.image0 && (item.image0.indexOf(".jpg") < 0 && item.image0.indexOf(".png") < 0)) errors.push(9);
            if(item.image1 && (item.image1.indexOf(".jpg") < 0 && item.image1.indexOf(".png") < 0)) errors.push(10);
            if(item.image2 && (item.image2.indexOf(".jpg") < 0 && item.image2.indexOf(".png") < 0)) errors.push(11);

            return errors.length ? errors : null;
        },

        toStyle(errors){
            return errors?.length ? `red lighten-4 ` + errors.map(no => `col-${no}-error`).join(' ') : "";
        },

        async postProduct(){
            for(let item of this.preitems){
                this.loading = true;
                this.postCount++;

                if(this.check(item)) continue;

                let collection = this.collections.find(collection => collection.name == item.collection);
                let category = collection?.children?.find?.(category => category.name == item.category);

                await this.$http.post('/api/console/shop/products', {
                    ...item,
                    _category: category._id,

                    price: 0,
                    cost: 0,
                    stock: 0,
                    sequence: this.postCount,
                    thumb: item.thumb ? `/res/shop/products/${item.thumb}` : null,
                    images: [item.image0, item.image1, item.image2].map(image => image ? `/res/shop/products/${image}` : ''),
                    contentFooter: null,

                    display: true,
                    sale: true,
                    best: false,
                    newly: false,
                    special: false,

                    zoomBasic: parseInt(item.zoomBasic),
                    zoomHeight: parseInt(item.zoomHeight),

                    fontType: "shadow",

                    hashtags: [],
                    icons: [],
                });
    
                // resolve(res.data.product);
            }
            
            this.$nextTick(() => {
                if(this.preitems.length) alert("저장됐습니다.");
                this.loading = false;
                this.postCount = 0;
            })
        },

        preload(wallpaperFile) { //엑셀 파일 변환
            console.log(wallpaperFile);
            if (!wallpaperFile) return;


            this.preitems = [];

            let reader = new FileReader();
            reader.onload = () => {
                var workSheets = [];
                var workBook = XLSX.read(reader.result, { type: "binary" });
                workBook.SheetNames.forEach(sheetName => {
                    let sheet = XLSX.utils.sheet_to_json(
                        workBook.Sheets[sheetName]
                    );
                    workSheets.push(sheet);
                });

                let [workSheet] = workSheets;
                for(let i = 0; i < workSheet.length; i++){
                    this.preitems.push({
                        "section": String(workSheet[i]["1차카테고리*"] || '').trim(),
                        "collection": String(workSheet[i]["2차카테고리*"] || '').trim(),
                        "category": String(workSheet[i]["3차카테고리*"] || '').trim(),
                        
                        "code": String(workSheet[i]["제품코드*"] || '').trim(),
                        "name": String(workSheet[i]["제품명_한글*"] || '').trim(),
                        "nameEn": String(workSheet[i]["제품명_영문"] || '').trim(),
                        "content": String(workSheet[i]["제품상세설명"] || '').trim(),

                        "thumb": String(workSheet[i]["썸네일_이미지"] || ''),
                        "image0": String(workSheet[i]["기본보기_이미지"] || '').trim(),
                        "image1": String(workSheet[i]["한폭보기_이미지"] || '').trim(),
                        "image2": String(workSheet[i]["간지보기_이미지"] || '').trim(),
                        "zoomBasic": parseInt(workSheet[i]["기본보기_확대비율"] || '1') * 100 + "%",
                        "zoomHeight": parseInt(workSheet[i]["한폭보기_확대비율"] || '1') * 100 + "%",
                        
                        "design": String(workSheet[i]["디자인"] || '').trim(),
                        "usage": String(workSheet[i]["용도"] || '').trim(),
                        "style": String(workSheet[i]["스타일"] || '').trim(),
                        "unitPrice": String(workSheet[i]["단가"] || '').trim(),
                        "kind": String(workSheet[i]["종류"] || '').trim(),
                        "type": String(workSheet[i]["타입"] || '').trim(),
                        "color": String(workSheet[i]["색상"] || '').trim(),
                        "size": String(workSheet[i]["사이즈"] || '').trim(),
                        "material": String(workSheet[i]["소재"] || '').trim(),
                    });
                }
            };
            reader.readAsBinaryString(wallpaperFile);
        },

        preupload(files){
            this.images.push(...files);
        },

        uploadThumb(files) { // 이미지 갯수 늘리기
            this.product.thumbs = [...files, ...this.product.thumbs];
        },

        async upload() { // 이미지 갯수 늘리기
            for(let image of this.images){
                this.uploading = true;
                this.uploadCount++;

                let formData = new FormData();
                formData.append("image", image);
                await this.$http.post("/api/console/shop/products/excelImg", formData, {
                    headers: { 'Content-Type': 'multipart/form-data' }
                });
            }

            if(this.images.length) alert("업로드 되었습니다");
            this.uploading = false;
            this.uploadCount = 0;
        },

        async postExcelThumbImage(){ // 썸네일 이미지 저장
            for(let img of this.product.thumbs) {
                var data = new FormData();
                data.append('image', img);
                await this.$http.post(`/api/console/shop/products/excelThumbImg`, data, {
                    headers: {
                        'Content-Type' : 'multipart/form-data'
                    }
                });
            }
            alert("저장됐습니다.");
            this.$router.go(-1)
        },

        async postExcelImage(){ // 썸네일 이미지 저장
            for(let img of this.product.images) {
                var data = new FormData();
                data.append('image', img);

                await this.$http.post(`/api/console/shop/products/excelImg` , data, {
                    headers: {
                        'Content-Type' : 'multipart/form-data'
                    }
                });
            }
            alert("저장됐습니다.");
            this.$router.go(-1)
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
    computed: {
        collections(){
            return this.categories?.[0]?.children || [];
        }
         
    },
    watch: {
    }

}
</script>

<style>
.shop-product-view .weight input { text-align: end; }
.shop-product-view .amount input { text-align: end; }
.shop-product-view .theme--light.v-tabs-items { background-color: transparent !important; }
.shop-product-view .option-groups-headers th { border-bottom: thin solid rgba(0,0,0,.12) }

</style>

<style scoped>
.preitems >>> .col-1-error > td:nth-child(1),
.preitems >>> .col-2-error > td:nth-child(2),
.preitems >>> .col-3-error > td:nth-child(3),
.preitems >>> .col-4-error > td:nth-child(4),
.preitems >>> .col-5-error > td:nth-child(5),
.preitems >>> .col-6-error > td:nth-child(6),
.preitems >>> .col-7-error > td:nth-child(7),
.preitems >>> .col-8-error > td:nth-child(8),
.preitems >>> .col-9-error > td:nth-child(9),
.preitems >>> .col-10-error > td:nth-child(10),
.preitems >>> .col-11-error > td:nth-child(11) {
    background: red;
    color: white;
}
</style>
