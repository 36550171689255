var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.ready ? _c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    staticClass: "shop-product-view overflow-visible",
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('div', {
    staticClass: "headline text-start mt-8 mb-4"
  }, [_vm._v("제품 파일 업로드")]), _c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("벽지 엑셀 파일")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "outlined": ""
    },
    on: {
      "click": function ($event) {
        return _vm.download(`/assets/excel/didwallpaper_upload.xlsx`, `didwallpaper_upload.xlsx`);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiMicrosoftExcel))]), _c('span', [_vm._v("엑셀 양식 다운로드")])], 1), _c('v-file-btn', {
    staticClass: "ml-2",
    attrs: {
      "outlined": "",
      "color": "blue",
      "accept": ".xlsx"
    },
    on: {
      "change": function (file) {
        return _vm.preload(file);
      }
    },
    model: {
      value: _vm.wallpaper,
      callback: function ($$v) {
        _vm.wallpaper = $$v;
      },
      expression: "wallpaper"
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiMicrosoftExcel))]), _c('span', [_vm._v("벽지 엑셀 업로드")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('div', {
    staticClass: "caption primary--text"
  }, [_c('b', [_vm._v("※ 주의사항")])]), _c('div', {
    staticClass: "caption mt-1"
  }, [_c('b', [_vm._v("* 카테고리는 선행 등록이 되어있어야 합니다. "), _c('router-link', {
    attrs: {
      "to": "/console/shop/categories"
    }
  }, [_vm._v("[컬렉션 & 텍스쳐 관리]")]), _vm._v("에서 설정 할 수 있습니다.")], 1)]), _c('div', {
    staticClass: "caption mt-1"
  }, [_c('b', [_vm._v("* [썸네일, 기본보기, 한폭보기, 간지보기] 이미지 파일 이름이 같으면, 이미지가 동일한 현상이 발생하므로 다르게 설정해주시기 바랍니다.")])]), _c('div', {
    staticClass: "caption mt-1"
  }, [_c('b', [_vm._v("* 작성 오류가 있는 행은 빨간색으로 표시됩니다. 빨간 행은 업로드 되지 않습니다.")])])]), _c('v-divider'), _c('v-data-table', {
    staticClass: "preitems",
    attrs: {
      "items": _vm.preitems,
      "headers": _vm.preitemsHeaders,
      "item-class": function (item) {
        return _vm.toStyle(_vm.check(item));
      },
      "disable-sort": ""
    },
    scopedSlots: _vm._u([{
      key: `item.section`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s(item.section))])];
      }
    }, {
      key: `item.content`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('p', {
          staticStyle: {
            "width": "200px",
            "white-space": "nowrap",
            "overflow": "hidden",
            "text-overflow": "ellipsis",
            "margin": "auto"
          }
        }, [_vm._v(_vm._s(item.content))])];
      }
    }], null, true)
  }), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": _vm.postProduct
    },
    scopedSlots: _vm._u([{
      key: `loader`,
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.postCount) + " / " + _vm._s(_vm.preitems.length) + "...")])];
      },
      proxy: true
    }], null, true)
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-content-save")]), _c('span', [_vm._v("데이터베이스에 저장")])], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-2 font-weight-bold"
  }, [_c('span', [_vm._v("이미지 업로드(썸네일/기본보기/한폭보기/간지보기)")]), _c('v-spacer'), _c('v-file-btn', {
    attrs: {
      "outlined": "",
      "color": "blue",
      "accept": "image/*",
      "multiple": ""
    },
    on: {
      "change": function (files) {
        return _vm.preupload(files);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-image")]), _c('span', [_vm._v("이미지 첨부")])], 1)], 1), _c('v-divider'), _c('v-data-table', {
    attrs: {
      "items": _vm.images,
      "headers": _vm.imagesHeaders
    },
    scopedSlots: _vm._u([{
      key: `item.preview`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-img', {
          attrs: {
            "src": _vm.createObjectURL(item),
            "width": "150",
            "height": "90",
            "contain": ""
          }
        })];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-icon', {
          attrs: {
            "size": "20"
          },
          on: {
            "click": function ($event) {
              _vm.images.splice(_vm.images.indexOf(item), 1);
            }
          }
        }, [_vm._v("mdi-delete")])];
      }
    }], null, true)
  }), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "primary",
      "loading": _vm.uploading
    },
    on: {
      "click": _vm.upload
    },
    scopedSlots: _vm._u([{
      key: `loader`,
      fn: function () {
        return [_c('span', [_vm._v(_vm._s(_vm.uploadCount) + " / " + _vm._s(_vm.images.length) + "...")])];
      },
      proxy: true
    }], null, true)
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "size": "20"
    }
  }, [_vm._v("mdi-content-save")]), _c('span', [_vm._v("서버에 업로드하기")])], 1)], 1)], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }