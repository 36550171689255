<template>
    <v-layout justify-center>
        <v-responsive max-width="1980px" width="100%" class="px-0 px-md-4 px-xl-8">
            <v-row class="mt-0 mt-md-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">주문 관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="mt-4" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate" label="주문일자" placeholder=" " append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate); filter.createDate[0] = filter.startDate ? new Date(`${filter.startDate} 00:00:00.000`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate" label="주문일자" placeholder=" " append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate); filter.createDate[1] = filter.endDate ? new Date(`${filter.endDate} 23:59:59.999`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" dense hide-details label="상세조건" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.searchValue" dense hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-spacer></v-spacer>
                <v-col cols="auto">
                    <v-btn color="white" class="green--text px-2" @click="getExcelPurchases" ><v-icon class="mr-2">{{ mdiMicrosoftExcel }}</v-icon>엑셀다운로드</v-btn>
                </v-col>
            </v-row>

            <v-data-table :headers="headers" :items="purchases" disable-sort disable-pagination hide-default-footer class="elevation-1">
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDateTime() }}
                </template>
                <template v-slot:item.order.sender="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order.sender.email }}</div>
                </template>
                <template v-slot:item.order.receiver="{ item }">
                    <div>{{ item.order.receiver.name }}</div>
                    <div>{{ item.order.receiver.phone }}</div>
                    <div>{{ item.order.receiver.email }}</div>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-card v-show="false" class="mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">주문관리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn text color="primary" >발주/발송처리</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >발송지연 처리</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >배송지 정보 수정</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >미결제 확인</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">취소관리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn text color="primary" >판매취소 처리</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >취소완료처리</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >구매확정 후 취소처리</v-btn>
                        </v-col>
                    </v-row>
                    <v-divider class="my-4"></v-divider>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">반품교환 관리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn text color="primary" >반품 및 교환접수</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >반품접수 후 처리</v-btn>
                            <v-icon>mdi-chevron-right</v-icon>
                            <v-btn text color="primary" >교환접수 후 처리</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
import XLSX from 'xlsx';

import { mdiMicrosoftExcel } from '@mdi/js';

export default {
    data: () => {
        return {
            mdiMicrosoftExcel,

            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null,
                createDate: [null, null]
            },
            searchKeys: [
                { text: "전체", value: null },
                { text: "수취인명", value: "receiver.name" },
                { text: "구매자명", value: "sender.name" },
                { text: "구매자연락처", value: "sender.phone" },
                { text: "구매자ID", value: "sender.username" },
                { text: "주문번호", value: "orderNo" },
                { text: "상품주문번호", value: "purchaseNo" },
            ],

            ///////////////////////////////////////////////
            // 주문목록
            ///////////////////////////////////////////////
            purchases: [],

            ///////////////////////////////////////////////
            // 주문목록 테이블 정보
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 10,
            headers: [{
                text: '상품주문번호',
                align: 'center',
                width: 50,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 50,
                value: 'order.orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 140,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 100,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 100,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'order.sender.phone'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 200,
                value: 'product.name'
            },
            {
                text: '옵션정보',
                align: 'center',
                width: 200,
                value: 'name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'order.sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'order.receiver'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getPurchases();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.repsonse.data.message);
            }
        },
        async search(){
            try{
                await this.getPurchases();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.repsonse.data.message);
            }
        },
        getPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/purchases', {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: this.filter
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.purchases = res.data.purchases;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getExcelPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/purchases', {
                        headers: {
                            skip: 0,
                            limit: 0
                        },
                        params: this.filter
                    });

                    var excelData = [];
                    res.data.purchases.forEach((purchase) => {
                        excelData.push({
                            "상품주문번호": purchase.purchaseNo,
                            "주문번호": purchase.order.orderNo,
                            "구매자명": purchase.order.sender.name,
                            "구매자ID": purchase.user ? purchase.user.username : "비회원",
                            "수취인명": purchase.order.receiver.name,
                            "주문상태": purchase.orderStatusMessage,
                            "주문세부상태": purchase.claimStatusMessage,
                            "결제일": purchase.paymentDate ? purchase.paymentDate.toDateTime() : purchase.createDate.toDateTime(),
                            "배송방법": purchase.delivery.method,
                            "택배사": purchase.delivery.name,
                            "송장번호": purchase.delivery.number,
                            "발송일": purchase.shippingDate,
                            "상품번호": purchase._product,
                            "상품명": purchase.product.name,
                            "상품종류": '',
                            "옵션정보": purchase.name,
                            "수량": purchase.amount,
                            "옵션가격": purchase.price,
                            "상품가격": purchase.product.price,
                            "상품별 할인액": purchase.discountPrice,
                            "판매자 부담 할인액": '',
                            "상품별 총 주문금액": purchase.salePrice,
                            "사은품": '',
                            "발주확인일": purchase.orderConfirmDate,
                            "발송기한": '',
                            "발송처리일": purchase.shippingConfirmDate,
                            "송장출력일": '',
                            "배송비 형태": '',
                            "배송비 묶음번호": '',
                            "배송비 유형": '',
                            "배송비 합계": purchase.deliveryPrice,
                            "지역별 배송비": purchase.islandPrice,
                            "배송비 할인액": '',
                            "판매자 상품코드": '',
                            "수취인연락처1": purchase.order.receiver.phone,
                            "수취인연락처2": '',
                            "배송지": `${purchase.order.receiver.address1} ${purchase.order.receiver.address2}`,
                            "구매자연락처": purchase.order.sender.phone,
                            "우편번호": purchase.order.receiver.postcode,
                            "배송메세지": purchase.delivery.message,
                            "출고지": '',
                            "결제수단": purchase.order.paymentMethod,
                            "유입경로": '',
                            "3개월 주문건수": '',
                            "6개월 주문건수": '',
                        })
                    });

                    var workbook = new XLSX.utils.book_new();
                    var worksheet = XLSX.utils.json_to_sheet(excelData);

                    XLSX.utils.book_append_sheet(workbook, worksheet, "new");
                    XLSX.writeFile(workbook, "주문목록.xlsx");

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
