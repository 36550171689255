<template>
    <v-app class="content" :style="themeStyle">
        <console-app-bar v-model="drawer" title="디아이디 관리자" color="info"></console-app-bar>
        <console-drawer v-model="drawer"></console-drawer>

        <v-content>
            <v-container fluid fill-height>
                <component v-if="$route.name" :is="($route.name == 'console') ? 'console-dashboard' : $route.name"/>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import ConsoleShopExcel from '../console/shop/product/shop-excel-view.vue'
////////////////////////////////////////////////////////////////////////////////
// 콘솔 UI 관련 컴포넌트
////////////////////////////////////////////////////////////////////////////////
import ConsoleAppBar from './console-app-bar.vue';
import ConsoleDrawer from './console-drawer.vue';

////////////////////////////////////////////////////////////////////////////////
// 대시보드
////////////////////////////////////////////////////////////////////////////////
import ConsoleDashboard from './console-dashboard.vue';

////////////////////////////////////////////////////////////////////////////////
// 사용자 정보
////////////////////////////////////////////////////////////////////////////////
import ConsoleUsers from './users/console-users.vue';
import ConsoleUserLevels from './users/console-user-levels.vue';
import ConsoleUserWithdraw from './users/console-user-withdraw.vue';

////////////////////////////////////////////////////////////////////////////////
// 게시판 정보
////////////////////////////////////////////////////////////////////////////////
import ConsoleBoards from './boards/console-boards.vue';

////////////////////////////////////////////////////////////////////////////////
// 쇼핑몰 정보
////////////////////////////////////////////////////////////////////////////////
import ConsoleShopProducts from './shop/console-shop-products.vue';
import ConsoleShopCategories from './shop/console-shop-categories.vue';
import ConsoleShopCoupons from './shop/console-shop-coupons.vue';
import ConsoleShopPoints from './shop/console-shop-points.vue';
import ConsoleShopBrands from './shop/console-shop-brands.vue';
import ConsoleShopOrders from './shop/console-shop-orders.vue';
import ConsoleShopInquire from './shop/console-shop-inquire.vue';
import ConsoleShopReviews from './shop/console-shop-reviews.vue';
import ConsoleShopPolicies from './shop/console-shop-policies.vue';
import ConsoleShopSetting from './shop/console-shop-setting.vue';

////////////////////////////////////////////////////////////////////////////////
// 통계관리
////////////////////////////////////////////////////////////////////////////////
import ConsoleStatistics from './statistics/console-statistics.vue';

////////////////////////////////////////////////////////////////////////////////
// 고객센터
////////////////////////////////////////////////////////////////////////////////
import ConsoleNotification from './center/console-notification.vue';
import ConsoleQuestion from './center/console-question.vue';
import ConsoleFaq from './center/console-faq.vue';

////////////////////////////////////////////////////////////////////////////////
// 이용약관
////////////////////////////////////////////////////////////////////////////////
import ConsoleTerms from './terms/console-terms.vue';

////////////////////////////////////////////////////////////////////////////////
// 배너관리
////////////////////////////////////////////////////////////////////////////////
import ConsoleBanners from './banners/console-banners.vue';

////////////////////////////////////////////////////////////////////////////////
// 팝업관리
////////////////////////////////////////////////////////////////////////////////
import ConsolePopups from './popups/console-popups.vue';

////////////////////////////////////////////////////////////////////////////////
// 자사정보
////////////////////////////////////////////////////////////////////////////////
import ConsoleSetting from './setting/console-setting.vue';

////////////////////////////////////////////////////////////////////////////////
// 유튜브 관리
////////////////////////////////////////////////////////////////////////////////
import ConsoleYoutube from './youtube/console-youtube.vue';

export default {
    components: {
        ConsoleShopExcel,
        ////////////////////////////////////////////////////////////////////////////////
        // 콘솔 UI 관련 컴포넌트
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleAppBar,
        ConsoleDrawer,

        ////////////////////////////////////////////////////////////////////////////////
        // 대시보드
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleDashboard,

        ////////////////////////////////////////////////////////////////////////////////
        // 사용자 정보
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleUsers,
        ConsoleUserLevels,
        ConsoleUserWithdraw,

        ////////////////////////////////////////////////////////////////////////////////
        // 게시판 정보
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleBoards,

        ////////////////////////////////////////////////////////////////////////////////
        // 쇼핑몰 정보
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleShopProducts,
        ConsoleShopCategories,
        ConsoleShopCoupons,
        ConsoleShopPoints,
        ConsoleShopBrands,
        ConsoleShopOrders,
        ConsoleShopInquire,
        ConsoleShopReviews,
        ConsoleShopPolicies,
        ConsoleShopSetting,

        ////////////////////////////////////////////////////////////////////////////////
        // 통계관리
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleStatistics,

        ////////////////////////////////////////////////////////////////////////////////
        // 고객센터
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleNotification,
        ConsoleQuestion,
        ConsoleFaq,

        ////////////////////////////////////////////////////////////////////////////////
        // 이용약관
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleTerms,

        ////////////////////////////////////////////////////////////////////////////////
        // 배너관리
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleBanners,

        ////////////////////////////////////////////////////////////////////////////////
        // 팝업관리
        ////////////////////////////////////////////////////////////////////////////////
        ConsolePopups,

        ////////////////////////////////////////////////////////////////////////////////
        // 자사정보
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleSetting,

        ////////////////////////////////////////////////////////////////////////////////
        // 유튜브관리
        ////////////////////////////////////////////////////////////////////////////////
        ConsoleYoutube
    },
    data: () => {
        return {
            drawer: null,
        };
    },
    created(){
        console.log(this.scope);
        if(this.$route.name == "console" && this.scope.includes("shop")){
            window.location.href = "/console/boards/interior-shop";
        }
    },
    computed: {
        themeStyle(){
            return {
                background: this.$vuetify.theme.themes[this.$vuetify.theme.isDark ? "dark" : "light"].content
            };
        },
        payload(){
            return this.$store.state.payload || {};
        },
        scope(){
            return this.payload.scope || [];
        }
    }
}
</script>
