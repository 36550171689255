var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app', {
    staticClass: "content",
    style: _vm.themeStyle
  }, [_c('console-app-bar', {
    attrs: {
      "title": "디아이디 관리자",
      "color": "info"
    },
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }), _c('console-drawer', {
    model: {
      value: _vm.drawer,
      callback: function ($$v) {
        _vm.drawer = $$v;
      },
      expression: "drawer"
    }
  }), _c('v-content', [_c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_vm.$route.name ? _c(_vm.$route.name == 'console' ? 'console-dashboard' : _vm.$route.name, {
    tag: "component"
  }) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }