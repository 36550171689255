<template>
<v-container fluid fill-height class="console-terms">
    <v-layout justify-center>
        <v-responsive max-width="1280px" width="100%">
            <v-row class="mt-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">YouTube 관리</v-col>
                <v-spacer></v-spacer>
                <v-col v-show="false" cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-data-table :headers="youtubesHeaders" :items="youtubes" disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.no="{ item }">
                    <span>{{ (page - 1)*limit + youtubes.indexOf(item) + 1  }}</span>
                </template>
                <template v-slot:item.createDate="{ item }">
                    {{ item.createDate.toDate() }}
                </template>
                <template v-slot:item.thumb="{ item }">
                    <v-img :src="`https://img.youtube.com/vi/${item.code}/default.jpg`"></v-img>
                </template>
                <template v-slot:item.visibility="{ item }">
                    <v-row justify="center">
                        <v-col cols="auto" class="pa-0">
                            <v-switch v-model="item.visibility" hide-details class="ma-0" @change="save({ _id: item._id, visibility: item.visibility })"></v-switch>
                        </v-col>
                    </v-row>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="edit(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="remove(item)">
                        mdi-delete
                    </v-icon>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search()"></v-pagination>

            <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn bottom color="accent" dark fab fixed right @click="create">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </template>
                <v-card v-if="editItem">
                    <v-card-title v-if="editItem._id" class="subtitle-1">유튜브 수정</v-card-title>
                    <v-card-title v-else class="subtitle-1">유튜브 등록</v-card-title>
                    <v-card-text>
                        <v-text-field v-model="editItem.url" label="유튜브 URL" placeholder="http://youtu.be/example" hide-details @input="matchCode"></v-text-field>
                        <v-text-field v-model="editItem.code" label="유튜브 CODE(자동)" placeholder="example" hide-details class="mt-4"></v-text-field>
                        <v-text-field v-model="editItem.sequence" label="순서" placeholder=" " hide-details class="mt-4"/>
                        <v-row align="center">
                            <v-col cols="auto">숨김/보임</v-col>
                            <v-spacer/>
                            <v-col cols="auto">
                                <v-switch v-model="editItem.visibility" hide-details class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                        
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="close">취소</v-btn>
                        <v-btn color="primary" text @click="save(editItem)">저장</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-responsive>
    </v-layout>
</v-container>
</template>

<script>
export default {
    created() {
        this.init();
    },
    data: () => {
        return {
            showSearch: false,
            dialog: false,

            page: 1,
            pageCount: 0,
            limit: 10,

            youtubes: [],
            youtubesHeaders: [{
                    text: 'no',
                    align: 'start',
                    value: 'sequence',
                },
                {
                    text: "썸네일",
                    align: "center",
                    value: "thumb"
                },
                {
                    text: "YouTube Code",
                    align: "center",
                    value: "code"
                },
                {
                    text: 'YouTube URL',
                    align: 'center',
                    value: 'url'
                },
                {
                    text: "숨김/보임",
                    algin: "center",
                    width: 90,
                    value: "visibility"
                },
                {
                    text: '등록일자',
                    align: 'center',
                    width: 120,
                    value: 'createDate'
                },
                {
                    text: 'Actions',
                    align: 'center',
                    width: 100,
                    value: 'actions'
                }
            ],

            editItem: null,

            defaultItem: {
                _id: null,
                url: null,
                code: null,
                visibility: true,
                sequence: 1
            }
        };
    },
    methods: {
        async init() {
            try {
                this.search(false);
            } catch (error) {
                console.error(error);
            }
        },

        async search(routable = true){
            try{
                if(routable){
                    this.$router.push({
                        query: Object.assign({}, this.filter, { page: this.page })
                    });
                }

                var { summary, youtubes } = await this.getYoutubes({
                    headers: {
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit
                    }
                });

                this.youtubes = youtubes;
                this.pageCount = Math.ceil(summary.totalCount / this.limit);
                this.defaultItem.sequence = summary.totalCount + 1;
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        close() {
            this.dialog = false;
        },

        create() {
            this.editItem = Object.assign({}, this.defaultItem);
            this.dialog = true;
        },

        edit(notification) {
            this.editItem = Object.assign({}, notification);
            this.dialog = true;
        },

        matchCode(){
            var match = (this.editItem.url || '').match(/(embed|youtu.be)\/([a-zA-Z0-9\-_]+)/) || [];
            this.editItem.code = match.pop();
        },

        async save(youtube) {
            try {
                youtube._id ? await this.putYoutube(youtube) : await this.postYoutube(youtube);
                await this.search(false);
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
            this.dialog = false;
        },

        async remove(youtube) {
            try {
                if(confirm("유튜브를 삭제하시겠습니까?")){
                    await this.deleteYoutube(youtube);
                    await this.search(false);
                }
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },

        getYoutubes(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/youtubes");

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        postYoutube(youtube){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post("/api/console/youtubes", youtube);

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        putYoutube(youtube){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/youtubes/${youtube._id}`, youtube);

                    resolve(res.data);
                }
                catch(error){
                    reject(error);
                }
            });
        },
        deleteYoutube(youtube){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.delete(`/api/console/youtubes/${youtube._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
