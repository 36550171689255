<template>
    <v-container fluid fill-height>
        <shop-order-default v-if="$route.query.manage === undefined"></shop-order-default>

        <shop-order-nonpayment v-if="$route.query.manage == 'nonpayment'"></shop-order-nonpayment>
        <shop-order-payment v-if="$route.query.manage == 'payment'"></shop-order-payment>
        <shop-order-delivery v-if="$route.query.manage == 'delivery'"></shop-order-delivery>

        <shop-order-cancel v-if="$route.query.manage == 'cancel'"></shop-order-cancel>
        <shop-order-exchange v-if="$route.query.manage == 'exchange'"></shop-order-exchange>
        <shop-order-return v-if="$route.query.manage == 'return'"></shop-order-return>
    </v-container>
</template>

<script>
import ShopOrderDefault from './order/shop-order-default.vue';

import ShopOrderNonpayment from './order/shop-order-nonpayment.vue';
import ShopOrderPayment from './order/shop-order-payment.vue';
import ShopOrderDelivery from './order/shop-order-delivery.vue';

import ShopOrderCancel from './order/shop-order-cancel.vue';
import ShopOrderExchange from './order/shop-order-exchange.vue';
import ShopOrderReturn from './order/shop-order-return.vue';

export default {
    components: {
        ShopOrderDefault,

        ShopOrderNonpayment,
        ShopOrderPayment,
        ShopOrderDelivery,

        ShopOrderCancel,
        ShopOrderExchange,
        ShopOrderReturn
    },
}
</script>
