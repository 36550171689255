<template>
<v-card class="mt-4">
    <v-card-title class="subtitle-1">관련제품</v-card-title>
    <v-card-text v-if="relativeProducts.length" style="display: flex; flex: 1 1; flex-wrap: wrap;">
        <v-card v-for="(product, index) in relativeProducts" :key="index" class="mr-2 mb-2">
            <v-card-title class="body-1 py-0">
                <v-row class="px-0">
                    <v-list-item-avatar rounded>
                        <v-img :src="product.thumb"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        {{ `[${product.code}] ${product.name}` }}
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon @click="relativeProducts.splice(index, 1)">mdi-close</v-icon>
                    </v-list-item-action>
                </v-row>
            </v-card-title>
        </v-card>
    </v-card-text>
    <v-divider />
    <v-card-actions>
        <v-row align="center">
            <v-col>
                <v-autocomplete v-model="selected" dense hide-details multiple :loading="productLoading" :items="products" item-text="combinedCodeName" item-value="_id" :search-input.sync="searchValue" label="제품이름 또는 코드로 검색" placeholder=" ">
                    <template v-slot:selection="{ attr, on, item, selected }">
                        <v-card class="mr-2 mb-2">
                            <v-card-title class="body-1 py-0">
                                <v-row class="px-0">
                                    <v-list-item-avatar rounded>
                                        <v-img :src="item.thumb"></v-img>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        {{ `[${item.code}] ${item.name}` }}
                                    </v-list-item-content>
                                </v-row>
                            </v-card-title>
                        </v-card>
                    </template>
                    <template v-slot:item="{ item }">
                        <v-list-item-avatar rounded>
                            <v-img :src="item.thumb"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            {{ `[${item.code}] ${item.name}` }}
                        </v-list-item-content>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="auto" class="py-0 pl-0">
                <v-btn color="primary" @click="insert">
                    <v-icon class="mr-2">mdi-plus</v-icon>추가
                </v-btn>
            </v-col>
        </v-row>
    </v-card-actions>
</v-card>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: null
        }
    },
    data: () => {
        return {
            relativeProducts: [],

            searchValue: null,
            productLoading: false,
            selected: [],
            products: []
        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                var {
                    relativeProducts
                } = this.value;

                Object.assignDefined(this.$data, {
                    relativeProducts,
                });
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        input() {
            this.$set(this.value, "relativeProducts", this.relativeProducts);

            this.$emit('input', this.value);
        },
        insert() {
            this.selected.forEach((_product) => {
                var find = this.relativeProducts.find((product) => product._id == _product);
                if (!find) this.relativeProducts.push(this.products.find((product) => product._id == _product));
            })
            this.selected = [];
            this.input();
        },

        getProducts(params) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get("/api/console/shop/products", {
                        params
                    });

                    resolve(res.data.products);
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
    watch: {
        searchValue() {
            if (0 < this.products.length) return;

            this.productLoading = true;
            this.getProducts({
                searchKey: "name|code",
                searchValue: this.searchValue
            }).then((products) => {
                this.products = products;
                this.productLoading = false;
            });
        },
    }
}
</script>
