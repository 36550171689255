var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1024px",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-4"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "color": "info lighten-1",
      "dense": ""
    }
  }, [_c('v-toolbar-title', {
    staticClass: "white--text subtitle-2"
  }, [_vm._v("카테고리")]), _c('v-spacer'), _c('v-dialog', {
    attrs: {
      "width": "560"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('v-fab-transition', [_c('v-btn', _vm._g({
          attrs: {
            "color": "accent",
            "fab": "",
            "dark": "",
            "small": "",
            "absolute": "",
            "bottom": "",
            "right": ""
          }
        }, on), [_c('v-icon', [_vm._v("mdi-plus")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.dialog ? _c('v-card', [_c('v-card-title', {
    staticClass: "subtitle-1",
    attrs: {
      "primary-title": ""
    }
  }, [_vm.editItem.depth == 1 ? _c('span', [_vm._v("카테고리 등록")]) : _vm._e(), _vm.editItem.depth == 2 ? _c('span', [_vm._v("신규 컬렉션 등록")]) : _vm._e(), _vm.editItem.depth == 3 ? _c('span', [_vm._v("신규 텍스처 등록")]) : _vm._e()]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('shop-category-select', {
    attrs: {
      "categories": _vm.categories,
      "max": 2
    },
    on: {
      "change": function (_ref2) {
        var _category = _ref2._category,
          category = _ref2.category;
        Object.assign(_vm.editItem, {
          _parents: category._parents.concat(_category),
          depth: category.depth + 1
        });
      }
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.name,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "name", $$v);
      },
      expression: "editItem.name"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 코드",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm.editItem.depth == 1 ? _c('span', [_vm._v("카테고리 썸네일 이미지")]) : _vm._e(), _vm.editItem.depth == 2 ? _c('span', [_vm._v("컬렉션 썸네일 이미지")]) : _vm._e(), _vm.editItem.depth == 3 ? _c('span', [_vm._v("텍스쳐 썸네일 이미지")]) : _vm._e()]), _c('v-row', [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb || _vm.editItem.thumb ? _c('v-img', {
    attrs: {
      "width": "96",
      "src": _vm.thumb ? _vm.createObjectURL(_vm.thumb) : _vm.editItem.thumb,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": "thumbnail",
      "placeholder": " "
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1)], 1)], 1)], 1), _vm.editItem.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('div', {
    staticClass: "caption"
  }, [_c('span', [_vm._v("컬렉션 이미지")])]), _c('v-row', [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.image || _vm.editItem.image ? _c('v-img', {
    attrs: {
      "width": "96",
      "src": _vm.image ? _vm.createObjectURL(_vm.image) : _vm.editItem.image,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": "image",
      "placeholder": " "
    },
    model: {
      value: _vm.image,
      callback: function ($$v) {
        _vm.image = $$v;
      },
      expression: "image"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.editItem.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "컬렉션 이미지 사이드 문구",
      "placeholder": " ",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.editItem.sideText,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "sideText", $$v);
      },
      expression: "editItem.sideText"
    }
  })], 1)], 1) : _vm._e(), _vm.editItem.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-textarea', {
    attrs: {
      "label": "컬렉션 설명",
      "placeholder": " ",
      "rows": "2",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.editItem.summary,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "summary", $$v);
      },
      expression: "editItem.summary"
    }
  })], 1)], 1) : _vm._e(), _vm.editItem.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-textarea', {
    attrs: {
      "label": "컬렉션 하단 설명",
      "placeholder": " ",
      "rows": "2",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.editItem.details,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "details", $$v);
      },
      expression: "editItem.details"
    }
  })], 1)], 1) : _vm._e(), _vm.editItem.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("카테고리 유형")]), _c('v-radio-group', {
    staticClass: "ma-0",
    attrs: {
      "row": ""
    },
    model: {
      value: _vm.editItem.type,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "type", $$v);
      },
      expression: "editItem.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "내수용",
      "value": "내수용"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "수출용",
      "value": "수출용"
    }
  })], 1)], 1), _c('v-col', {
    staticClass: "py-0"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("친환경인증 표기")]), _c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "inset": "",
      "label": _vm.editItem.isEcoFriendly ? '표기' : '미표기'
    },
    model: {
      value: _vm.editItem.isEcoFriendly,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "isEcoFriendly", $$v);
      },
      expression: "editItem.isEcoFriendly"
    }
  })], 1)], 1) : _vm._e(), _c('v-row', {
    staticClass: "mt-3",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('span', [_vm._v("검색엔진최적화(SEO)")]), _vm._v(" "), _c('span', [_vm._v("(사용/미사용)")])]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "pa-0 ma-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.seoEnabled,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "seoEnabled", $$v);
      },
      expression: "editItem.seoEnabled"
    }
  })], 1)], 1), _vm.editItem.seoEnabled ? _c('v-row', [_c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-3",
    attrs: {
      "label": "타이틀: Title",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.title,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo, "title", $$v);
      },
      expression: "editItem.seo.title"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Author",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'author';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'author';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'author').content"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Description",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'description';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'description';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'description').content"
    }
  })], 1), _c('v-col', {
    staticClass: "py-3 py-md-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    staticClass: "mt-6",
    attrs: {
      "label": "메타태그: Keywords",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    on: {
      "input": _vm.input
    },
    model: {
      value: _vm.editItem.seo.meta.find(function (meta) {
        return meta.name == 'keywords';
      }).content,
      callback: function ($$v) {
        _vm.$set(_vm.editItem.seo.meta.find(function (meta) {
          return meta.name == 'keywords';
        }), "content", $$v);
      },
      expression: "editItem.seo.meta.find((meta) => meta.name == 'keywords').content"
    }
  })], 1)], 1) : _vm._e()], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.save
    }
  }, [_vm._v("등록")])], 1)], 1) : _vm._e()], 1)], 1), _c('v-card-text', [_vm.categories.length ? _c('v-draggable-treeview', {
    attrs: {
      "item-key": "_id",
      "children": "children",
      "group": "categories",
      "dense": ""
    },
    on: {
      "click": _vm.select
    },
    model: {
      value: _vm.categories,
      callback: function ($$v) {
        _vm.categories = $$v;
      },
      expression: "categories"
    }
  }) : _c('span', [_vm._v("등록된 카테고리가 없습니다."), _c('br'), _vm._v("카테고리를 등록해주세요")])], 1)], 1), _c('v-row', [_c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  })], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": !_vm.category._id
    }
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("카테고리 기본정보")]), _c('v-card-text', [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 코드(영문)",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.code,
      callback: function ($$v) {
        _vm.$set(_vm.category, "code", $$v);
      },
      expression: "category.code"
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "카테고리 이름",
      "placeholder": " ",
      "dense": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.name,
      callback: function ($$v) {
        _vm.$set(_vm.category, "name", $$v);
      },
      expression: "category.name"
    }
  })], 1)], 1)], 1)], 1), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "disabled": !_vm.category._id
    }
  }, [_c('v-card-title', [_vm.category.depth == 2 ? _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("컬렉션 정보")]) : _vm._e(), _vm.category.depth == 3 ? _c('span', {
    staticClass: "subtitle-1"
  }, [_vm._v("텍스처 정보")]) : _vm._e()]), _c('v-card-text', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.thumb || _vm.category.thumb ? _c('v-img', {
    attrs: {
      "width": "96",
      "src": _vm.thumb ? _vm.createObjectURL(_vm.thumb) : _vm.category.thumb,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": _vm.category.depth == 2 ? '컬렉션 썸네일 이미지' : '텍스처 썸네일 이미지',
      "placeholder": " "
    },
    model: {
      value: _vm.thumb,
      callback: function ($$v) {
        _vm.thumb = $$v;
      },
      expression: "thumb"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    on: {
      "click": function ($event) {
        return _vm.download(_vm.category.thumb, _vm.category.name);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "color": "primary",
      "size": "20"
    }
  }, [_vm._v(_vm._s(_vm.mdiDownload))]), _vm._v("다운로드")], 1)], 1)], 1), _vm.category.depth == 2 ? _c('v-row', {
    staticClass: "mt-2",
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 pr-0",
    attrs: {
      "cols": "auto"
    }
  }, [_vm.image || _vm.category.image ? _c('v-img', {
    attrs: {
      "width": "96",
      "src": _vm.image ? _vm.createObjectURL(_vm.image) : _vm.category.image,
      "contain": ""
    }
  }) : _c('v-responsive', {
    staticClass: "grey lighten-2",
    attrs: {
      "width": "96",
      "height": "96"
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1), _c('v-col', [_c('v-file-input', {
    attrs: {
      "label": "컬렉션 이미지",
      "placeholder": " "
    },
    model: {
      value: _vm.image,
      callback: function ($$v) {
        _vm.image = $$v;
      },
      expression: "image"
    }
  })], 1)], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "label": "컬렉션 이미지 사이드 문구",
      "placeholder": " ",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.category.sideText,
      callback: function ($$v) {
        _vm.$set(_vm.category, "sideText", $$v);
      },
      expression: "category.sideText"
    }
  })], 1)], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-textarea', {
    attrs: {
      "label": "컬렉션 설명",
      "placeholder": " ",
      "rows": "2",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.category.summary,
      callback: function ($$v) {
        _vm.$set(_vm.category, "summary", $$v);
      },
      expression: "category.summary"
    }
  })], 1)], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', {
    staticClass: "mt-6"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-textarea', {
    attrs: {
      "label": "컬렉션 하단 설명",
      "placeholder": " ",
      "rows": "2",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.category.details,
      callback: function ($$v) {
        _vm.$set(_vm.category, "details", $$v);
      },
      expression: "category.details"
    }
  })], 1)], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', {
    staticClass: "caption mt-4"
  }, [_c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("컬렉션 유형")]), _c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("친환경인증 표기")]), _c('v-col', {
    staticClass: "py-0"
  }, [_vm._v("샘플노출")])], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', [_c('v-col', [_c('v-radio-group', {
    staticClass: "ma-0",
    attrs: {
      "row": "",
      "hide-details": ""
    },
    model: {
      value: _vm.category.type,
      callback: function ($$v) {
        _vm.$set(_vm.category, "type", $$v);
      },
      expression: "category.type"
    }
  }, [_c('v-radio', {
    attrs: {
      "label": "내수용",
      "value": "내수용"
    }
  }), _c('v-radio', {
    attrs: {
      "label": "수출용",
      "value": "수출용"
    }
  })], 1)], 1), _c('v-col', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "inset": "",
      "label": _vm.category.isEcoFriendly ? '표기' : '미표기'
    },
    model: {
      value: _vm.category.isEcoFriendly,
      callback: function ($$v) {
        _vm.$set(_vm.category, "isEcoFriendly", $$v);
      },
      expression: "category.isEcoFriendly"
    }
  })], 1), _c('v-col', [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": "",
      "inset": "",
      "label": _vm.category.isSample ? '노출' : '미노출'
    },
    model: {
      value: _vm.category.isSample,
      callback: function ($$v) {
        _vm.$set(_vm.category, "isSample", $$v);
      },
      expression: "category.isSample"
    }
  })], 1)], 1) : _vm._e(), _vm.category.depth == 2 ? _c('v-file-input', {
    attrs: {
      "label": "컬렉션 전체이미지 다운로드",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.category.file,
      callback: function ($$v) {
        _vm.$set(_vm.category, "file", $$v);
      },
      expression: "category.file"
    }
  }) : _vm._e(), _vm.category.depth == 2 ? _c('v-row', [_c('v-col', [_c('collection-image-downloader', {
    attrs: {
      "category": _vm.category
    }
  })], 1)], 1) : _vm._e()], 1)], 1), _vm.category.depth == 2 ? _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "disabled": !_vm.category._id
    }
  }, [_c('v-card-title', {
    staticClass: "py-2"
  }, [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "py-0 subtitle-1"
  }, [_vm._v("해시태그")]), _c('v-spacer')], 1)], 1), _c('v-card-text', _vm._l(_vm.category.hashtags, function (hashtag) {
    return _c('v-chip', {
      staticClass: "mr-2",
      attrs: {
        "close": "",
        "close-icon": "mdi-delete",
        "color": "primary"
      },
      on: {
        "click:close": function ($event) {
          _vm.category.hashtags.splice(_vm.category.hashtags.indexOf(hashtag), 1);
        }
      }
    }, [_vm._v(_vm._s(hashtag))]);
  }), 1), _c('v-divider'), _c('v-card-actions', [_c('v-row', [_c('v-col', {
    staticClass: "py-0"
  }, [_c('v-text-field', {
    attrs: {
      "hide-details": "",
      "outlined": "",
      "dense": "",
      "clearable": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        _vm.category.hashtags.push(_vm.hashtag);
        _vm.hashtag = '';
      }
    },
    model: {
      value: _vm.hashtag,
      callback: function ($$v) {
        _vm.hashtag = $$v;
      },
      expression: "hashtag"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.category.hashtags.push(_vm.hashtag);
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(_vm._s(_vm.mdiTagPlus))]), _vm._v(" 추가")], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-card', {
    staticClass: "mt-3",
    attrs: {
      "disabled": !_vm.category._id
    }
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "py-0 subtitle-1"
  }, [_vm._v("보임/숨김")]), _c('v-spacer'), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.category.isHidden,
      callback: function ($$v) {
        _vm.$set(_vm.category, "isHidden", $$v);
      },
      expression: "category.isHidden"
    }
  })], 1)], 1)], 1)], 1), _c('shop-category-seo', {
    attrs: {
      "disabled": !_vm.category._id
    },
    model: {
      value: _vm.category,
      callback: function ($$v) {
        _vm.category = $$v;
      },
      expression: "category"
    }
  }), _c('v-row', [_c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "white--text",
    attrs: {
      "color": "grey darken-1",
      "disabled": !_vm.category._id || _vm.category.root ? true : false
    },
    on: {
      "click": function ($event) {
        return _vm.remove(_vm.category);
      }
    }
  }, [_vm._v("삭제")])], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "md": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary",
      "disabled": !_vm.category._id
    },
    on: {
      "click": function ($event) {
        return _vm.update(_vm.category);
      }
    }
  }, [_vm._v("저장")])], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }