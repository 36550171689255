<template>
    <v-layout justify-center>
        <v-responsive max-width="1980px" width="100%" class="px-0 px-md-4 px-xl-8">
            <v-row class="mt-0 mt-md-8" justify="center" align="center">
                <v-col cols="auto" class="pt-0 pb-0 headline text-start">취소관리</v-col>
                <v-spacer></v-spacer>
                <v-col cols="auto" class="pt-0 pb-0">
                    <v-btn class="ma-2" color="secondary" rounded @click="showSearch = !showSearch"><v-icon small class="mr-2">mdi-filter-variant</v-icon>검색필터</v-btn>
                </v-col>
            </v-row>

            <v-expand-transition>
                <v-card v-show="showSearch" class="elevation-1 mx-auto">
                    <v-card-title class="subtitle-2 font-weight-bold">검색필터</v-card-title>
                    <v-card-text class="py-0">
                        <v-row class="mt-4" align="center">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="startDateMenu" :close-on-content-click="false" :return-value.sync="filter.startDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.startDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.startDate" no-title scrollable @input="$refs.startDateMenu.save(filter.startDate); filter.createDate[0] = filter.startDate ? new Date(`${filter.startDate} 00:00:00.000`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-menu ref="endDateMenu" :close-on-content-click="false" :return-value.sync="filter.endDate" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="filter.endDate" label="주문일자" placeholder=" " dense hide-details append-icon="event" readonly v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="filter.endDate" no-title scrollable @input="$refs.endDateMenu.save(filter.endDate); filter.createDate[1] = filter.endDate ? new Date(`${filter.endDate} 23:59:59.999`).getTime() : null"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row class="mt-6">
                            <v-col cols="12" sm="6" class="py-0">
                                <v-select v-model="filter.searchKey" :items="searchKeys" item-text="text" item-value="value" dense hide-details label="상세조건" placeholder=" "></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" class="py-0">
                                <v-text-field v-model="filter.searchValue" dense hide-details :disabled="!filter.searchKey"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" rounded text @click="search"><v-icon small class="mt-1 mr-2">mdi-magnify</v-icon>검색</v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

            <v-row class="mt-4">
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-package-variant</v-icon>취소요청</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=cancel&claimStatus=cancel-request">{{ dashboard?.cancelRequest?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
                <v-col cols="12" sm="6" md="3" class="py-0">
                    <v-card>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0"><v-icon class="mr-2">mdi-clipboard-check-outline</v-icon>취소완료</v-col>
                                <v-spacer></v-spacer>
                                <v-col cols="auto" class="py-0 pl-0"><span class="font-weight-bold"><router-link to="?manage=cancel&claimStatus=cancel-complete">{{ dashboard?.cancelComplete?.format() }}</router-link></span>건</v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-data-table v-model="selected" :headers="purchasesHeaders" :items="purchases" show-select disable-sort disable-pagination hide-default-footer class="elevation-1 mt-4">
                <template v-slot:item.createDate="{ item }">
                    {{ item?.createDate?.toDateTime() }}
                </template>
                <template v-slot:item.order.sender="{ item }">
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order?.sender?.name }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order?.sender?.phone }}</div>
                    <div :class="{ 'red--text': item.user ? item.user.warning : false }">{{ item.order?.sender?.email }}</div>
                </template>
                <template v-slot:item.order.receiver="{ item }">
                    <div>{{ item.order?.receiver?.name }}</div>
                    <div>{{ item.order?.receiver?.phone }}</div>
                    <div>{{ item.order?.receiver?.email }}</div>
                </template>
            </v-data-table>
            <v-pagination v-model="page" :length="pageCount" :total-visible="11" class="mt-4 mb-12" @input="search"></v-pagination>

            <v-card class="mt-4">
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="12" sm="3" md="2" class="py-0">취소처리</v-col>
                        <v-col cols="12" sm="9" md="10" class="py-0">
                            <v-btn color="primary" outlined class="mr-2" small @click="cancelComplete">취소 완료처리</v-btn>
                            <v-btn color="primary" outlined class="mr-2" small @click="cancelReject">취소 거부처리</v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-responsive>
    </v-layout>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 검색필터
            ///////////////////////////////////////////////
            showSearch: true,
            filter: {
                startDate: "",
                startTime: "",
                endDate: "",
                endTime: "",
                username: "",
                phone: "",
                searchKey: null,
                searchValue: null,
                claimStatuses: ["cancel-request", "cancel-complete", "cancel-reject"],
                createDate: [null, null]
            },
            searchKeys: [
                { text: "전체", value: null },
                { text: "수취인명", value: "receiver.name" },
                { text: "구매자명", value: "sender.name" },
                { text: "구매자연락처", value: "sender.phone" },
                { text: "구매자ID", value: "sender.username" },
                { text: "주문번호", value: "orderNo" },
                { text: "상품주문번호", value: "purchaseNo" },
            ],

            dashboard: {
                cancelRequest: 0,
                cancelComplete: 0,
            },

            ///////////////////////////////////////////////
            // 페이징
            ///////////////////////////////////////////////
            page: 1,
            pageCount: 0,
            limit: 20,

            ///////////////////////////////////////////////
            // 구매목록
            ///////////////////////////////////////////////
            selected: [],
            purchases: [],
            purchasesHeaders: [{
                text: '상품주문번호',
                align: 'center',
                width: 100,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 100,
                value: 'order.orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 140,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 140,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 140,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'order.sender.phone'
            },
            {
                text: '벽지코드',
                align: 'center',
                width: 200,
                value: 'product.code'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 200,
                value: 'product.name'
            },
            {
                text: '옵션정보',
                align: 'center',
                width: 200,
                value: 'name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'order.sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'order.receiver'
            }],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getPurchases();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },
        async search(){
            try{
                await this.getPurchases();
                this.getDashboard();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        /////////////////////////////////////////////////
        // 취소완료처리
        /////////////////////////////////////////////////
        async cancelComplete(){
            try{
                if(!this.selected.length) throw new Error("취소처리를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        claimStatus: "cancel-complete"
                    });
                }
                alert("취소완료처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        /////////////////////////////////////////////////
        // 취소거부처리
        /////////////////////////////////////////////////
        async cancelReject(){
            try{
                if(!this.selected.length) throw new Error("취소거부를 하기위한 구매건을 선택해주세요");

                for(var i in this.selected){
                    await this.putPurchase({
                        _id: this.selected[i]._id,
                        orderStatus: "cancel-reject"
                    });
                }
                alert("취소거부처리 되었습니다.");
                this.search();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }
        },

        getPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/purchases', {
                        headers: {
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit
                        },
                        params: Object.assign({}, this.filter, this.query)
                    });

                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);
                    this.purchases = res.data.purchases;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putPurchase(purchase){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/purchases/${purchase._id}`, purchase);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getDashboard(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/shop/purchases/dashboard/${this.$route.query.manage}`);

                    this.dashboard = res.data.dashboard;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    },
    computed: {
        query(){
            return this.$route.query;
        }
    },
    watch: {
        query(){
            this.search();
        }
    }
}
</script>
