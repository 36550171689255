<template>
    <v-container fluid fill-height>
        <shop-coupon-list v-if="$route.query._coupon === undefined"></shop-coupon-list>
        <shop-coupon-view v-else></shop-coupon-view>
    </v-container>
</template>

<script>
import ShopCouponList from './coupon/shop-coupon-list.vue';
import ShopCouponView from './coupon/shop-coupon-view.vue';

export default {
    components: {
        ShopCouponList,
        ShopCouponView,
    },
}
</script>
