<template>
    <v-row v-if="maxDepth" class="pa-0">
        <v-col v-for="(depth, index) in maxDepth" :key="depth" cols="12" :md="12/maxDepth" :sm="12/maxDepth" class="py-3 py-sm-0">
            <v-select :items="findItems(depth)" item-text="name" item-value="_id" :value="isSelect(depth)" @input="input" :label="`${depth}차 카테고리`" placeholder=" " :dense="dense" hide-details></v-select>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        dense: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        value: { type: String, default: null },
        categories: { type: Array, default: [] },
        max: { type: Number, default: undefined }
    },
    data: () => {
        return {
            _category: null
        };
    },
    created(){
        this.$data._category = this.value;
    },
    methods: {
        input(_category){
            this.$data._category = _category;
            this.$emit('input', _category);
            this.$emit('change', { _category, category: this.findCategory(_category) });
        },

        isSelect(depth, categories = null){
            var category = this.findCategory(this.$data._category);
            return category ? category._parents.concat(category._id)[depth - 1] || category._parents.concat(category._id)[depth - 2]  : null;
        },

        findCategory(_category, categories = this.categories){
            var find = null;
            categories.forEach((category) => {
                if(category._id == _category) find = category;
                if(!find && category.children) find = this.findCategory(_category, category.children);
            });

            return find;
        },

        findItems(depth){
            if(depth == 1) return [{ _id: null, name: ":: 선택없음 ::" }].concat(this.categories);

            var category = this.findCategory(this.$data._category);
            if(category && category._parents.concat(category._id)[depth - 2] ){
                return [{ _id: this.findCategory(category._parents.concat(category._id)[depth - 2])._id, name: ":: 선택없음 ::" }].concat(this.findCategory(category._parents.concat(category._id)[depth - 2]).children);
            }

            return [];
        },

        findDepth(maxDepth, categories = this.categories){
            categories.forEach((category) => {
                var result = (category.children && category.children.length) ? this.findDepth(maxDepth, category.children) : category.depth;
                if(maxDepth < result) maxDepth = result;
            });
            return maxDepth;
        },
    },
    computed: {
        maxDepth(){
            var maxDepth = this.findDepth(0);
            return this.max && this.max < maxDepth ? this.max : maxDepth;
        }
    },
    watch: {
        value(){
            this.$data._category = this.value;
        }
    }
}
</script>
