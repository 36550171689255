<template>
    <v-btn @click="download" color="accent" large width="100%">
        <v-icon>mdi-folder-zip-outline</v-icon>
        컬렉션 전체이미지 다운로드 (자동생성)
    </v-btn>
</template>

<script>
import JSZip from "jszip";

export default {
    props: {
        category: Object,
    },
    methods: {
        async download() {
            try {
                const { products } = (await this.$http.get(`/api/console/shop/products`, { params: { _category: this.category?._id } }))?.data;

                const images = products.reduce((images, product) => [...images, { fileName: `${product?.code?.trim()} - ${product?.name?.trim()}.${product.images[0].split(".")[1]}`, fileURL: product.images[0] }], []);

                const files = await Promise.all(images.map(async (image) => ({ ...image, file: await this.getResource(image.fileURL, image.fileName) })));
                console.log({ files });

                const zip = new JSZip();
                await Promise.all(files.map(async (file) => await zip.file(file.fileName, file.file, { binary: true })));
                console.log("isCalled?");
                zip.generateAsync({ type: "blob" })
                    .then(function(blob) {
                        console.log("download starts?");
                        window.location = window.URL.createObjectURL(blob);
                    })
                    .catch((error) => console.log(error));
            } catch (error) {}
        },

        getResource(url, filename) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http({
                        url,
                        method: "GET",
                        responseType: "blob",
                    });

                    resolve(new File([res.data], filename));
                } catch (error) {
                    reject(error);
                }
            });
        },
    },
};
</script>

<style></style>
