<template>
    <v-layout v-if="ready" justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div v-if="!$route.query._product" class="headline text-start mt-8">제품 등록</div>
            <div v-else class="headline text-start mt-8">제품 상세</div>

            <v-tabs v-model="tab" background-color="content" class="mt-4">
                <v-tab key="basic">기본상품정보</v-tab>
                <v-tab v-show="false" key="autocomplete"><!-- 자동완성 --></v-tab>
                <v-tab-item key="basic" >
                    <v-card class="mt-4">
                        <v-card-title class="subtitle-1">카테고리</v-card-title>
                        <v-card-text>
                            <shop-category-select v-model="product._category" :categories="categories"></shop-category-select>
                        </v-card-text>
                    </v-card>

                    <v-row class="mt-4">
                        <v-col cols="12" md="7" sm="7">
                            <v-card>
                                <v-card-title class="subtitle-1">썸네일</v-card-title>
                                <v-card-text>
                                    <v-row>
                                        <v-col cols="auto" class="pt-0 pb-0">
                                            <v-img v-if="thumb || product.thumb" :src="thumb ? createObjectURL(thumb) : product.thumb" width="128" height="128"></v-img>
                                            <v-responsive v-else width="128" height="128">
                                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-row>
                                            </v-responsive>
                                        </v-col>
                                        <v-col>
                                            <v-file-input v-model="thumb" label="썸네일 업로드" show-size accept="image/*"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-title class="subtitle-1">제품 이미지</v-card-title>
                                <v-card-text>
                                    <v-row class="pt-0 pb-0">
                                        <v-col cols="auto" class="pt-2 pb-0">
                                            <v-img v-if="images[0] || product.images[0]" :src="images[0] ? createObjectURL(images[0]) : product.images[0]" width="128" height="128"></v-img>
                                            <v-responsive v-else width="128" height="128">
                                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-row>
                                            </v-responsive>
                                        </v-col>
                                        <v-col>
                                            <v-file-input v-model="images[0]" :label="'기본보기'" show-size dense accept="image/*"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-0 pb-0">
                                        <v-col cols="auto" class="pt-2 pb-0">
                                            <v-img v-if="images[1] || product.images[1]" :src="images[1] ? createObjectURL(images[1]) : product.images[1]" width="128" height="128"></v-img>
                                            <v-responsive v-else width="128" height="128">
                                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-row>
                                            </v-responsive>
                                        </v-col>
                                        <v-col>
                                            <v-file-input v-model="images[1]" :label="'한폭보기'" show-size dense accept="image/*"></v-file-input>
                                        </v-col>
                                    </v-row>
                                    <v-row class="pt-0 pb-0">
                                        <v-col cols="auto" class="pt-2 pb-0">
                                            <v-img v-if="images[2] || product.images[2]" :src="images[2] ? createObjectURL(images[2]) : product.images[2]" width="128" height="128"></v-img>
                                            <v-responsive v-else width="128" height="128">
                                                <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                                    <v-icon>mdi-image</v-icon>
                                                </v-row>
                                            </v-responsive>
                                        </v-col>
                                        <v-col>
                                            <v-file-input v-model="images[2]" :label="'간지보기'" show-size dense accept="image/*"></v-file-input>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-text>
                                    <v-text-field v-model="product.zoomBasic" label="기본보기 확대비율(%)" type="number" placeholder=" " dense hide-details></v-text-field>
                                    <v-text-field v-model="product.zoomHeight" label="한폭보기 확대비율(%)" type="number" placeholder=" " dense hide-details class="mt-6"></v-text-field>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-title class="subtitle-1">해시태그</v-card-title>
                                <v-card-text v-if="product.hashtags.length">
                                    <v-chip v-for="(hashtag, index) in product.hashtags" :key="index" color="primary" close close-icon="mdi-delete" @click:close="product.hashtags.splice(product.hashtags.indexOf(hashtag), 1)" class="mr-2">{{ hashtag }}</v-chip>
                                </v-card-text>
                                <v-divider/>
                                <v-card-actions>
                                    <v-row align="center">
                                        <v-col class="py-0">
                                            <v-text-field v-model="hashtag" hide-details dense outlined @input="hashtag = hashtag.replace(/[^0-9a-zA-Zㄱ-ㅎ가-힣#,]/,'')" @keydown.enter="product.hashtags.push(hashtag); hashtag = '';"></v-text-field>
                                        </v-col>
                                        <v-col cols="auto" class="py-0 pl-0">
                                            <v-btn color="primary" @click="product.hashtags.push(hashtag); hashtag = '';"><v-icon class="mr-2">{{ mdiTagPlus }}</v-icon> 추가</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="5" sm="5">
                            <v-card>
                                <v-card-title class="subtitle-1">제품 정보</v-card-title>
                                <v-card-text>
                                    <v-text-field v-model="product.name" label="제품명" placeholder=" "></v-text-field>
                                    <v-text-field v-model="product.nameEn" label="제품명(영문)" placeholder=" "></v-text-field>
                                    <v-text-field v-model="product.code" label="제품코드" placeholder=" "></v-text-field>
                                    <v-text-field v-show="false" v-model="product.price" label="판매가 (0원 일때 '무료'로 문구변경)" placeholder=" "></v-text-field>
                                    <v-text-field v-show="false" v-model="product.stock" label="재고" placeholder=" " :disabled="product.optionsEnabled"></v-text-field>
                                    <v-text-field v-show="false" v-model="product.cost" label="원가 (0원 이상시 할인율도 같이표시)" placeholder=" "></v-text-field>
                                    <v-text-field v-model="product.sequence" label="진열순서" placeholder=" "></v-text-field>
                                    <v-text-field v-show="false" v-model="product.manufacturer" label="제조사" placeholder=" "></v-text-field>
                                    <v-text-field v-show="false" v-model="product.origin" label="원산지" placeholder=" "></v-text-field>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-text>
                                    <v-select v-model="product.design" label="디자인" :items="['스톤','플라워','기하무늬','스트라이프','디마스크','솔리드','내츄럴','패브릭','벽돌','기타']" placeholder=" "></v-select>
                                    <v-select v-model="product.usage" label="용도" :items="['포인트','아동','천정','범용']" placeholder=" "></v-select>
                                    <v-combobox v-model="product.style" label="스타일" :items="['플레인','내추럴','지오매트릭','플라워','키즈','집이넓어보이는','트렌디','차분한분위기','화려한분위기']" placeholder=" "></v-combobox>
                                    <v-select v-model="product.unitPrice" label="단가" :items="['저가','중가','고가']" placeholder=" "></v-select>
                                    <v-select v-model="product.kind" label="종류" :items="['내수','수출']" placeholder=" "></v-select>
                                    <v-select v-model="product.type" label="타입" :items="['실크','합지']" placeholder=" "></v-select>
                                    <v-combobox v-model="product.color" label="색상" :items="['화이트/베이지','옐로우','블루/그린','바이올렛/퍼플','레드/와인','핑크','브라운','블랙/그레이','메탈','기타']" placeholder=" "></v-combobox>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-text>
                                    <v-text-field v-model="product.size" label="사이즈"  placeholder=" "></v-text-field>
                                    <v-text-field v-model="product.material" label="소재" placeholder=" "></v-text-field>
                                </v-card-text>
                            </v-card>

                            <v-card class="mt-4">
                                <v-card-text>
                                    <div class="caption">컬렉션 리스트 -> 텍스트타입</div>
                                    <v-radio-group v-model="product.fontType" dense hide-details row>
                                        <v-radio value="black" label="블랙"></v-radio>
                                        <v-radio value="light" label="화이트"></v-radio>
                                        <v-radio value="shadow" label="쉐도우"></v-radio>
                                    </v-radio-group>
                                </v-card-text>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="auto" class="subtitle-1 py-0">브랜드</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="12" sm="12" md="6" class="py-0">
                                            <v-select v-model="product._brand" :items="brands" item-text="name" item-value="_id" dense hide-details class="mt-0"></v-select>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">전시여부 (전시/숨김)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.display" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col cols="auto" class="subtitle-1 py-0">판매여부 (판매/중단)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.sale" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">신상품 (사용/미사용)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.newly" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">특가 (사용/미사용)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.special" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">베스트 (사용/미사용)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.best" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>

                            <v-card v-show="false" class="mt-4">
                                <v-card-title>
                                    <v-row>
                                        <v-col class="subtitle-1 py-0">품절여부 (품절/판매)</v-col>
                                        <v-spacer></v-spacer>
                                        <v-col cols="auto" class="py-0">
                                            <v-switch v-model="product.soldout" dense hide-details class="mt-0"></v-switch>
                                        </v-col>
                                    </v-row>
                                </v-card-title>
                            </v-card>
                        </v-col>
                    </v-row>

                    <!-- 할인가 -->
                    <shop-product-discount v-show="false" v-model="product"></shop-product-discount>

                    <!-- 상품옵션 -->
                    <shop-product-options v-show="false" v-model="product"></shop-product-options>

                    <!-- 추가상품 -->
                    <shop-product-supplies v-show="false" v-model="product"></shop-product-supplies>

                    <!-- 배송비 -->
                    <shop-product-shippings v-show="false" v-model="product"></shop-product-shippings>

                    <!-- 도서산간 -->
                    <shop-product-islands v-show="false" v-model="product"></shop-product-islands>

                    <!-- 베스트매치 -->
                    <shop-product-bestmatchs v-model="product"></shop-product-bestmatchs>

                    <!-- 칼라 아이콘 -->
                    <shop-product-icons v-model="product"></shop-product-icons>

                    <!-- 검색엔진최적화(SEO) -->
                    <shop-product-seo v-model="product"></shop-product-seo>

                    <v-card class="mt-6">
                        <v-card-title class="subtitle-1">상품 상세 설명</v-card-title>
                        <v-card-text>
                            <naver-smarteditor v-model="product.content" rows="10"></naver-smarteditor>
                        </v-card-text>
                    </v-card>

                </v-tab-item>

                <v-tab-item key="autocomplete">
                </v-tab-item>
            </v-tabs>

            <v-row justify="center">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
</template>

<script>
import ShopProductDiscount from './shop-product-discount.vue';
import ShopProductOptions from './shop-product-options.vue';
import ShopProductSupplies from './shop-product-supplies.vue';
import ShopProductShippings from './shop-product-shippings.vue';
import ShopProductIslands from './shop-product-islands.vue';
import ShopProductBestmatchs from './shop-product-bestmatchs.vue';
import ShopProductIcons from './shop-product-icons.vue';
import ShopProductSeo from './shop-product-seo.vue';
import ShopCategorySelect from '../category/shop-category-select.vue';
import NaverSmarteditor from '../../../plugins/naver/naver-smarteditor.vue';

import { mdiTagPlus } from '@mdi/js';

export default {
    components: {
        ShopProductDiscount,
        ShopCategorySelect,
        ShopProductOptions,
        ShopProductSupplies,
        ShopProductShippings,
        ShopProductIslands,
        ShopProductBestmatchs,
        ShopProductIcons,
        ShopProductSeo,
        NaverSmarteditor
    },
    data: () => {
        return {
            mdiTagPlus,
            ready: false,
            tab: "basic",

            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            product: {
                _category: null,
                name: null,
                nameEn: null,
                code: null,
                price: 0,
                cost: 0,
                stock: 0,
                sequence: 1,
                thumb: null,
                images: [],
                content: null,
                contentFooter: null,

                display: true,
                sale: true,
                best: false,
                newly: false,
                special: false,

                zoomBasic: 100,
                zoomHeight: 150,

                hashtags: [],
                icons: [],
            },

            ///////////////////////////////////////////////
            // UI
            ///////////////////////////////////////////////
            categories: [],
            brands: [],
            thumb: null,
            images: new Array(5),
            autoImage: null,
            hashtag: null,

            checkPoint:{
                placeholders: [
                    {
                        title: '재료와 성분',
                        content: 'ex)\n돼지 함량 97%\n돼지의 원육, 뼈, 내장을 모두함유\n식이섬유가 풍부한 호박, 오메가 3&6을 함유한 연어오일\n그레인 프리'
                    },
                    {
                        title: '생산 유통 과정',
                        content: 'ex)\n동결건조 방식으로제조해 영양소와 풍미 보존\n보관과 급여가 간편한 지퍼백 패키지 적용\n기호성 테스트용 128g과 신선하게 먹기 좋은 340g 용량'
                    },
                    {
                        title: '활용법',
                        content: ''
                    },
                ]
            },
            kongsPick: {
                placeholders: [
                    {
                        title: '선택 가이드',
                        content: 'ex)①1팩(128g). ②1팩(340g) 중 선택하세요.'
                    },
                    {
                        title: '주요 원재료',
                        content: 'ex)닭고기(뼈,심장,간포함), 호박, 코코넛오일, 연어오일 등'
                    },
                    {
                        title: '특징',
                        content: 'ex)항생제와 호르몬제 없이 방목하여 키운 닭 함량 98%의 동결건조 주식으로, 뼈와 내장을 모두 포함해 칼슘과 인, 미네랄이 풍부해요. 물에 풀면 고소한 향이 살아납니다.'
                    },
                ],
                headers: [
                    {
                        text: '반려견무게',
                        align: 'center',
                        value: 'weight'
                    }, {
                        text: '권장급여량',
                        align: 'center',
                        value: 'amount'
                    }, {
                        text: 'Actions',
                        width: 100,
                        align: 'center',
                        value: 'actions'
                    }
                ]
            }
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getCategories();
                await this.getBrands();

                if(this.$route.query._product) await this.getProduct();
                if(this.$route.query.method == "copy"){
                    this.product._id = undefined;
                    if (this?.product?.autocomplete?._id) this.product.autocomplete._id = undefined;
                    for(var i in this.product.options){
                        this.product.options[i]._id = undefined;
                    }
                    for(var i in this.product.supplies){
                        this.product.supplies[i]._id = undefined;
                    }

                    if(this.product.thumb){
                        this.thumb = await this.getResource(this.product.thumb);
                        this.product.thumb = undefined;
                    }

                    for(var i in this.product.images){
                        if(this.product.images[i]){
                            this.images[i] = await this.getResource(this.product.images[i]);
                            this.product.images[i] = undefined;
                        }
                    }

                    if(this.product?.autocomplete?.autoImage){
                        this.autoImage = await this.getResource(this.product.autoImage);
                        this.product.autoImage = undefined;
                    }

                    for(var i in this.product.icons){
                        this.product.icons[i].file = await this.getResource(this.product.icons[i].filename);
                    }
                }

                if(this.product._id === undefined){
                    var summary = await this.getProducts();

                    this.product.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        async save(){
            try{
                ////////////////////////////////////////////////////////////////
                // 1. 상품저장
                ////////////////////////////////////////////////////////////////
                console.log(this.product);
                console.log(this.product._id);
                var product = this.product._id ? await this.putProduct() : await this.postProduct();

                console.log(product);

                ////////////////////////////////////////////////////////////////
                // 2. 자동완성저장
                ////////////////////////////////////////////////////////////////
                // var autocomplete = this.product.autocomplete._id ? await this.putAutocomplete(product._id) : await this.postAutocomplete(product._id);

                ////////////////////////////////////////////////////////////////
                // 3. 옵션저장
                ////////////////////////////////////////////////////////////////
                for(var i in this.product.options){
                    var option = this.product.options[i];

                    if(option.deleted){
                        await this.deleteOption(product._id, option);
                    }
                    else if(option._id){
                        await this.putOption(product._id, option);
                    }
                    else if(!option._id){
                        await this.postOption(product._id, option);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 4. 추가상품저장
                ////////////////////////////////////////////////////////////////
                for(var i in this.product.supplies){
                    var supply = this.product.supplies[i];

                    if(supply.deleted){
                        await this.deleteSupply(product._id, supply);
                    }
                    else if(supply._id){
                        await this.putSupply(product._id, supply);
                    }
                    else if(!supply._id){
                        await this.postSupply(product._id, supply);
                    }
                }

                ////////////////////////////////////////////////////////////////
                // 5. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if(this.thumb) await this.postThumb(product._id);
                for(var i in this.images){
                    if(this.images[i]) await this.postImage(product._id, i, this.images[i]);
                }
                // if(this.autoImage) await this.postAutoImage(product._id);

                for(var i in this.product.icons){
                    if(this.product.icons[i] && this.product.icons[i].file) await this.postIcon(product._id, i, this.product.icons[i]);
                }
                for(var i = this.product.icons.length-1; 0 <= i; i--){
                    if(this.product.icons[i].removed) await this.deleteIcon(product._id, i);
                }

                if(this.product._id) alert("수정되었습니다");
                else alert("저장되었습니다");
                this.$router.go(-1);
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        getProducts(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/products', {
                        headers: {
                            "skip": 0,
                            "limit": 1
                        }
                    });

                    resolve(res.data.summary);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getProduct(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/products/' + this.$route.query._product);

                    this.product = res.data.product;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
        postProduct(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post('/api/console/shop/products', this.product);

                    resolve(res.data.product);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putProduct(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/products/${this.product._id}`, this.product);

                    resolve(res.data.product);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postAutocomplete(_product){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.post(`/api/console/shop/products/${_product}/autocomplete` , this.product.autocomplete);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putAutocomplete(_product){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.put(`/api/console/shop/products/${_product}/autocomplete` , this.product.autocomplete);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postThumb(_product){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('thumb', this.thumb);
                    await this.$http.post(`/api/console/shop/products/${_product}/thumb`, data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postImage(_product, index, image){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('image', image);
                    await this.$http.post(`/api/console/shop/products/${_product}/images/${index}` , data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postAutoImage(_product){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('autoImage', this.autoImage);
                    await this.$http.post(`/api/console/shop/products/${_product}/autoImage`, data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postIcon(_product, index, icon){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append("iconFile", icon.file);
                    data.append("iconName", icon.name);
                    await this.$http.post(`/api/console/shop/products/${_product}/icons/${index}`, data, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteIcon(_product, index){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/console/shop/products/${_product}/icons/${index}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postOption(_product, option){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post(`/api/console/shop/products/${_product}/options`, option);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putOption(_product, option){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/products/${_product}/options/${option._id}`, option);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteOption(_product, option){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/console/shop/products/${_product}/options/${option._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postSupply(_product, supply){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post(`/api/console/shop/products/${_product}/supplies`, supply);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putSupply(_product, supply){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/shop/products/${_product}/supplies/${supply._id}`, supply);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteSupply(_product, supply){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.delete(`/api/console/shop/products/${_product}/supplies/${supply._id}`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getCategories(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/categories');

                    this.categories = res.data.categories;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getBrands(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/brands');

                    this.brands = res.data.brands;
                    this.pageCount = Math.ceil(res.data.summary.totalCount / this.limit);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getResource(filename){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http({
                        url: filename,
                        method: 'GET',
                        responseType: 'blob'
                    });

                    resolve(new File([res.data], filename));
                }
                catch(error){
                    reject(error);
                }
            });
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }
    },
    computed: {
        optionsStock(){
            var stock = 0;
            for(var i in this.product.options){
                stock += this.product.options[i].stock;
            }
            return stock;
        }
    },
    watch: {
        hashtags(){
            if(this.hashtags){
                var hashtags = this.hashtags.split(',');
                for(var i in hashtags){
                    hashtags[i] = hashtags[i].trim();
                }
                this.product.hashtags = hashtags;
            }
            else this.product.hashtags = [];
        },
        optionsStock(){
            if(this.product.optionsEnabled){
                this.product.stock = this.optionsStock;
            }
        }
    }

}
</script>

<style>
.shop-product-view .weight input { text-align: end; }
.shop-product-view .amount input { text-align: end; }
.shop-product-view .theme--light.v-tabs-items { background-color: transparent !important; }
.shop-product-view .option-groups-headers th { border-bottom: thin solid rgba(0,0,0,.12) }
</style>
