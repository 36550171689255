<template>
    <v-layout justify-center>
        <v-responsive max-width="720px" width="100%">
            <div class="headline text-start mt-8">브랜드 등록</div>

            <v-row class="mt-4" justify="center">
                <v-col cols="12" algin>
                    <v-card>
                        <v-card-title class="subtitle-1">썸네일</v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col v-if="image || brand.image" cols="auto" class="py-0">
                                    <v-img :src="image ? createObjectURL(image) : brand.image" max-width="128" max-height="128"></v-img>
                                </v-col>
                                <v-col v-else cols="auto" class="py-0">
                                    <v-responsive width="128" height="128">
                                        <v-row class="fill-height ma-0 grey lighten-3" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col class="py-0">
                                    <v-file-input v-model="image" label="썸네일 업로드" placeholder=" " dense hide-details show-size></v-file-input>
                                </v-col>
                            </v-row>
                            <v-row>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>

            <v-card class="mt-6">
                <v-card-text>
                    <v-text-field v-model="brand.name" label="브랜드명" placeholder=" " dense hide-details class="mt-3"></v-text-field>
                    <v-text-field v-model="brand.code" label="브랜드코드" placeholder=" " dense hide-details class="mt-6"></v-text-field>
                    <v-text-field v-model="brand.sequence" label="순서" placeholder=" " dense hide-details class="mt-6"></v-text-field>
                </v-card-text>
            </v-card>

            <v-card class="mt-6">
                <v-card-title>
                    <v-row>
                        <v-col class="subtitle-1 py-0">사용여부(사용/미사용)</v-col>
                        <v-col cols="auto" class="py-0">
                            <v-switch v-model="brand.display" dense hide-details class="mt-0"></v-switch>
                        </v-col>
                    </v-row>
                </v-card-title>
            </v-card>

            <v-row justify="center" class="mt-6">
                <v-col cols="auto" class="py-0">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>
</template>

<script>
export default {
    data: () => {
        return {
            ///////////////////////////////////////////////
            // 상품정보
            ///////////////////////////////////////////////
            brand: {
                _id: null,
                sequence: 1,
                name: '',
                code: '',
                image: null,
                display: true,
            },
            image: null,
        };
    },

    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                if(this.$route.query._brand) await this.getBrand();
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        async save(){
            try{
                if(!this.brand._id){
                    var brand = await this.postBrand();
                    if(this.image) await this.postImage(brand._id);

                    alert("등록되었습니다");
                    this.$router.push({
                        name: this.$route.name
                    });
                }
                else{
                    var brand = await this.putBrand();
                    if(this.image) await this.postImage(brand._id);

                    alert("수정되었습니다");
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },

        getBrand(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get('/api/console/shop/brands/' + this.$route.query._brand);

                    this.brand = res.data.brand;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },
        postBrand(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.post('/api/console/shop/brands', this.brand);

                    resolve(res.data.brand);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putBrand(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put('/api/console/shop/brands/' + this.brand._id, this.brand);

                    resolve(res.data.brand);
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postImage(_brand){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('image', this.image);
                    await this.$http.post(`/api/console/shop/brands/${_brand}/image` , data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        }

    }
}
</script>
