<template>
<v-navigation-drawer v-bind:value="value" @input="input" :clipped="$vuetify.breakpoint.lgAndUp" app dark color="secondary">
    <!-- 관리자 목록 -->
    <v-list v-model="selected" dense>
        <template v-for="menu in menus">

            <!-- 싱글메뉴 -->
            <v-list-item-group v-if="!menu.children" v-model="selected" :key="menu.model">
                <v-list-item v-if="menu.scope === undefined || menu.scope.find((scope) => payload.scope.includes(scope))" :key="menu.title" :value="menu" @click="navigate(menu)">
                    <template v-slot:default="{ active }">
                        <v-list-item-action>
                            <v-icon size="20" :color="active ? 'accent' : ''">{{ menu.icon }}</v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                            <v-list-item-title class="text-start" :class="{ 'accent--text': active}">{{ menu.title }}</v-list-item-title>
                        </v-list-item-content>
                    </template>
                </v-list-item>
            </v-list-item-group>

            <!-- 자식이 있는 메뉴 -->
            <v-list-group v-else-if="menu.scope === undefined || menu.scope.find((scope) => payload.scope.includes(scope))" :key="menu.text" v-model="menu.model" :prepend-icon="menu.icon" color="white">
                <template v-slot:prependIcon>
                    <v-icon size="20" dense>{{ menu.icon }}</v-icon>
                </template>
                <template v-slot:activator>
                    <v-list-item-title class="text-start subtitle-2">{{ menu.title }}</v-list-item-title>
                </template>
                <v-divider></v-divider>

                <v-list-item-group v-model="selected">
                    <template v-for="(child, j) in menu.children">
                        <v-list-item v-if="child.scope === undefined || child.scope.find((scope) => payload.scope.includes(scope))" :key="j" :value="child" @click="navigate(child)" style="background: #414958;" dense>
                            <template v-slot:default="{ active }">
                                <v-list-item-action class="mr-4 ml-4">
                                    <v-icon v-if="child.icon" size="20" :color="active ? 'accent' : ''">{{ child.icon }}</v-icon>
                                </v-list-item-action>
                                <v-list-item-content>
                                    <v-list-item-title class="text-start" :class="{ 'grey--text text--lighten-1': !active, 'accent--text': active }">{{ child.title }}</v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </v-list-item>
                    </template>
                </v-list-item-group>

                <v-divider></v-divider>
            </v-list-group>

        </template>
    </v-list>

    <!-- 관리자 하단 목록 -->
    <template v-slot:append>
        <v-divider></v-divider>
        <v-list dense class="pa-0">
            <v-list-item @click="signOut">
                <v-list-item-action>
                    <v-icon dense>mdi-logout-variant</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title class="text-start">로그아웃</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </template>

</v-navigation-drawer>
</template>

<script>
import {
    mdiFileDocument
} from '@mdi/js';
import {
    mdiPencilBoxMultiple
} from '@mdi/js';

export default {
    props: {
        value: {
            type: Boolean,
            default: null
        }
    },
    data: () => {
        return {
            selected: null,
            menus: [{
                    icon: "mdi-view-dashboard",
                    title: "대시보드",
                    scope: ["console"],
                    route: {
                        name: "console-dashboard"
                    },
                },
                {
                    icon: 'mdi-account-circle',
                    title: '사용자 정보',
                    scope: ["console"],

                    children: [{
                            title: "회원목록",
                            route: {
                                name: "console-users"
                            },
                        },
                        {
                            title: "회원등급",
                            route: {
                                name: "console-user-levels"
                            }
                        },
                        {
                            title: "탈퇴회원",
                            route: {
                                name: "console-user-withdraw"
                            }
                        },
                    ]
                },
                {
                    icon: mdiFileDocument,
                    title: '게시판 관리',
                    scope: ["console", "shop"],

                    children: [{
                            title: "협찬정보",
                            scope: ["console"],
                            route: {
                                path: "/console/boards/support"
                            },
                        },
                        {
                            title: "인테리어샵",
                            scope: ["console", "shop"],
                            route: {
                                path: "/console/boards/interior-shop"
                            }
                        },
                        {
                            title: "인테리어상담",
                            scope: ["console"],
                            route: {
                                path: "/console/boards/interior-inquire"
                            },
                            // query: { code: "interior-inquire" }
                        },
                        {
                            title: "이벤트",
                            scope: ["console"],
                            route: {
                                path: "/console/boards/event"
                            },
                            // query: { code: "event" }
                        },
                    ]
                },
                {
                    icon: mdiPencilBoxMultiple,
                    title: '제품 관리',
                    scope: ["console"],
                    children: [{
                            title: "컬렉션 & 텍스처 관리",
                            route: {
                                path: "/console/shop/categories"
                            }
                        },
                        {
                            title: "벽지 관리",
                            route: {
                                name: "console-shop-products"
                            }
                        },
                        // {
                        //     title: "브랜드 관리",
                        //     route: { path: "console-shop-brands"}
                        // },
                        // {
                        //     title: '상품문의',
                        //     route: { path: "console-shop-inquire"}
                        // },
                        // {
                        //     title: '구매후기',
                        //     route: { path: "console-shop-reviews"}
                        // },
                        // {
                        //     title: "운영정보설정",
                        //     route: { path: "console-shop-setting"}
                        // }
                    ]
                },
                {
                    icon: 'mdi-receipt',
                    title: '주문 정보',
                    scope: ["console"],
                    children: [
                        // {
                        //     title: "주문 관리",
                        //     route: { path: "console-shop-orders"},
                        // },
                        // {
                        //     title: "미결제 관리",
                        //     route: { path: "console-shop-orders"},
                        //     query: {
                        //         manage: "nonpayment"
                        //     }
                        // },
                        {
                            title: "발주/발송 관리",
                            route: {
                                name: "console-shop-orders",
                                query: {
                                    manage: "payment"
                                }
                            },
                        },
                        {
                            title: "배송 관리",
                            route: {
                                name: "console-shop-orders",
                                query: {
                                    manage: "delivery"
                                }
                            },
                        },
                        {
                            title: "취소 관리",
                            route: {
                                name: "console-shop-orders",
                                query: {
                                    manage: "cancel"
                                }
                            },
                        },
                        // {
                        //     title: "반품 관리",
                        //     route: { path: "console-shop-orders"},
                        //     query: {
                        //         manage: "return"
                        //     }
                        // },
                        // {
                        //     title: "교환 관리",
                        //     route: { path: "console-shop-orders"},
                        //     query: {
                        //         manage: "exchange"
                        //     }
                        // },
                    ]
                },
                // {
                //     icon: "mdi-ticket-percent",
                //     title: "쿠폰 관리",
                //     route: { path: "console-shop-coupons"}
                // },
                // {
                //     icon: "mdi-plus-circle-multiple-outline",
                //     title: "포인트 관리",
                //     route: { path: "console-shop-points"}
                // },
                // {
                //     icon: 'mdi-chart-bar',
                //     title: '통계관리',
                //     route: { path: "console-statistics"}
                // },
                {
                    icon: "mdi-image",
                    title: "배너 관리",
                    scope: ["console"],
                    route: {
                        name: "console-banners"
                    }
                },
                {
                    icon: "mdi-window-restore",
                    title: "팝업 관리",
                    scope: ["console"],
                    route: {
                        name: "console-popups"
                    }
                },
                {
                    icon: "mdi-youtube",
                    title: "YouTube 관리",
                    scope: ["console"],
                    route: {
                        name: "console-youtube"
                    }
                },
                {
                    icon: 'mdi-help-circle',
                    title: '고객센터',
                    scope: ["console"],
                    children: [{
                            title: '공지사항',
                            route: {
                                name: "console-notification"
                            }
                        },
                        {
                            title: 'Faq',
                            route: {
                                name: "console-faq"
                            }
                        },
                        {
                            title: '1:1 문의',
                            route: {
                                name: "console-question"
                            }
                        },
                    ],
                },
                {
                    icon: 'mdi-format-list-checks',
                    title: '이용약관',
                    scope: ["console"],
                    route: {
                        name: "console-terms"
                    }
                },
                {
                    icon: 'mdi-web',
                    title: '자사정보',
                    scope: ["console"],
                    route: {
                        name: "console-setting"
                    }
                },
            ]
        };
    },
    methods: {
        input(value) {
            this.$emit('input', value);
        },
        signOut() {
            if (confirm("로그아웃 하시겠습니까?")) {
                this.$store.dispatch('logout');
                this.$router.push("/console");
            }
        },
        navigate(item) {
            if (item.route) this.$router.push(item.route);
        },
    },
    computed: {
        payload() {
            return this.$store.state.payload || {};
        }
    }
}
</script>
