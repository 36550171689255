var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', {
    attrs: {
      "color": "accent",
      "large": "",
      "width": "100%"
    },
    on: {
      "click": _vm.download
    }
  }, [_c('v-icon', [_vm._v("mdi-folder-zip-outline")]), _vm._v(" 컬렉션 전체이미지 다운로드 (자동생성) ")], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }