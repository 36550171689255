<template>
    <v-app id="inspire">
        <v-content>
            <v-container class="fill-height content" fluid>
                <v-row align="center" justify="center">
                    <v-col cols="12" sm="8" md="3">
                        <v-card class="elevation-12">
                            <v-toolbar color="info" dark flat>
                                <v-toolbar-title>관리자 로그인</v-toolbar-title>
                                <v-spacer />
                            </v-toolbar>
                            <v-card-text>
                                <v-form>
                                    <v-text-field v-model="username" label="아이디" name="login" prepend-icon="person" type="text" @keydown.enter="signIn"/>
                                    <v-text-field v-model="password" id="password" label="비밀번호" name="password" prepend-icon="lock" type="password" @keydown.enter="signIn"/>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer />
                                <v-btn color="primary" dark @click="signIn"><v-icon dense class="mr-2">mdi-login-variant</v-icon>로그인</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-content>
    </v-app>
</template>

<script>
import CryptoAES from '../../plugins/crypto-aes';
export default{
    data: () => {
        return {
            username: "",
            password: ""
        }
    },
    methods: {
        signIn(){
            this.$store.dispatch('login', { username: this.username, password: this.password }).catch((error) => {
                if(error.response) alert(error.response.data.message.toString());
            })
        },
    }
}
</script>
