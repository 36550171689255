var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-6 shop-product-supplies"
  }, [_c('v-card-title', [_c('v-row', [_c('v-col', {
    staticClass: "subtitle-1 py-0"
  }, [_vm._v("도서산간설정 (사용/미사용)")]), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "md": "auto",
      "sm": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "dense": "",
      "hide-details": ""
    },
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.islandsEnabled,
      callback: function ($$v) {
        _vm.islandsEnabled = $$v;
      },
      expression: "islandsEnabled"
    }
  })], 1)], 1)], 1), _vm.islandsEnabled ? _c('v-card-text', _vm._l(_vm.islands, function (island) {
    return _c('v-row', [_c('v-col', {
      attrs: {
        "cols": "12"
      }
    }, [_c('v-text-field', {
      attrs: {
        "label": `'${island.name}' 지역 추가배송비`,
        "placeholder": " ",
        "dense": "",
        "hide-details": ""
      },
      on: {
        "change": _vm.input
      },
      model: {
        value: island.price,
        callback: function ($$v) {
          _vm.$set(island, "price", $$v);
        },
        expression: "island.price"
      }
    })], 1)], 1);
  }), 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }