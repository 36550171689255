var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-4"
  }, [_c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("관련제품")]), _vm.relativeProducts.length ? _c('v-card-text', {
    staticStyle: {
      "display": "flex",
      "flex": "1 1",
      "flex-wrap": "wrap"
    }
  }, _vm._l(_vm.relativeProducts, function (product, index) {
    return _c('v-card', {
      key: index,
      staticClass: "mr-2 mb-2"
    }, [_c('v-card-title', {
      staticClass: "body-1 py-0"
    }, [_c('v-row', {
      staticClass: "px-0"
    }, [_c('v-list-item-avatar', {
      attrs: {
        "rounded": ""
      }
    }, [_c('v-img', {
      attrs: {
        "src": product.thumb
      }
    })], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(`[${product.code}] ${product.name}`) + " ")]), _c('v-list-item-action', [_c('v-icon', {
      on: {
        "click": function ($event) {
          return _vm.relativeProducts.splice(index, 1);
        }
      }
    }, [_vm._v("mdi-close")])], 1)], 1)], 1)], 1);
  }), 1) : _vm._e(), _c('v-divider'), _c('v-card-actions', [_c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', [_c('v-autocomplete', {
    attrs: {
      "dense": "",
      "hide-details": "",
      "multiple": "",
      "loading": _vm.productLoading,
      "items": _vm.products,
      "item-text": "combinedCodeName",
      "item-value": "_id",
      "search-input": _vm.searchValue,
      "label": "제품이름 또는 코드로 검색",
      "placeholder": " "
    },
    on: {
      "update:searchInput": function ($event) {
        _vm.searchValue = $event;
      },
      "update:search-input": function ($event) {
        _vm.searchValue = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref) {
        var attr = _ref.attr,
          on = _ref.on,
          item = _ref.item,
          selected = _ref.selected;
        return [_c('v-card', {
          staticClass: "mr-2 mb-2"
        }, [_c('v-card-title', {
          staticClass: "body-1 py-0"
        }, [_c('v-row', {
          staticClass: "px-0"
        }, [_c('v-list-item-avatar', {
          attrs: {
            "rounded": ""
          }
        }, [_c('v-img', {
          attrs: {
            "src": item.thumb
          }
        })], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(`[${item.code}] ${item.name}`) + " ")])], 1)], 1)], 1)];
      }
    }, {
      key: "item",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-list-item-avatar', {
          attrs: {
            "rounded": ""
          }
        }, [_c('v-img', {
          attrs: {
            "src": item.thumb
          }
        })], 1), _c('v-list-item-content', [_vm._v(" " + _vm._s(`[${item.code}] ${item.name}`) + " ")])];
      }
    }]),
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0 pl-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.insert
    }
  }, [_c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v("mdi-plus")]), _vm._v("추가 ")], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }