<template>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%" class="shop-product-view">
            <div class="headline text-start mt-8">회원 상세</div>

            <v-card class="mt-4">
                <v-card-title class="subtitle-2 font-weight-bold">회원정보</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.name" label="이름" placeholder=" " hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.phone" label="연락처" placeholder=" " hide-details></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.email" label="이메일" placeholder=" " hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-select v-model="user._level" label="회원등급" :items="levels" item-text="name" item-value="_id" placeholder=" " hide-details></v-select>
                        </v-col>
                    </v-row>

                    <v-row class="mt-4">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.password" label="비밀번호" placeholder=" " hide-details></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="mt-8">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.postcode" label="우편번호" placeholder=" " hide-details></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row class="mt-4">
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.address1" label="기본주소" placeholder=" " hide-details></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" class="py-0">
                            <v-text-field v-model="user.address2" label="상세주소" placeholder=" " hide-details></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-row justify="center" class="mt-4">
                <v-col md="auto" sm="auto">
                    <v-btn color="primary" @click="save">저장</v-btn>
                </v-col>
            </v-row>

        </v-responsive>
    </v-layout>
    </v-dialog>
</template>

<script>
export default{
    data: () => {
        return {
            levels: [],
            user: {},

            petsHeaders: [{
                text: '분류',
                align: 'center',
                width: 100,
                value: 'type'
            },{
                text: '이름',
                align: 'center',
                width: 100,
                value: 'name'
            },
            {
                text: '성별',
                align: 'center',
                width: 140,
                value: 'sex'
            },
            {
                text: '품종',
                align: 'center',
                width: 140,
                value: 'kind'
            },
            {
                text: '생년월일',
                align: 'center',
                width: 140,
                value: 'birthday'
            },
            {
                text: '몸무게',
                align: 'center',
                width: 128,
                value: 'weight'
            },
            {
                text: '중성화여부',
                align: 'center',
                width: 100,
                value: 'neutralization'
            },
            {
                text: '특이사항',
                align: 'center',
                width: 200,
                value: 'memo'
            }],
            purchases: [],
            purchasesHeaders: [{
                text: '상품주문번호',
                align: 'center',
                width: 100,
                value: 'purchaseNo'
            },{
                text: '주문번호',
                align: 'center',
                width: 100,
                value: 'order.orderNo'
            },
            {
                text: '주문일자',
                align: 'center',
                width: 200,
                value: 'createDate'
            },
            {
                text: '주문상태',
                align: 'center',
                width: 140,
                value: 'orderStatusMessage'
            },
            {
                text: '클레임상태',
                align: 'center',
                width: 140,
                value: 'claimStatusMessage'
            },
            {
                text: '연락처',
                align: 'center',
                width: 128,
                value: 'order.sender.phone'
            },
            {
                text: '구매상품',
                align: 'center',
                width: 200,
                value: 'product.name'
            },
            {
                text: '옵션정보',
                align: 'center',
                width: 200,
                value: 'name'
            },
            {
                text: '구매수량',
                align: 'center',
                width: 100,
                value: 'amount'
            },
            {
                text: '구매자',
                align: 'center',
                width: 120,
                value: 'order.sender'
            },
            {
                text: '수취인',
                align: 'center',
                width: 120,
                value: 'order.receiver'
            }],
        }
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getUser();
                await this.getLevels();
                await this.getPurchases();
            }
            catch(error){
                console.error(error);
            }
        },
        async save(){
            try{
                await this.putUser({ name, phone, email, _level, warning, postcode, address1, address2 } = this.user);

                alert("수정되었습니다");
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
                else alert(error.message.toString());
            }

        },
        getUser(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/users/${this.$route.query._user}`);

                    this.user = res.data.user;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        putUser(user){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.put(`/api/console/users/${this.$route.query._user}`, user);

                    this.user = res.data.user;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getLevels(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/levels`);

                    this.levels = res.data.levels;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getPurchases(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/shop/purchases`, {
                        params: {
                            _user: this.$route.query._user
                        }
                    });

                    this.purchases = res.data.purchases;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        }
    }
}
</script>
