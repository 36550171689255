<template>
    <v-card class="mt-6 shop-options">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">옵션사용 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="optionsEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="optionsEnabled">
            <v-row align="center">
                <v-col cols="12" sm="2" class="py-0">옵션명 개수</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-select v-model="optionsCount" :items="optionsCountHeaders" item-text="text" item-value="value" dense hide-details @change="input"></v-select>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row align="center" class="mt-4">
                <v-col cols="12" sm="2" class="py-0">정렬순서</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-select v-model="optionsSort" :items="optionsSortHeaders" item-text="text" item-value="value" dense hide-details @change="input"></v-select>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row align="center" class="mt-4">
                <v-col cols="12" sm="2" class="py-0">옵션입력</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-row>
                        <v-col class="py-0">옵션명</v-col>
                        <v-col class="py-0">옵션값</v-col>
                        <v-col cols="2" class="py-0"></v-col>
                    </v-row>
                    <v-row v-for="(i, index) in optionsCount" class="mt-2">
                        <v-col class="py-0">
                            <v-text-field v-model="optionsGroups[index].name" dense hide-details :placeholder="placeholders[index].name"></v-text-field>
                        </v-col>
                        <v-col class="py-0">
                            <v-text-field v-model="optionsGroups[index].value" dense hide-details  :placeholder="placeholders[index].value"></v-text-field>
                        </v-col>
                        <v-col cols="2" class="py-0">
                            <v-btn v-if="1 < optionsCount" color="white" small class="px-1 mr-1 primary--text" style="min-width: auto" @click="optionsCount--"><v-icon>mdi-minus</v-icon></v-btn>
                            <v-btn v-if="i == optionsCount && i < optionsCountHeaders.length" color="primary" small class="px-1" style="min-width: auto" @click="optionsCount++"><v-icon>mdi-plus</v-icon></v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn color="primary" dark small @click="generate(); input()">옵션목록으로 적용<v-icon small class="ml-2">mdi-chevron-down</v-icon></v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                    </v-row>
                </v-col>
            </v-row>
            <v-divider class="mt-4"></v-divider>
            <v-row class="mt-4">
                <v-col cols="12" sm="2">옵션목록</v-col>
                <v-col cols="12" sm="10" class="py-0">
                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col cols="2" md="1" class="px-1">
                            <v-select v-model="header.operator" :items="operatorItems" label="연산자" placeholder=" " dense hide-details></v-select>
                        </v-col>
                        <v-col cols="4" md="2" class="px-1">
                            <v-text-field v-model="header.price" label="옵션가" placeholder=" " dense hide-details @input="header.price = header.price.replace(/[^0-9]/,'')"></v-text-field>
                        </v-col>
                        <v-col cols="4" md="2" class="px-1">
                            <v-text-field v-model="header.stock" label="재고수량" placeholder=" " dense hide-details @input="header.stock = header.stock.replace(/[^0-9]/,'')"></v-text-field>
                        </v-col>
                        <v-col cols="2" md="1" class="px-1">
                            <v-select v-model="header.enabled" :items="enabledItems" label="사용여부" placeholder=" " dense hide-details></v-select>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn color="secondary" small @click="modify">선택목록 일괄수정</v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table v-model="selected" :headers="optionsHeaders" :items="options.filter((option) => !option.deleted)" item-key="name" show-select>
                        <template v-slot:header.value="{ header }">
                            <v-row>
                                <v-col>옵션명</v-col>
                            </v-row>
                            <v-divider v-if="options[0]"></v-divider>
                            <v-row v-if="options[0]">
                                <v-col v-for="(name, index) in options[0].name.split('/')">{{ name.split(':')[0] }}</v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.value="{ item }">
                            <v-row>
                                <v-col v-for="(value, index) in item.value.split('/')" class="pa-0">{{ value }}</v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.price="{ item }">
                            <v-text-field v-model="item.price" dense hide-details class="price" @input="input"></v-text-field>
                        </template>
                        <template v-slot:item.stock="{ item }">
                            <v-text-field v-model="item.stock" dense hide-details class="price" @input="input"></v-text-field>
                        </template>
                        <template v-slot:item.enabled="{ item }">
                            <v-simple-checkbox v-model="item.enabled" dense color="primary" @input="input"></v-simple-checkbox>
                        </template>
                        <template v-slot:item.actions="{ item, index }">
                            <v-icon small @click="remove(item, index)">mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default{
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            optionsEnabled: false,
            optionsSort: "byLatest",
            optionsSortHeaders: [
                { text: "등록일자순", value: "byLatest" },
                { text: "이름순", value: "byName" },
                { text: "높은가격순", value: "byHighPrice" },
                { text: "낮은가격순", value: "byLowPrice" }
            ],

            optionsCount: 1,
            optionsCountHeaders: [
                { text: "1개", value: 1 },
                { text: "2개", value: 2 },
                { text: "3개", value: 3 }
            ],

            optionsGroups: [{ name: '', value: '' }],

            options: [],
            optionsHeaders: [
                {
                    text: '옵션값',
                    align: 'center',
                    sortable: false,
                    value: 'value'
                },
                {
                    text: '옵션가',
                    align: 'center',
                    width: 140,
                    value: 'price'
                },
                {
                    text: '재고수량',
                    align: 'center',
                    width: 140,
                    value: 'stock'
                },
                {
                    text: '사용여부',
                    align: 'center',
                    width: 100,
                    sortable: false,
                    value: 'enabled'
                },
                {
                    text: 'actions',
                    align: 'center',
                    width: 100,
                    sortable: false,
                    value: 'actions'
                }
            ],

            placeholders: [
                { name: "ex) 색상", value: "ex) 빨강, 노랑" },
                { name: "ex) 사이즈", value: "ex) S, M, L, XL" },
                { name: "ex) 시즌", value: "ex) 긴팔, 반팔" }
            ],

            selected: [],

            header: {
                operator: 1,
                price: null,
                stock: null,
                enabled: true
            },

            operatorItems: [
                { text: '+', value: 1 },
                { text: '-', value: -1 }
            ],

            enabledItems: [
                { text: 'Y', value: true },
                { text: 'N', value: false }
            ]
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            var { optionsEnabled, optionsSort, options } = this.value;

            if(optionsEnabled === undefined) optionsEnabled = this.optionsEnabled;
            if(optionsSort === undefined) optionsSort = this.optionsSort;
            if(options === undefined) options = this.options;

            Object.assign(this.$data, {
                optionsEnabled,
                optionsSort,
                options
            });
        },

        input(){
            this.$set(this.value, 'optionsEnabled', this.optionsEnabled);
            this.$set(this.value, 'optionsSort', this.optionsSort);
            this.$set(this.value, 'options', this.options);

            this.$emit('input', this.value);
        },

        remove(option){
            if(option._id) this.$set(option, "deleted", true);
            else this.options.splice(this.options.indexOf(option), 1);
        },

        generate(name = '', value = '', count = 1){
            if(count == 1){
                if(!this.options) this.options = [];
                while(0 < this.options.filter((option) => !option.deleted).length){
                    this.options.filter((option) => !option.deleted).forEach((option) => this.remove(option));
                }
                this.selected = [];
            }
            if(count <= this.optionsCount){
                var group = this.optionsGroups[count - 1];
                var values = group.value.split(',');
                for(var i in values){
                    if(count == this.optionsCount){
                        this.options.push({
                            name: name ? (name + ' / ' + group.name.trim() + ': ' + values[i].trim()) : group.name.trim() + ': ' + values[i].trim(),
                            value: value ? (value + ' / ' + values[i].trim()) : values[i].trim(),
                            price: 0,
                            stock: 0,
                            enabled: true
                        });
                    }
                    else{
                        this.generate(
                            name ? (name + ' / ' + group.name.trim() + ': ' + values[i].trim()) : group.name.trim() + ': ' + values[i].trim(),
                            value ? (value + ' / ' + values[i].trim()) : values[i].trim(),
                            count + 1
                        );
                    }
                }
            }
        },

        modify(){
            for(var i in this.selected){
                if(!Number.isNaN(parseInt(this.header.price))) this.selected[i].price += parseInt(this.header.price) * this.header.operator;
                if(!Number.isNaN(parseInt(this.header.stock))) this.selected[i].stock += parseInt(this.header.stock) * this.header.operator;
                this.selected[i].enabled = this.header.enabled;
            }
            this.input();
        }
    },
    watch: {
        optionsCount(){
            this.optionsGroups.splice(this.optionsCount, this.optionsGroups.length - this.optionsCount);

            for(var i=0; i < this.optionsCount; i++){
                if(!this.optionsGroups[i]) this.$set(this.optionsGroups, i, { name: '', value: '' });
            }
        },
    }
}
</script>

<style>
.shop-options .price input[type=text] { text-align: center; }
</style>
