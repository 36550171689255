<template>
    <v-card class="mt-6 shop-product-supplies">
        <v-card-title>
            <v-row>
                <v-col class="subtitle-1 py-0">배송설정 (사용/미사용)</v-col>
                <v-col md="auto" sm="auto" class="py-0">
                    <v-switch v-model="shippingEnabled" dense hide-details class="mt-0" @change="input"></v-switch>
                </v-col>
            </v-row>
        </v-card-title>
        <v-card-text v-if="shippingEnabled">
            <v-radio-group v-model="shipping.code" dense class="mt-0" @change="input">
                <v-data-table :headers="shippingsHeaders" :items="shippings" disable-sort disable-pagination hide-default-footer>
                    <template v-slot:item.shipping="{ item }">
                        <v-radio :label="item.name" :value="item.code" @click="shipping = Object.assign({}, item)">
                            <template v-slot:label>
                                <v-icon class="mx-2" small :color="item.color">{{ item.icon }}</v-icon><span class="subtitle-2">{{ item.name }}</span>
                            </template>
                        </v-radio>
                    </template>
                    <template v-slot:item.config="{ item }">
                        <v-row v-if="item.code == 'product'" align="center">
                            <v-col cols="auto">
                                <v-text-field v-model="item.price" label="배송비(원)" placeholder=" " outlined dense hide-details @input="item.price = item.price.replace(/[^0-9]/,'')"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.code == 'order'">
                            <v-col cols="auto">
                                {{ item.range.basePrice.format() }}
                            </v-col>
                            <v-col class="py-0">
                                <v-row align="center">
                                    <v-col cols="auto">이상 구매시</v-col>
                                    <v-col cols="auto">
                                        {{ item.range.highPrice.format() }}
                                    </v-col>
                                </v-row>
                                <v-row align="center">
                                    <v-col cols="auto">미만 구매시</v-col>
                                    <v-col cols="auto">
                                        {{ item.range.lowPrice.format() }}
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row v-if="item.code == 'free'">
                            <v-col class="py-0 text-start">※ 해당 제품 구매시 무료배송으로 표시됩니다.</v-col>
                        </v-row>
                        <v-row v-if="item.code == 'later'">
                            <v-col class="py-0 text-start">※ 해당 제품 구매시 착불배송으로 표시됩니다.</v-col>
                        </v-row>
                    </template>
                </v-data-table>
            </v-radio-group>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        value: { type: Object, default: null }
    },
    data: () => {
        return {
            shippingEnabled: false,

            setting: {},

            _shipping: null,
            shipping: { code: "product" },

            shippings: [],
            shippingsHeaders: [
                {
                    text: "기본정책",
                    align: "center",
                    width: 200,
                    value: "shipping"
                },
                {
                    text: "설정",
                    align: "center",
                    value: "config"
                }
            ],
        };
    },
    mounted(){
        this.init();
    },
    methods: {
        async init(){
            try{
                await this.getShippings();
                await this.getSetting();

                var { shippingEnabled, shipping, _shipping } = this.value;

                if(shippingEnabled === undefined) shippingEnabled = this.shippingEnabled;
                if(shipping === undefined) shipping = this.setting.shop._shipping ? Object.assign({}, this.shippings.find((shipping) => shipping._id == this.setting.shop._shipping )) : this.shipping;
                if(_shipping === undefined) _shipping = this.setting.shop._shipping;

                Object.assign(this.$data, {
                    shippingEnabled,
                    shipping,
                    _shipping
                });

                if(this.shipping && this.shipping.code == "product"){
                    this.shippings.find((shipping, index) => {
                        if(shipping.code == "product"){
                            Object.assign(shipping, this.shipping);
                        }
                    });
                }
            }
            catch(error){
                console.error(error);
                if(error.response) alert(error.response.data.message);
            }
        },
        input(){
            this.$set(this.value, "shippingEnabled", this.shippingEnabled);
            this.$set(this.value, "_shipping", this.shipping._id);
            this.$set(this.value, "shipping", this.shipping);

            this.$emit('input', this.value);
        },
        getShippings(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get(`/api/console/shop/shippings`);

                    this.shippings = res.data.shippings;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        getSetting(){
            return new Promise(async (resolve, reject) => {
                try{
                    var res = await this.$http.get("/api/console/setting");

                    this.setting = res.data.setting;

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

    }
}
</script>
