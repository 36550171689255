var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "console-terms",
    attrs: {
      "fluid": "",
      "fill-height": ""
    }
  }, [_c('v-layout', {
    attrs: {
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "1280px",
      "width": "100%"
    }
  }, [_c('v-row', {
    staticClass: "mt-8",
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pt-0 pb-0 headline text-start",
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("YouTube 관리")]), _c('v-spacer'), _c('v-col', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "pt-0 pb-0",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-btn', {
    staticClass: "ma-2",
    attrs: {
      "color": "secondary",
      "rounded": ""
    },
    on: {
      "click": function ($event) {
        _vm.showSearch = !_vm.showSearch;
      }
    }
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-filter-variant")]), _vm._v("검색필터")], 1)], 1)], 1), _c('v-data-table', {
    staticClass: "elevation-1 mt-4",
    attrs: {
      "headers": _vm.youtubesHeaders,
      "items": _vm.youtubes,
      "disable-sort": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "item.no",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s((_vm.page - 1) * _vm.limit + _vm.youtubes.indexOf(item) + 1))])];
      }
    }, {
      key: "item.createDate",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(item.createDate.toDate()) + " ")];
      }
    }, {
      key: "item.thumb",
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-img', {
          attrs: {
            "src": `https://img.youtube.com/vi/${item.code}/default.jpg`
          }
        })];
      }
    }, {
      key: "item.visibility",
      fn: function (_ref4) {
        var item = _ref4.item;
        return [_c('v-row', {
          attrs: {
            "justify": "center"
          }
        }, [_c('v-col', {
          staticClass: "pa-0",
          attrs: {
            "cols": "auto"
          }
        }, [_c('v-switch', {
          staticClass: "ma-0",
          attrs: {
            "hide-details": ""
          },
          on: {
            "change": function ($event) {
              return _vm.save({
                _id: item._id,
                visibility: item.visibility
              });
            }
          },
          model: {
            value: item.visibility,
            callback: function ($$v) {
              _vm.$set(item, "visibility", $$v);
            },
            expression: "item.visibility"
          }
        })], 1)], 1)];
      }
    }, {
      key: "item.actions",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.edit(item);
            }
          }
        }, [_vm._v(" mdi-pencil ")]), _c('v-icon', {
          attrs: {
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.remove(item);
            }
          }
        }, [_vm._v(" mdi-delete ")])];
      }
    }])
  }), _c('v-pagination', {
    staticClass: "mt-4 mb-12",
    attrs: {
      "length": _vm.pageCount,
      "total-visible": 11
    },
    on: {
      "input": function ($event) {
        return _vm.search();
      }
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  }), _c('v-dialog', {
    attrs: {
      "max-width": "500px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref6) {
        var on = _ref6.on;
        return [_c('v-btn', {
          attrs: {
            "bottom": "",
            "color": "accent",
            "dark": "",
            "fab": "",
            "fixed": "",
            "right": ""
          },
          on: {
            "click": _vm.create
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.editItem ? _c('v-card', [_vm.editItem._id ? _c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("유튜브 수정")]) : _c('v-card-title', {
    staticClass: "subtitle-1"
  }, [_vm._v("유튜브 등록")]), _c('v-card-text', [_c('v-text-field', {
    attrs: {
      "label": "유튜브 URL",
      "placeholder": "http://youtu.be/example",
      "hide-details": ""
    },
    on: {
      "input": _vm.matchCode
    },
    model: {
      value: _vm.editItem.url,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "url", $$v);
      },
      expression: "editItem.url"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "유튜브 CODE(자동)",
      "placeholder": "example",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.code,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "code", $$v);
      },
      expression: "editItem.code"
    }
  }), _c('v-text-field', {
    staticClass: "mt-4",
    attrs: {
      "label": "순서",
      "placeholder": " ",
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.sequence,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "sequence", $$v);
      },
      expression: "editItem.sequence"
    }
  }), _c('v-row', {
    attrs: {
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._v("숨김/보임")]), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-switch', {
    staticClass: "mt-0",
    attrs: {
      "hide-details": ""
    },
    model: {
      value: _vm.editItem.visibility,
      callback: function ($$v) {
        _vm.$set(_vm.editItem, "visibility", $$v);
      },
      expression: "editItem.visibility"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("취소")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function ($event) {
        return _vm.save(_vm.editItem);
      }
    }
  }, [_vm._v("저장")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }