<template>
<v-container fluid fill-height>
    <v-layout justify-center>
        <v-responsive max-width="1024px" width="100%" height="100%">
            <v-row class="mt-4">
                <v-col cols="12" md="6">
                    <v-card>
                        <v-toolbar color="info lighten-1" dense>
                            <v-toolbar-title class="white--text subtitle-2">카테고리</v-toolbar-title>

                            <v-spacer></v-spacer>
                            <v-dialog v-model="dialog" width="560">
                                <template v-slot:activator="{ on }">
                                    <v-fab-transition>
                                        <v-btn color="accent" fab dark small absolute bottom right v-on="on">
                                            <v-icon>mdi-plus</v-icon>
                                        </v-btn>
                                    </v-fab-transition>
                                </template>

                                <v-card v-if="dialog">
                                    <v-card-title class="subtitle-1" primary-title>
                                        <span v-if="editItem.depth == 1">카테고리 등록</span>
                                        <span v-if="editItem.depth == 2">신규 컬렉션 등록</span>
                                        <span v-if="editItem.depth == 3">신규 텍스처 등록</span>
                                    </v-card-title>

                                    <v-card-text>
                                        <v-row class="mt-3">
                                            <v-col class="py-0">
                                                <shop-category-select :categories="categories" @change="({ _category, category }) => { Object.assign(editItem, { _parents: category._parents.concat(_category), depth: category.depth + 1 }) }" :max="2"></shop-category-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-6">
                                            <v-col class="py-0">
                                                <v-text-field v-model="editItem.name" label="카테고리 이름" placeholder=" " dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-6">
                                            <v-col class="py-0">
                                                <v-text-field v-model="editItem.code" label="카테고리 코드" placeholder=" " dense hide-details></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-6">
                                            <v-col class="py-0">
                                                <div class="caption">
                                                    <span v-if="editItem.depth == 1">카테고리 썸네일 이미지</span>
                                                    <span v-if="editItem.depth == 2">컬렉션 썸네일 이미지</span>
                                                    <span v-if="editItem.depth == 3">텍스쳐 썸네일 이미지</span>
                                                </div>
                                                <v-row>
                                                    <v-col cols="auto" class="py-0 pr-0">
                                                        <v-img v-if="thumb || editItem.thumb" width="96" :src="thumb ? createObjectURL(thumb) : editItem.thumb" contain></v-img>
                                                        <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                                            <v-row class="fill-height" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col>
                                                        <v-file-input v-model="thumb" label="thumbnail" placeholder=" "></v-file-input>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="editItem.depth == 2" class="mt-6">
                                            <v-col class="py-0">
                                                <div class="caption">
                                                    <span>컬렉션 이미지</span>
                                                </div>
                                                <v-row>
                                                    <v-col cols="auto" class="py-0 pr-0">
                                                        <v-img v-if="image || editItem.image" width="96" :src="image ? createObjectURL(image) : editItem.image" contain></v-img>
                                                        <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                                            <v-row class="fill-height" align="center" justify="center">
                                                                <v-icon>mdi-image</v-icon>
                                                            </v-row>
                                                        </v-responsive>
                                                    </v-col>
                                                    <v-col>
                                                        <v-file-input v-model="image" label="image" placeholder=" "></v-file-input>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="editItem.depth == 2" class="mt-6">
                                            <v-col class="py-0">
                                                <v-text-field v-model="editItem.sideText" label="컬렉션 이미지 사이드 문구" placeholder=" " hide-details dense></v-text-field>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="editItem.depth == 2" class="mt-6">
                                            <v-col class="py-0">
                                                <v-textarea v-model="editItem.summary" label="컬렉션 설명" placeholder=" " rows="2" hide-details dense></v-textarea>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="editItem.depth == 2" class="mt-6">
                                            <v-col class="py-0">
                                                <v-textarea v-model="editItem.details" label="컬렉션 하단 설명" placeholder=" " rows="2" hide-details dense></v-textarea>
                                            </v-col>
                                        </v-row>

                                        <v-row v-if="editItem.depth == 2" class="mt-6">
                                            <v-col class="py-0">
                                                <div class="caption">카테고리 유형</div>
                                                <v-radio-group v-model="editItem.type" row class="ma-0">
                                                    <v-radio label="내수용" value="내수용"/>
                                                    <v-radio label="수출용" value="수출용"/>
                                                </v-radio-group>
                                            </v-col>
                                            <v-col class="py-0">
                                                <div class="caption">친환경인증 표기</div>
                                                <v-switch v-model="editItem.isEcoFriendly" hide-details inset class="mt-0" :label="editItem.isEcoFriendly ? '표기' : '미표기'" />
                                            </v-col>
                                        </v-row>

                                        <v-row align="center" class="mt-3">
                                            <v-col cols="auto"><span>검색엔진최적화(SEO)</span> <span>(사용/미사용)</span></v-col>
                                            <v-spacer/>
                                            <v-col cols="auto">
                                                <v-switch v-model="editItem.seoEnabled" dense hide-details class="pa-0 ma-0"></v-switch>
                                            </v-col>
                                        </v-row>
                                        <v-row v-if="editItem.seoEnabled">
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-text-field v-model="editItem.seo.title" label="타이틀: Title" placeholder=" " dense hide-details class="mt-3" @input="input"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-text-field v-model="editItem.seo.meta.find((meta) => meta.name == 'author').content" label="메타태그: Author" placeholder=" " dense hide-details class="mt-6" @input="input"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-textarea v-model="editItem.seo.meta.find((meta) => meta.name == 'description').content" label="메타태그: Description" placeholder=" " dense hide-details class="mt-6" @input="input"></v-textarea>
                                            </v-col>
                                            <v-col cols="12" class="py-3 py-md-0">
                                                <v-textarea v-model="editItem.seo.meta.find((meta) => meta.name == 'keywords').content" label="메타태그: Keywords" placeholder=" " dense hide-details class="mt-6" @input="input"></v-textarea>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="dialog = false">취소</v-btn>
                                        <v-btn color="primary" text @click="save">등록</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-toolbar>

                        <v-card-text>
                            <v-draggable-treeview v-if="categories.length" v-model="categories" item-key="_id" children="children" group="categories" @click="select" dense></v-draggable-treeview>
                            <span v-else>등록된 카테고리가 없습니다.<br>카테고리를 등록해주세요</span>
                        </v-card-text>

                    </v-card>

                    <v-row>
                        <v-spacer></v-spacer>
                        <v-col md="auto">

                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="6">
                    <v-card :disabled="!category._id">
                        <v-card-title class="subtitle-1">카테고리 기본정보</v-card-title>
                        <v-card-text>
                            <v-row class="mt-3">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.code" label="카테고리 코드(영문)" placeholder=" " dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-6">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.name" label="카테고리 이름" placeholder=" " dense hide-details></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card class="mt-3" :disabled="!category._id">
                        <v-card-title>
                            <span v-if="category.depth == 2" class="subtitle-1">컬렉션 정보</span>
                            <span v-if="category.depth == 3" class="subtitle-1">텍스처 정보</span>
                        </v-card-title>
                        <v-card-text>
                            <v-row align="center">
                                <v-col cols="auto" class="py-0 pr-0">
                                    <v-img v-if="thumb || category.thumb" width="96" :src="thumb ? createObjectURL(thumb) : category.thumb" contain/>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row class="fill-height" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col>
                                    <v-file-input v-model="thumb" :label="category.depth == 2 ? '컬렉션 썸네일 이미지' : '텍스처 썸네일 이미지'" placeholder=" "></v-file-input>
                                </v-col>
                                <v-col cols="auto">
                                    <v-btn @click="download(category.thumb, category.name)"><v-icon color="primary" size="20" class="mr-2">{{ mdiDownload }}</v-icon>다운로드</v-btn>
                                </v-col>
                            </v-row>

                            <v-row v-if="category.depth == 2" align="center" class="mt-2">
                                <v-col cols="auto" class="py-0 pr-0">
                                    <v-img v-if="image || category.image" width="96" :src="image ? createObjectURL(image) : category.image" contain/>
                                    <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                        <v-row class="fill-height" align="center" justify="center">
                                            <v-icon>mdi-image</v-icon>
                                        </v-row>
                                    </v-responsive>
                                </v-col>
                                <v-col>
                                    <v-file-input v-model="image" label="컬렉션 이미지" placeholder=" "></v-file-input>
                                </v-col>
                            </v-row>

                            <v-row v-if="category.depth == 2" class="mt-6">
                                <v-col class="py-0">
                                    <v-text-field v-model="category.sideText" label="컬렉션 이미지 사이드 문구" placeholder=" " hide-details dense></v-text-field>
                                </v-col>
                            </v-row>

                            <v-row v-if="category.depth == 2" class="mt-6">
                                <v-col class="py-0">
                                    <v-textarea v-model="category.summary" label="컬렉션 설명" placeholder=" " rows="2" hide-details dense></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row v-if="category.depth == 2" class="mt-6">
                                <v-col class="py-0">
                                    <v-textarea v-model="category.details" label="컬렉션 하단 설명" placeholder=" " rows="2" hide-details dense></v-textarea>
                                </v-col>
                            </v-row>

                            <v-row v-if="category.depth == 2" class="caption mt-4">
                                <v-col class="py-0">컬렉션 유형</v-col>
                                <v-col class="py-0">친환경인증 표기</v-col>
                                <v-col class="py-0">샘플노출</v-col>
                            </v-row>
                            <v-row v-if="category.depth == 2">
                                <v-col>
                                    <v-radio-group v-model="category.type" row hide-details class="ma-0">
                                        <v-radio label="내수용" value="내수용"/>
                                        <v-radio label="수출용" value="수출용"/>
                                    </v-radio-group>
                                </v-col>
                                <v-col>
                                    <v-switch v-model="category.isEcoFriendly" hide-details inset class="mt-0" :label="category.isEcoFriendly ? '표기' : '미표기'" />
                                </v-col>
                                <v-col>
                                    <v-switch v-model="category.isSample" hide-details inset class="mt-0" :label="category.isSample ? '노출' : '미노출'" />
                                </v-col>
                            </v-row>

                            <v-file-input v-if="category.depth == 2" v-model="category.file" label="컬렉션 전체이미지 다운로드" placeholder=" " hide-details></v-file-input>
                            <v-row v-if="category.depth == 2">
                                <v-col>
                                    <collection-image-downloader :category="category" />
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card v-if="category.depth == 2" class="mt-3" :disabled="!category._id">
                        <v-card-title class="py-2">
                            <v-row align="center">
                                <v-col class="py-0 subtitle-1">해시태그</v-col>
                                <v-spacer/>
                            </v-row>
                        </v-card-title>
                        <v-card-text>
                            <v-chip v-for="hashtag in category.hashtags" close close-icon="mdi-delete" @click:close="category.hashtags.splice(category.hashtags.indexOf(hashtag), 1)" color="primary" class="mr-2">{{ hashtag }}</v-chip>
                        </v-card-text>
                        <v-divider/>
                        <v-card-actions>
                            <v-row>
                                <v-col class="py-0">
                                    <v-text-field v-model="hashtag" hide-details outlined dense @keydown.enter="category.hashtags.push(hashtag); hashtag = ''" clearable></v-text-field>
                                </v-col>
                                <v-col cols="auto" class="py-0 pl-0">
                                    <v-btn color="primary" @click="category.hashtags.push(hashtag)"><v-icon class="mr-2">{{ mdiTagPlus }}</v-icon> 추가</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>

                    <v-card class="mt-3" :disabled="!category._id">
                        <v-card-title>
                            <v-row>
                                <v-col class="py-0 subtitle-1">보임/숨김</v-col>
                                <v-spacer/>
                                <v-col class="py-0" cols="auto">
                                    <v-switch v-model="category.isHidden" hide-details class="mt-0"></v-switch>
                                </v-col>
                            </v-row>
                        </v-card-title>
                    </v-card>

                    <shop-category-seo v-model="category" :disabled="!category._id"></shop-category-seo>

                    <v-row>
                        <v-col md="auto">
                            <v-btn color="grey darken-1" class="white--text" @click="remove(category)" :disabled="!category._id || category.root ? true : false">삭제</v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col md="auto">
                            <v-btn color="primary" @click="update(category)" :disabled="!category._id">저장</v-btn>
                        </v-col>
                    </v-row>

                </v-col>
            </v-row>
        </v-responsive>
    </v-layout>

</v-container>
</template>

<script>
import { mdiTagPlus, mdiDownload } from '@mdi/js';
import download from "downloadjs";
import ShopCategorySelect from './category/shop-category-select.vue';
import ShopCategorySeo from './category/shop-category-seo.vue';
import CollectionImageDownloader from './collection-image-downloader.vue';

export default {
    components: {
        ShopCategorySelect,
        ShopCategorySeo,
        CollectionImageDownloader
    },
    created() {
        this.getCategories().then(() => {
            this.$nextTick(() => {
                this.loading = false;
            });
        });
    },
    data: () => {
        return {
            mdiTagPlus,
            mdiDownload,

            dialog: false,

            loading: true,

            headers: [],
            categories: [],
            category: {},
            editItem: null,
            defaultItem: {
                _parents: [],
                name: "",
                no: 0,
                depth: 1,
                type: "",

                thumb: null,
                image: null,

                seoEnabled: false,
                seo: {
                    title: null,
                    meta: [
                        { name: "author", content: null },
                        { name: "description", content: null },
                        { name: "keywords", content: null },
                    ]
                }
            },

            hashtag: null,
            thumb: null,
            image: null,
            file: null
        };
    },
    methods: {
        getResource(filename){
            return this.$http({
                url: filename,
                method: 'GET',
                responseType: 'blob'
            }).then(result => {
                    try{
                        return new File([result.data], filename.split('/').pop());
                    }
                    catch(error){
                        var blob = new Blob([result.data]);
                        blob.name = filename.split('/').pop();
                        return blob;
                    }
                }
            );
        },

        download(thumb, name){
            this.getResource(thumb).then(file => {
                download(file, name + thumb.substr(thumb.indexOf(".")) )
            });
        },

        /////////////////////////////////////////
        // 카테고리 등록
        /////////////////////////////////////////
        save() {
            return new Promise(async (resolve, reject) => {
                try {
                    this.loading = true;

                    var category = await this.postCategory(this.editItem);

                    if(this.thumb) await this.postThumb(category._id);
                    if(this.image) await this.postImage(category._id);

                    await this.getCategories();

                    this.dialog = false;

                    resolve();
                } catch (error) {
                    console.log(error);
                    reject(error);
                }
            })
        },

        /////////////////////////////////////////
        // 카테고리 수정
        /////////////////////////////////////////
        update(category) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.loading = true;

                    await this.putCategory(category);

                    if(this.thumb) await this.postThumb(category._id);
                    if(this.image) await this.postImage(category._id);
                    if(category.file) await this.postFile(category._id, category.file);

                    await this.getCategories();

                    alert("저장되었습니다");

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
        /////////////////////////////////////////
        // 카테고리 삭제
        /////////////////////////////////////////
        remove(category) {
            return new Promise(async (resolve, reject) => {
                try {
                    this.loading = true;

                    await this.deleteCategory(category);
                    await this.getCategories();

                    resolve();
                } catch (error) {
                    reject(error);
                }
            });
        },
        findParent(category, parent) {
            for (var i in parent.categories) {
                if (parent.categories[i] == category) return parent;
                if (parent.categories[i].categories) {
                    var result = this.findParent(category, parent.categories[i]);
                    if (result) return result;
                }
            }
            return null;
        },
        /////////////////////////////////////////
        // 카테고리 조회
        /////////////////////////////////////////
        getCategories() {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.get('/api/console/shop/categories');

                    this.categories = res.data.categories;

                    resolve();
                } catch (error) {
                    if (error.response) {
                        alert(error.response.data.message);
                    }
                    reject(error);
                }
            });
        },
        /////////////////////////////////////////
        // 카테고리 등록
        /////////////////////////////////////////
        postCategory(category) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.post('/api/console/shop/categories', category);

                    resolve(res.data.category);
                } catch (error) {
                    if (error.response) {
                        alert(error.response.data.message);
                    }
                    reject(error);
                }
            });
        },
        /////////////////////////////////////////
        // 카테고리 수정
        /////////////////////////////////////////
        putCategory(category) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.put('/api/console/shop/categories/' + category._id, category);

                    resolve();
                } catch (error) {
                    if (error.response) {
                        alert(error.response.data.message);
                    }
                    reject(error);
                }
            });
        },
        /////////////////////////////////////////
        // 카테고리 삭제
        /////////////////////////////////////////
        deleteCategory(category) {
            return new Promise(async (resolve, reject) => {
                try {
                    var res = await this.$http.delete('/api/console/shop/categories/' + category._id);

                    resolve();
                } catch (error) {
                    if (error.response) {
                        alert(error.response.data.message);
                    }
                    reject(error);
                }
            });
        },

        // postThumb(_category){
        //     return new Promise(async (resolve, reject) => {
        //         try{
        //             var data = new FormData();
        //             data.append('thumb', this.thumb);
        //             await this.$http.post(`/api/console/shop/categories/${_category}/thumb`, data, {
        //                 headers: {
        //                     'Content-Type' : 'multipart/form-data'
        //                 }
        //             });

        //             resolve();
        //         }
        //         catch(error){
        //             reject(error);
        //         }
        //     });
        // },

        postThumb(_category){
            let headers = { "Content-Type": "multipart/form-data" };
            let formData = new FormData(); formData.append("thumb", this.thumb);
            return this.$http.post(`/api/console/shop/categories/${_category}/thumb`, formData, { headers }).then(result => result.data);
        },

        postFile(_category, file){
            let headers = { "Content-Type": "multipart/form-data" };
            let formData = new FormData(); formData.append("file", file);
            return this.$http.post(`/api/console/shop/categories/${_category}/file`, formData, { headers }).then(result => result.data);
        },

        deleteThumb(_category){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.delete(`/api/console/shop/categories/${_category}/thumb`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        postImage(_category){
            return new Promise(async (resolve, reject) => {
                try{
                    var data = new FormData();
                    data.append('image', this.image);
                    await this.$http.post(`/api/console/shop/categories/${_category}/image`, data, {
                        headers: {
                            'Content-Type' : 'multipart/form-data'
                        }
                    });

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        deleteImage(_category){
            return new Promise(async (resolve, reject) => {
                try{
                    await this.$http.delete(`/api/console/shop/categories/${_category}/image`);

                    resolve();
                }
                catch(error){
                    reject(error);
                }
            });
        },

        async select(category) {
            try{
                this.category = category;
                if(this.category.file) this.category.file = await this.getResource(this.category.file);
                this.thumb = null;
                this.image = null;
            }
            catch(error){
                console.error(error);
            }
        },
        sort(categories, _parents = [], depth = 1) {
            for(var i in categories) {
                var category = categories[i];
                category.sequence = i;
                category.depth = depth;
                category._parents = _parents;

                if (category.children) {
                    category._children = category.children.map((child) => { return child._id });
                    this.sort(category.children, [].concat(category._parents, category._id), depth + 1);
                }
                this.putCategory(category);
            }
        },

        createObjectURL(file){
            try{ return URL.createObjectURL(file); }
            catch(error){}
        },

        getResource(filename){
            return this.$http({
                url: filename,
                method: 'GET',
                responseType: 'blob'
            }).then(result => new File([result.data], filename));
        },
    },
    computed: {},
    watch: {
        dialog(value){
            if(value){
                this.editItem = Object.assign({}, this.defaultItem);
            }
        },
        category() {
            console.log(this.category);
        },
        categories() {
            if (!this.loading) {
                this.sort(this.categories);
            }
        }
    }
}
</script>
